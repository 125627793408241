import { createMutation, createQuery } from 'react-query-kit';

import {
  createQuota, deleteQuota, getQuota, getQuotas, updateQuota,
} from 'api/quotas';

export const useQuotas = createQuery({
  primaryKey: 'quotas',
  queryFn: ({ queryKey: [_, params] }) => getQuotas(params),
  retry: false,
});

export const useOneQuota = createQuery({
  primaryKey: 'one-quota',
  queryFn: ({ queryKey: [_, params] }) => getQuota(params),
  retry: false,
});

export const useSetQuotaMutation = createMutation({
  mutationFn: async (params) => {
    if (params.id) {
      return updateQuota(params);
    }
    return createQuota(params);
  },
});

export const useDeleteQuotaMutation = createMutation({
  mutationFn: async (params) => deleteQuota(params),
});
