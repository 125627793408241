import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { useOutControllers } from 'api/query/controllers';

import { useStyles } from './styles';

export default function CallControllerAction({
  facilityId,
  params,
  onChange = () => {},
  devShow = false,
}) {
  const styles = useStyles();

  const {
    controller_id: controllerId,
    port_id: portId,
  } = params || {};

  const { data: controllersData } = useOutControllers({
    variables: { facilityId },
  });

  const controllersItems = useMemo(
    () => (controllersData || []).map(({ id, name }) => ({ id, title: name })),
    [controllersData],
  );

  const portsItemsMap = useMemo(() => {
    const result = {};

    (controllersData || []).forEach(({ id, ports }) => {
      const portsResult = [];
      (ports || []).forEach(({ id: pId, name: portName }) => {
        portsResult.push({ id: pId, title: portName });
      });
      result[id] = portsResult;
    });

    return result;
  }, [controllersData]);

  return (
    <>
      <Grid item xs={12}>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={controllersItems}
          label="Контроллер"
          name="controllerId"
          onChange={(e) => {
            onChange({ ...params, controller_id: e.target.value, port_id: '' });
          }}
          currentValue={controllerId}
        />
      </Grid>
      <Grid item xs={12}>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={portsItemsMap[controllerId] || []}
          disabled={controllerId === ''}
          label="Порт"
          name="portId"
          onChange={(e) => {
            onChange({ ...params, port_id: e.target.value });
          }}
          currentValue={portId}
        />
      </Grid>
      {devShow && (<></>)}
    </>
  );
}
