import React from 'react';

function MobilePaymentsIcon({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={size} height={size} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.5C1.61929 0.5 0.5 1.61929 0.5 3V11C0.5 12.3807 1.61929 13.5 3 13.5H17C18.3807 13.5 19.5 12.3807 19.5 11V3C19.5 1.61929 18.3807 0.5 17 0.5H3ZM1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H17C17.8284 1.5 18.5 2.17157 18.5 3V4H1.5V3ZM1.5 7V11C1.5 11.8284 2.17157 12.5 3 12.5H17C17.8284 12.5 18.5 11.8284 18.5 11V7H1.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default MobilePaymentsIcon;
