import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  plural,
  toNativeTimeFormat,
  useFormFields,
} from '@parkly/shared/helpers';

import { useActiveTicketTypes, useCreateTicketMutation } from 'api/query/tickets';
import CustomerAsyncSelect from 'components/organisms/CustomerAsyncSelect';
import TicketPlaceSelect from 'components/organisms/TicketPlaceSelect';
import {
  MODULES_KEYS, PASS_PAYMENT_METHODS,
  PASS_REQ_TYPE_NEED,
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PATH_PAGES,
  RIGHT_KEYS,
  UNITS_PLURAL_MAP,
} from 'config/constants';
import {
  useCheckFacilityRights, useCommonRights, useCurrentOperator, useHasFacilityModule,
} from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { usePersonalAccountsForCharge } from '../../../../api/query/personalAccounts';

import { useStyles } from './styles';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  if (name === 'additionalPhone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function NewOneTicketPage({
  match,
}) {
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tickets);
  const { params, path } = match || {};
  const {
    id: ticketId,
    facilityId,
  } = params || {};

  const isNewMode = !ticketId && path === PATH_PAGES.addTicket;

  const currentOperator = useCurrentOperator();

  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const [showAdditionalValues, setShowAdditionalValues] = useState(false);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      ticketTypeId: '',
      placeId: '',
      placeTitle: '',
      personalAccountId: '',
      comment: '',
      startTime: moment().startOf('day'),
      finishTime: moment().startOf('day'),
      status: PASSES_STATUSES.draft,
      reqValues: [],
      optionalReqValues: [],
      additionalReqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const createTicketMutation = useCreateTicketMutation({
    onSuccess({ success, id }) {
      if (success) {
        history.replace(PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id));
      }
    },
    onError(error) {
      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors({
          ...backErrors,
        });
      }
    },
  });

  const { isLoading: isCreateLoading } = createTicketMutation || {};

  const {
    data: activeTicketTypesData,
    isLoading: isActivePassTypesLoading,
  } = useActiveTicketTypes({
    variables: { facilityId },
  });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    createTicketMutation.mutate({
      facilityId,
      customerId: fields.customerId,
      ticketTypeId: fields.ticketTypeId,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: PASSES_STATUSES.active,
      comment: fields.comment,
      personalAccountId: fields.personalAccountId,
      reqValues: fields.reqValues
        .concat(fields.optionalReqValues)
        .concat(fields.additionalReqValues)
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        }))
        .filter(({ value, type }) => !([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) && value === '7')),
    });
  };

  const selTicketType = (activeTicketTypesData || []).find(({ id }) => id === fields.ticketTypeId);

  useEffect(() => {
    if (!selTicketType) {
      return;
    }

    const reqValues = [];
    const optionalReqValues = [];
    const additionalReqValues = [];
    Object.entries(selTicketType.reqData).forEach((entry) => {
      const [key, value] = entry;

      if (value === PASS_REQ_TYPE_NEED.required) {
        reqValues.push({ type: key, value: '' });
      } else if (value === PASS_REQ_TYPE_NEED.optional) {
        if (!key.includes('additional')) {
          optionalReqValues.push({ type: key, value: '' });
        }
      }
    });

    updateState({
      placeId: '',
      placeTitle: '',
      reqValues,
      optionalReqValues,
      additionalReqValues,
      startTime: moment(selTicketType.startTime),
      finishTime: moment(selTicketType.finishTime),
    });
  }, [selTicketType, updateState]);

  useEffect(() => {
    if (!selTicketType) {
      return;
    }

    updateState({
      finishTime: fields.startTime.clone().add(selTicketType.duration, 'hours'),
    });
  }, [selTicketType, updateState, fields.startTime]);

  const { data: personalAccountsData, isLoading: isPersonalAccountsLoading } = usePersonalAccountsForCharge({
    variables: {},
    enabled: hasPersonalAccountModule && hasPersonalAccountChargeRight,
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name, number }) => ({
    id,
    title: name || number,
  })), [personalAccountsData]);

  useEffect(() => {
    if (!selTicketType) {
      return;
    }

    if (
      !hasPersonalAccountModule
      || selTicketType.paymentMethod !== PASS_PAYMENT_METHODS.personalAccount
    ) {
      updateState({
        personalAccountId: '',
      });

      return;
    }

    const { defaultPersonalAccountId } = currentOperator;

    if (personalAccountItems.length === 1) {
      updateState({
        personalAccountId: personalAccountItems[0].id,
      });

      return;
    }

    if (personalAccountItems.find(({ id }) => id === defaultPersonalAccountId)) {
      updateState({
        personalAccountId: defaultPersonalAccountId,
      });
    }
  }, [selTicketType, personalAccountItems, currentOperator, updateState, hasPersonalAccountModule]);

  const handleChangeCustomer = useCallback((e, v) => {
    updateState({
      customerId: v ? v.id : '',
    });
    setErrors({
      customerId: false,
    });

    if (v && (v.phone || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.phone);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '+7' : formatPhoneNumber(v.phone);
        updateState({
          reqValues: newReqValues,
        });
      }
    }

    if (v && (v.title || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.fio);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '' : v.title;
        updateState({
          reqValues: newReqValues,
        });
      }
    }
  }, [fields.reqValues, setErrors, updateState]);

  if (isActivePassTypesLoading || (isPersonalAccountsLoading && hasPersonalAccountModule && hasPersonalAccountChargeRight)) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title="Добавление пропуска"
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <div>
          <Typography>Выберите тип пропуска:</Typography>
          <Grid className={styles.typesContainer} container spacing={1} wrap="nowrap">
            {(activeTicketTypesData || []).map(({
              id, name, tariffPlanName, intervalMode, intervalValue,
            }) => (
              <Grid item key={id}>
                <Card
                  variant="outlined"
                  className={[
                    styles.typeCard,
                    id === fields.ticketTypeId ? styles.typeCardSelected : '',
                  ].join(' ')}
                  onClick={() => {
                    updateState({ ticketTypeId: id });
                  }}
                >
                  <Typography className={styles.cardTitle}>{name}</Typography>
                  <Typography>{`${intervalValue} ${plural(intervalValue, UNITS_PLURAL_MAP[intervalMode])}`}</Typography>
                  {/* <Typography style={{ color: 'grey' }}>{tariffPlanName}</Typography> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <Container maxWidth="sm" className={styles.innerContainer}>
          <Grid container spacing={2}>
            {fields.ticketTypeId && (
              <>
                <Grid item xs={12} md={4}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                      (errors || {}).startTime ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="startTime"
                    label="Время начала"
                    min={moment().startOf('day')}
                    max={selTicketType.maxDayToStart
                      ? moment().add(selTicketType.maxDayToStart, 'days').endOf('day')
                      : undefined}
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon />)}
                    onChange={({ value }) => updateState({ startTime: moment(value) })}
                    value={toNativeTimeFormat(fields.startTime)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                      (errors || {}).finishTime ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="finishTime"
                    label="Время окончания"
                    min={fields.startTime.clone().startOf('day')}
                    max={fields.startTime.clone().add(selTicketType.duration, 'hours').endOf('day')}
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon />)}
                    onChange={({ value }) => updateState({ finishTime: moment(value) })}
                    value={toNativeTimeFormat(fields.finishTime)}
                  />
                </Grid>
                {selTicketType.isBookPlace && (
                  <Grid item xs={12} md={10}>
                    <TicketPlaceSelect
                      facilityId={facilityId}
                      ticketTypeId={fields.ticketTypeId}
                      selTitle={fields.placeTitle}
                      onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                    />
                  </Grid>
                )}
                {/* <Grid item xs={12} md={8}> */}
                {/*  <ItemSelectors */}
                {/*    classNameForm={styles.selectorsForm} */}
                {/*    classNameLabel={styles.selectorsLabel} */}
                {/*    className={styles.selector} */}
                {/*    disabled={!hasTicketUpdateRight} */}
                {/*    label="Статус" */}
                {/*    items={TICKETS_STATUSES_ITEMS} */}
                {/*    error={!!errors.status} */}
                {/*    name="status" */}
                {/*    onChange={onChange} */}
                {/*    currentValue={fields.status} */}
                {/*  /> */}
                {/* </Grid> */}
                <Grid item xs={12} md={8}>
                  <CustomTextInput
                    className={styles.inputItem}
                    error={!!errors.comment}
                    name="comment"
                    label="Комментарий"
                    value={fields.comment}
                    autoComplete="off"
                    disabled={!hasTicketUpdateRight}
                    onChange={onChange}
                  />
                </Grid>
                {hasPersonalAccountModule && selTicketType.paymentMethod === PASS_PAYMENT_METHODS.personalAccount && (
                  <Grid item xs={12} md={8}>
                    <ItemSelectors
                      classNameForm={styles.selectorsForm}
                      classNameLabel={styles.selectorsLabel}
                      className={styles.selector}
                      disabled={!hasTicketUpdateRight || (personalAccountItems || []).length <= 1}
                      label="Лицевой счет"
                      items={personalAccountItems}
                      error={!!errors.personalAccountId}
                      name="personalAccountId"
                      onChange={onChange}
                      currentValue={fields.personalAccountId}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={8}>
                  <CustomerAsyncSelect
                    facilityId={facilityId}
                    onChange={handleChangeCustomer}
                    error={!!errors.customerId}
                    label="Посетитель"
                  />
                </Grid>
                {(fields.customerId || fields.customerId === null) && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>Обязательные данные:</Typography>
                      </Grid>
                      {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                        <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                          <CustomTextInput
                          // required={!type.includes('additional')}
                            className={styles.inputItem}
                            error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                            label={PASS_REQ_TYPES_MAP[type]}
                            value={value || ''}
                            autoComplete="off"
                            disabled={!hasTicketUpdateRight}
                            inputProps={{
                              maxLength:
                              [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                                ? MAX_PHONE_NUMBER_LENGTH : 250,
                            }}
                            InputProps={{
                              endAdornment: type.includes('additional') ? (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={() => {
                                      const newReqValues = clone(fields.reqValues);
                                      newReqValues.splice(reqIndex, 1);
                                      updateState({
                                        reqValues: newReqValues,
                                      });
                                    }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </InputAdornment>
                              ) : null,
                            }}
                            onChange={(e) => {
                              const { target } = e || {};
                              const {
                                value: newValue,
                              } = target || {};

                              const newReqValues = clone(fields.reqValues);

                              if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                                newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                              } else {
                                newReqValues[reqIndex].value = newValue;
                              }

                              updateState({
                                reqValues: newReqValues,
                              });
                            }}
                          />
                        </Grid>
                      ))}
                      {!showAdditionalValues && fields && (!isEmpty(fields.optionalReqValues)) && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item>
                              <CustomLink
                                onClick={() => {
                                  setShowAdditionalValues(true);
                                }}
                              >
                                Добавить доп. данные
                              </CustomLink>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {showAdditionalValues && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>Дополнительные данные:</Typography>
                            </Grid>
                            {(fields.optionalReqValues || []).map(({ value, type }, reqIndex) => (
                              <Grid key={`optional_req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                                <CustomTextInput
                                  required={false}
                                  className={styles.inputItem}
                                  error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                                  label={PASS_REQ_TYPES_MAP[type]}
                                  value={value || ''}
                                  autoComplete="off"
                                  disabled={!hasTicketUpdateRight}
                                  inputProps={{
                                    maxLength:
                                      [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                                        ? MAX_PHONE_NUMBER_LENGTH : 250,
                                  }}
                                  onChange={(e) => {
                                    const { target } = e || {};
                                    const {
                                      value: newValue,
                                    } = target || {};

                                    const newReqValues = clone(fields.optionalReqValues);

                                    if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                                      newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                                    } else {
                                      newReqValues[reqIndex].value = newValue;
                                    }

                                    updateState({
                                      optionalReqValues: newReqValues,
                                    });
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
              <Grid item xs={3}>
                <CustomBtn
                  type="submit"
                  className={styles.btnSubmit}
                  btnType="primaryBlue"
                  disabled={!(fields.customerId || fields.customerId === null) || isCreateLoading}
                >
                  Добавить
                  {isCreateLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
    </div>
  );
}

export default NewOneTicketPage;
