import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { useFacilityGates } from 'api/query/gates';

import { useStyles } from './styles';

function AddGateModalContent({
  facilityId,
  onClose,
  onAdd,
  zoneIndex,
}) {
  const styles = useStyles();
  const [gateId, setGateId] = useState('');

  const { data, isLoading } = useFacilityGates({
    variables: { id: facilityId },
  });

  const items = useMemo(() => {
    const result = [];

    (data || []).forEach(({ id, name }) => {
      result.push({
        id,
        title: name,
      });
    });

    return result;
  }, [data]);

  const handleOnSave = useCallback(() => {
    if (gateId === '') {
      return;
    }

    const gate = items.find(({ id }) => id === gateId);

    onAdd(zoneIndex, { id: gate.id });
  }, [items, onAdd, gateId, zoneIndex]);

  return (
    <CustomModalContentWrapper
      title="Добавить барьер"
      onClose={onClose}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={items}
            label="Барьер"
            name="gateId"
            onChange={(e) => setGateId(e.target.value)}
            currentValue={gateId}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomBtn disabled={gateId === ''} onClick={handleOnSave} className={styles.saveBtn} btnType="primaryBlue">
            Добавить
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export default AddGateModalContent;
