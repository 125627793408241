import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  StringParam, withDefault,
} from 'use-query-params';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

import { usePersonalAccounts } from 'api/query/personalAccounts';
import {
  PATH_PAGES,
  PERSONAL_ACCOUNTS_STATUSES,
  PERSONAL_ACCOUNTS_STATUSES_MAP, RIGHT_KEYS,
} from 'config/constants';
import { useCommonRights, useDebounce } from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam,
  PageParam,
  withParams,
  withQueryParams,
} from 'helpers/queryParams';

import PersonalAccountContent from './PersonalAccountContent/PersonalAccountContent';
import { useStyles } from './styles';

const STATUS_ITEMS = [
  { id: PERSONAL_ACCOUNTS_STATUSES.active, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.active] },
  { id: PERSONAL_ACCOUNTS_STATUSES.blocked, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.blocked] },
  { id: PERSONAL_ACCOUNTS_STATUSES.closed, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.closed] },
];

function getInitParams() {
  return {
    search: '',
    status: [],
    page: 1,
  };
}

function prepareParam({
  params,
}) {
  const {
    search,
    status,
    page,
  } = params || {};

  const searchValue = (search || '').trim();

  const reqParam = {
    search: searchValue,
    page,
    status,
  };

  return reqParam;
}

function PersonalAccounts({
  showControls = false,
  showPagination,

  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const history = useHistory();

  const hasPersonalAccountManageRight = useCommonRights(RIGHT_KEYS.personalAccountsManage);

  const variables = useDebounce(params, 300);

  const { data: personalAccountsData, isLoading } = usePersonalAccounts({
    variables: { ...variables },
    refetchInterval: 1000 * 60 * 30, // 30 mins
    refetchIntervalInBackground: true,
  });

  const {
    data,
    meta,
  } = personalAccountsData || {};

  const {
    search,
    status,
  } = params;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;

    handleParamChange(propName, value);
  }, [handleParamChange]);

  return (
    <Grid container spacing={2}>
      {showControls && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomTextInput
                style={{ margin: 0 }}
                name="search"
                label="Поиск"
                value={search}
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MultiItemSelectors
                classNameForm={styles.statusSelectForm}
                className={styles.statusSelect}
                name="status"
                currentValue={status}
                items={STATUS_ITEMS}
                unselectText="Статус не выбран"
                onChange={handleChanges}
              />
            </Grid>
            {hasPersonalAccountManageRight && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomBtn
                      className={styles.btn}
                      btnType="primaryGreen"
                      onClick={() => history.push(PATH_PAGES.addNewPersonalAccounts)}
                    >
                      Добавить лицевой счет
                    </CustomBtn>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <PersonalAccountContent
        isLoading={isLoading}
        data={data}
        meta={meta}
        showPagination={showPagination}
        onPageChange={onPageChange}
      />
    </Grid>
  );
}

export const PersonalAccountsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(PersonalAccounts);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PERSONAL_ACCOUNTS_STATUSES.active,
  PERSONAL_ACCOUNTS_STATUSES.blocked,
  PERSONAL_ACCOUNTS_STATUSES.closed,
]);

const PersonalAccountsWithQueryParams = withQueryParams({
  queryParams: {
    search: withDefault(StringParam, ''),
    status: withDefault(StatusArrayEnumParam, []),
    page: PageParam,
  },
  prepareParam,
})(PersonalAccounts);

export default PersonalAccountsWithQueryParams;
