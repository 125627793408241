import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import isEmpty from 'lodash/isEmpty';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ChevronDownIcon from '@parkly/shared/components/atoms/icons/ChevronDownIcon';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { getApiErrorMsg, plural, useFormFields } from '@parkly/shared/helpers';

import { getFacilityPassPlacesAction } from 'actions/facilities';
import { getOnePassSettingsAction, setPassesSettingsAction } from 'actions/passes';
import { getActiveTariffPlansAction } from 'actions/tariffs';
import {
  FACILITY_SETTINGS_KEYS, MODULES_KEYS, PASS_PAYMENT_METHODS,
  PASSES_SETTINGS_STATUS,
  PATH_PAGES,
  PLACE_PURPOSES,
  RIGHT_KEYS,
  TARIFF_STATUS,
  UNITS,
  UNITS_CAPITALIZE_PLURAL_MAP,
  UNITS_PLURAL_MAP,
} from 'config/constants';
import { useCheckFacilityRights, useGetFacilitySetting, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useShortAccessSchemas } from '../../../../../api/query/accessSchemas';

import { useStyles } from './styles';

const UNITS_LIST = [
  { id: UNITS.hour, title: 'Час' },
  { id: UNITS.day, title: 'День' },
  { id: UNITS.month, title: 'Месяц' },
];

const PAYMENT_METHODS = [
  { id: PASS_PAYMENT_METHODS.card, title: 'Принимать оплату от Клиента' },
  { id: PASS_PAYMENT_METHODS.personalAccount, title: 'Списывать с лицевого счета Заказчика' },
];

function getOnePass({
  onePassSettingsState,
  match,
}) {
  const { params } = match || {};
  const { passId } = params || {};

  const { list } = onePassSettingsState || {};
  const currentPass = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + passId);

    return isNeeded;
  });

  return {
    passId,
    currentPass,
  };
}

function getCurrentActiveTariffPlans({
  activeTariffPlansState,
  facilityId,
}) {
  const activeTariffPlansList = (activeTariffPlansState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const activeTariffPlans = activeTariffPlansList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    activeTariffPlans,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function getOneFacilityPlaces({
  oneFacilityPlacesState,
  facilityId,
}) {
  const { list } = oneFacilityPlacesState || {};
  const currentFacilityPlacesData = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + facilityId);

    return isNeeded;
  });

  const {
    places: currentFacilityPlaces,
    error,
  } = currentFacilityPlacesData || {};

  return {
    facilityId,
    currentFacilityPlaces,
    currentFacilityPlacesError: error,
  };
}

function OnePassSettingsPage({
  match,

  onePassSettingsState,
  getOnePassSettingsReq,

  setPassesSettingsReq,
  setPassSettingsState,

  activeTariffPlansState,
  getActiveTariffPlansActionReq,

  oneFacilityPlacesState,
  getFacilityPlacesReq,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.passesSettings);

  const { params, path } = match || {};
  const {
    passId,
    facilityId,
  } = params || {};

  const hasPassesSettingsUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passTypeUpdate, facilityId);

  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);

  const isNewMode = !passId && path === PATH_PAGES.newPassSettings;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      description: '',
      tariffPlanId: '',
      accessSchemaId: '',
      intervalValue: '',
      intervalMode: UNITS.day,
      isShowingAtSite: false,
      isSingle: false,
      isIgnoreSessions: false,
      isBookPlace: true,
      isAllPlace: true,
      isFixedPlace: false,
      holdValue: '',
      holdMode: UNITS.day,
      placeHoldValue: '',
      placeHoldMode: UNITS.day,
      notifyValue: '',
      notifyMode: UNITS.day,
      maxDaysToStart: '',
      paymentMethod: PASS_PAYMENT_METHODS.card,
      needPhone: true,
      optionalPhone: false,
      needFio: false,
      optionalFio: true,
      needPlateNumber: false,
      optionalPlateNumber: false,
      needCarModel: false,
      optionalCarModel: false,
      needStsNumber: false,
      optionalStsNumber: false,
      needIdNumber: false,
      optionalIdNumber: false,
      needRFID: false,
      optionalRFID: false,
      needBarcode: false,
      optionalBarcode: false,
      needPinCode: false,
      optionalPinCode: false,
      needAdditionalPhone: false,
      needAdditionalPlateNumber: false,
      status: PASSES_SETTINGS_STATUS.draft,
      places: [],
      maxStartRenewals: 1,
    },
  });

  useEffect(() => {
    if (isNewMode) {
      return;
    }

    const {
      passId: requestedId,
      currentPass,
    } = getOnePass({
      onePassSettingsState,
      match,
    });

    const { loading: isPassLoading } = onePassSettingsState || {};
    if (!isPassLoading && !currentPass && requestedId && !isNewMode) {
      getOnePassSettingsReq({
        id: passId,
        facilityId,
      });
    }

    if (currentPass && !isNewMode) {
      updateState({
        ...currentPass,
        accessSchemaId: currentPass.accessSchemaId || '',
      });
    }
  }, [facilityId, getOnePassSettingsReq, isNewMode, match, onePassSettingsState, passId, updateState]);

  useEffect(() => {
    const { error } = setPassSettingsState || {};
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setPassSettingsState]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      activeTariffPlans,
    } = getCurrentActiveTariffPlans({
      activeTariffPlansState,
      facilityId,
    });

    const {
      loading,
    } = activeTariffPlansState || {};

    if (!activeTariffPlans && !loading) {
      getActiveTariffPlansActionReq({
        facilityId,
      });
    }
  }, [facilityId, activeTariffPlansState, getActiveTariffPlansActionReq]);

  useEffect(() => {
    const {
      loading: isLoading,
    } = oneFacilityPlacesState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacilityPlaces,
      currentFacilityPlacesError,
    } = getOneFacilityPlaces({
      oneFacilityPlacesState,
      facilityId,
    });

    if (currentFacilityPlaces || currentFacilityPlacesError) {
      return;
    }

    getFacilityPlacesReq({ id: facilityId, purpose: PLACE_PURPOSES.pass });
  }, [oneFacilityPlacesState, getFacilityPlacesReq, facilityId]);

  const enabledAccessSchemas = useGetFacilitySetting(facilityId, FACILITY_SETTINGS_KEYS.isAccessSchemasEnabled, false);

  const { data: accessSchemasData, isLoading: isAccessSchemasLoading } = useShortAccessSchemas({
    variables: { facilityId },
    enabled: enabledAccessSchemas,
  });

  const accessSchemasItems = useMemo(() => (accessSchemasData || []).map(({ id, name }) => ({
    id,
    title: name,
  })), [accessSchemasData]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (fields.isBookPlace && !fields.isAllPlace && isEmpty(fields.places)) {
      setErrors({
        places: true,
      });
      return;
    }

    if (isNewMode) {
      setPassesSettingsReq(
        {
          facilityId,
          name: fields.name,
          description: fields.description,
          tariffPlanId: fields.tariffPlanId,
          accessSchemaId: fields.accessSchemaId,
          intervalValue: fields.intervalValue,
          intervalMode: fields.intervalMode,
          isShowingAtSite: fields.isShowingAtSite,
          isSingle: fields.isSingle,
          isIgnoreSessions: fields.isIgnoreSessions,
          holdValue: fields.holdValue,
          holdMode: fields.holdMode,
          placeHoldValue: fields.placeHoldValue,
          placeHoldMode: fields.placeHoldMode,
          notifyValue: fields.notifyValue,
          notifyMode: fields.notifyMode,
          maxDaysToStart: fields.maxDaysToStart,
          paymentMethod: fields.isShowingAtSite ? PASS_PAYMENT_METHODS.card : fields.paymentMethod,
          needPhone: fields.needPhone || fields.isShowingAtSite,
          requiredPhone: !fields.optionalPhone || fields.isShowingAtSite,
          needFio: fields.needFio,
          requiredFio: !fields.optionalFio,
          needPlateNumber: fields.needPlateNumber,
          requiredPlateNumber: !fields.optionalPlateNumber,
          needCarModel: fields.needCarModel,
          requiredCarModel: !fields.optionalCarModel,
          needStsNumber: fields.needStsNumber,
          requiredStsNumber: !fields.optionalStsNumber,
          needIdNumber: fields.needIdNumber,
          requiredIdNumber: !fields.optionalIdNumber,
          needRFID: fields.needRFID,
          requiredRFID: !fields.optionalRFID,
          needBarcode: fields.needBarcode,
          requiredBarcode: !fields.optionalBarcode,
          needPinCode: fields.needPinCode,
          requiredPinCode: !fields.optionalPinCode,
          needAdditionalPhone: fields.needAdditionalPhone,
          needAdditionalPlateNumber: fields.needAdditionalPlateNumber,
          status: fields.status,
          isBookPlace: fields.isBookPlace,
          isFixedPlace: fields.isFixedPlace,
          places: fields.isBookPlace && !fields.isAllPlace ? fields.places.map(({ id }) => id) : [],
          maxStartRenewals: fields.maxStartRenewals,
        },
        history,
      );
      return;
    }

    setPassesSettingsReq(
      {
        facilityId,
        id: passId,
        name: fields.name,
        description: fields.description,
        tariffPlanId: fields.tariffPlanId,
        accessSchemaId: fields.accessSchemaId,
        intervalValue: fields.intervalValue,
        intervalMode: fields.intervalMode,
        isShowingAtSite: fields.isShowingAtSite,
        isSingle: fields.isSingle,
        isIgnoreSessions: fields.isIgnoreSessions,
        holdValue: fields.holdValue,
        holdMode: fields.holdMode,
        placeHoldValue: fields.placeHoldValue,
        placeHoldMode: fields.placeHoldMode,
        notifyValue: fields.notifyValue,
        notifyMode: fields.notifyMode,
        maxDaysToStart: fields.maxDaysToStart,
        paymentMethod: fields.isShowingAtSite ? PASS_PAYMENT_METHODS.card : fields.paymentMethod,
        needPhone: fields.needPhone || fields.isShowingAtSite,
        requiredPhone: !fields.optionalPhone || fields.isShowingAtSite,
        needFio: fields.needFio,
        requiredFio: !fields.optionalFio,
        needPlateNumber: fields.needPlateNumber,
        requiredPlateNumber: !fields.optionalPlateNumber,
        needCarModel: fields.needCarModel,
        requiredCarModel: !fields.optionalCarModel,
        needStsNumber: fields.needStsNumber,
        requiredStsNumber: !fields.optionalStsNumber,
        needIdNumber: fields.needIdNumber,
        requiredIdNumber: !fields.optionalIdNumber,
        needRFID: fields.needRFID,
        requiredRFID: !fields.optionalRFID && !fields.isShowingAtSite,
        needBarcode: fields.needBarcode,
        requiredBarcode: !fields.optionalBarcode && !fields.isShowingAtSite,
        needPinCode: fields.needPinCode,
        requiredPinCode: !fields.optionalPinCode && !fields.isShowingAtSite,
        needAdditionalPhone: fields.needAdditionalPhone,
        needAdditionalPlateNumber: fields.needAdditionalPlateNumber,
        status: fields.status,
        isBookPlace: fields.isBookPlace,
        isFixedPlace: fields.isFixedPlace,
        places: fields.isBookPlace && !fields.isAllPlace ? fields.places.map(({ id }) => id) : [],
        maxStartRenewals: fields.maxStartRenewals,
      },
      history,
    );
  }, [facilityId, fields, history, isNewMode, passId, setPassesSettingsReq]);

  const {
    loading: isSaveLoading,
  } = setPassSettingsState || {};

  const {
    loading: isPassLoading,
  } = onePassSettingsState || {};

  const { loading: isPlacesLoading } = oneFacilityPlacesState || {};
  const { loading: isTariffLoading } = activeTariffPlansState || {};

  const {
    currentPass,
  } = getOnePass({
    onePassSettingsState,
    match,
  });

  const { status } = currentPass || {};

  const isDisabledUpdate = !(isNewMode || status === PASSES_SETTINGS_STATUS.draft);

  const availableStatuses = useMemo(() => {
    if (isNewMode) {
      return [
        { id: PASSES_SETTINGS_STATUS.draft, title: 'Черновик' },
        { id: PASSES_SETTINGS_STATUS.active, title: 'Активный' },
        { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
      ];
    }

    switch (status) {
      case PASSES_SETTINGS_STATUS.active:
        return [
          { id: TARIFF_STATUS.active, title: 'Активный' },
          { id: TARIFF_STATUS.archive, title: 'Архивный' },
        ];
      case PASSES_SETTINGS_STATUS.archive:
        return [
          { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
        ];
      case PASSES_SETTINGS_STATUS.draft:
      default:
        return [
          { id: PASSES_SETTINGS_STATUS.draft, title: 'Черновик' },
          { id: PASSES_SETTINGS_STATUS.active, title: 'Активный' },
          { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
        ];
    }
  }, [isNewMode, status]);

  const {
    activeTariffPlans,
  } = getCurrentActiveTariffPlans({
    activeTariffPlansState,
    facilityId,
  });

  const { data: activeTariffPlansData } = activeTariffPlans || {};

  const activeTariffPlansItems = useMemo(() => (activeTariffPlansData || [])
    .map(({ id, name }) => ({
      id,
      title: name,
    })), [activeTariffPlansData]);

  const {
    currentFacilityPlaces,
  } = getOneFacilityPlaces({
    oneFacilityPlacesState,
    facilityId,
  });

  const { places: currentFacilityPlacesData } = currentFacilityPlaces || {};

  const placesItems = useMemo(() => {
    const result = [];
    const showZoneName = (currentFacilityPlacesData || []).length > 1;
    (currentFacilityPlacesData || []).forEach(({ name: zoneName, places }) => {
      (places || []).forEach(({ id, name }) => {
        result.push({
          id,
          title: showZoneName ? `${zoneName} - ${name}` : name,
        });
      });
    });
    return result;
  }, [currentFacilityPlacesData]);

  const handlePlacesChange = useCallback((e, value) => {
    updateState({
      places: value,
    });
    setErrors({
      places: false,
    });
  }, [setErrors, updateState]);

  const handleSelectAllPlaces = useCallback(() => {
    updateState({
      places: placesItems,
    });
    setErrors({
      places: false,
    });
  }, [setErrors, updateState, placesItems]);

  if (isPassLoading || isPlacesLoading || isTariffLoading || (enabledAccessSchemas && isAccessSchemasLoading)) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const { error: passSettingsError } = currentPass || {};

  if (passSettingsError) {
    const errorMessage = getApiErrorMsg(passSettingsError);

    return (
      <Container className={styles.container} maxWidth="sm">
        <HeaderWithBackBtn
          title={isNewMode ? 'Добавление типа абонемента' : 'Тип абонемента'}
        />
        <Typography>{`${errorMessage}`}</Typography>
      </Container>
    );
  }

  return (
    <Container className={styles.container} maxWidth="sm">
      <HeaderWithBackBtn
        title={isNewMode ? 'Добавление типа абонемента' : 'Тип абонемента'}
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              required
              error={!!errors.name}
              name="name"
              label="Название, например Абонемент на месяц"
              value={fields.name}
              autoComplete="off"
              disabled={!hasPassesSettingsUpdateRight}
              inputProps={{
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={!!errors.description}
              name="description"
              label="Описание"
              value={fields.description || ''}
              autoComplete="off"
              disabled={!hasPassesSettingsUpdateRight}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={styles.checkbox}
              control={(
                <Checkbox
                  disabled={!hasPassesSettingsUpdateRight}
                  checked={fields.isShowingAtSite}
                  name="isShowingAtSite"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label="Продажа на мини-сайте для физлиц"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              className={styles.selector}
              disabled={!hasPassesSettingsUpdateRight}
              label="Тарифный план *"
              items={activeTariffPlansItems}
              error={!!errors.tariffPlanId}
              name="tariffPlanId"
              onChange={onChange}
              currentValue={fields.tariffPlanId}
            />
          </Grid>
          {hasPersonalAccountModule && !fields.isShowingAtSite && (
            <Grid item xs={12} md={6}>
              <ItemSelectors
                required
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={PAYMENT_METHODS}
                label="Способ оплаты"
                name="paymentMethod"
                onChange={onChange}
                currentValue={fields.paymentMethod}
              />
            </Grid>
          )}
          {enabledAccessSchemas && (
            <Grid item xs={12} md={7}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                disabled={!hasPassesSettingsUpdateRight}
                label="Cхема доступа"
                items={accessSchemasItems}
                error={!!errors.accessSchemaId}
                name="accessSchemaId"
                onChange={onChange}
                currentValue={fields.accessSchemaId}
              />
            </Grid>
          )}
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={8} md={3}>
              <CustomTextInput
                className={styles.inputItem}
                required
                error={!!errors.intervalValue}
                name="intervalValue"
                label="Срок действия"
                value={fields.intervalValue}
                autoComplete="off"
                disabled={isDisabledUpdate || !hasPassesSettingsUpdateRight}
                inputProps={{
                  min: 0,
                  type: 'number',
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={UNITS_LIST}
                disabled={isDisabledUpdate || !hasPassesSettingsUpdateRight}
                name="intervalMode"
                onChange={onChange}
                currentValue={fields.intervalMode}
              />
            </Grid>
            {/* <Grid item xs={12} md={7}> */}
            {/*  <FormControlLabel */}
            {/*    className={styles.checkbox} */}
            {/*    control={( */}
            {/*      <Checkbox */}
            {/*        disabled={isDisabledUpdate || !isCurrentFacilityPassesSettingsUpdateRight} */}
            {/*        checked={fields.isSingle} */}
            {/*        name="isSingle" */}
            {/*        onChange={onChange} */}
            {/*        color="primary" */}
            {/*      /> */}
            {/*    )} */}
            {/*    label="Разовый доступ" */}
            {/*  /> */}
            {/* </Grid> */}
          </Grid>
          {/* <Grid container spacing={0} item xs={12}> */}
          {/*  <Grid item xs={12}> */}
          {/*    <Typography> */}
          {/*      Возможность холдирования */}
          {/*    </Typography> */}
          {/*  </Grid> */}
          {/*  <Grid container spacing={2} item xs={12}> */}
          {/*    <Grid item xs={8} md={3}> */}
          {/*      <CustomTextInput */}
          {/*        className={styles.inputItem} */}
          {/*        error={!!errors.holdValue} */}
          {/*        name="holdValue" */}
          {/*        label="Срок" */}
          {/*        value={fields.holdValue} */}
          {/*        autoComplete="off" */}
          {/*        disabled={isDisabledUpdate || !hasPassesSettingsUpdateRight} */}
          {/*        inputProps={{ */}
          {/*          min: 0, */}
          {/*          type: 'number', */}
          {/*        }} */}
          {/*        onChange={onChange} */}
          {/*      /> */}
          {/*    </Grid> */}
          {/*    <Grid item xs={4} md={2}> */}
          {/*      <ItemSelectors */}
          {/*        classNameForm={styles.selectorsForm} */}
          {/*        classNameLabel={styles.selectorsLabel} */}
          {/*        className={styles.selector} */}
          {/*        items={UNITS_LIST} */}
          {/*        disabled={isDisabledUpdate || !hasPassesSettingsUpdateRight} */}
          {/*        name="holdMode" */}
          {/*        onChange={onChange} */}
          {/*        currentValue={fields.holdMode} */}
          {/*      /> */}
          {/*    </Grid> */}
          {/*  </Grid> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <FormControlLabel
              // className={styles.checkbox}
              control={(
                <Checkbox
                  disabled={isDisabledUpdate || !hasPassesSettingsUpdateRight}
                  checked={fields.isBookPlace}
                  name="isBookPlace"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label="Занимает машиноместо"
            />
          </Grid>
          {fields.isBookPlace && (
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <FormControlLabel
                    // className={styles.checkbox}
                    control={(
                      <Checkbox
                        disabled={!hasPassesSettingsUpdateRight}
                        checked={fields.isAllPlace}
                        name="isAllPlace"
                        onChange={onChange}
                        color="primary"
                      />
                    )}
                    label="Все места c типом Абонемент"
                  />
                </Grid>
                {!fields.isAllPlace && (
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      options={placesItems}
                      disableCloseOnSelect
                      value={fields.places}
                      disabled={!hasPassesSettingsUpdateRight}
                      onChange={handlePlacesChange}
                      popupIcon={<ChevronDownIcon />}
                      renderInput={(params) => (
                        <CustomTextInput
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...params}
                          required
                          className={styles.inputAutocompleteItem}
                          error={!!errors.places}
                          disabled={!hasPassesSettingsUpdateRight}
                          label="Машиноместа"
                        />
                      )}
                    />
                    {hasPassesSettingsUpdateRight && (
                      <CustomLink onClick={handleSelectAllPlaces}>Выбрать все места</CustomLink>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {fields.isBookPlace && (
            <Grid item xs={12}>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Место назначается автоматически</Grid>
                  <Grid item>
                    <Switch
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.isFixedPlace}
                      onChange={onChange}
                      name="isFixedPlace"
                      color="primary"
                    />
                  </Grid>
                  <Grid item>Можно выбрать машиноместо</Grid>
                </Grid>
              </Typography>
            </Grid>
          )}
          <Grid container spacing={0} item xs={12}>
            <Grid item xs={12}>
              <Typography>
                Период удержания места за клиентом
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={8} md={3}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={!!errors.notifyValue}
                  name="placeHoldValue"
                  label="Период"
                  value={fields.placeHoldValue}
                  autoComplete="off"
                  disabled={!hasPassesSettingsUpdateRight}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  items={UNITS_LIST}
                  disabled={!hasPassesSettingsUpdateRight}
                  name="placeHoldMode"
                  onChange={onChange}
                  currentValue={fields.placeHoldMode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={12}>
            <Grid item xs={12}>
              <Typography>
                Уведомление о необходимости продления за
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={8} md={3}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={!!errors.notifyValue}
                  name="notifyValue"
                  label="Срок"
                  value={fields.notifyValue}
                  autoComplete="off"
                  disabled={!hasPassesSettingsUpdateRight}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  items={UNITS_LIST}
                  disabled={!hasPassesSettingsUpdateRight}
                  name="notifyMode"
                  onChange={onChange}
                  currentValue={fields.notifyMode}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  // className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.isIgnoreSessions}
                      name="isIgnoreSessions"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Не учитывать въезд и выезд при доступе"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={12}>
            <Grid item xs={12}>
              <Typography>
                Максимальное кол-во дней до начала действия абонемента
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12} alignItems="center">
              <Grid item xs={8} md={3}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={!!errors.maxDaysToStart}
                  name="maxDaysToStart"
                  label={plural(fields.maxDaysToStart, UNITS_CAPITALIZE_PLURAL_MAP[UNITS.day])}
                  value={fields.maxDaysToStart}
                  autoComplete="off"
                  disabled={!hasPassesSettingsUpdateRight}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  onChange={onChange}
                />
              </Grid>
              {parseInt(fields.maxDaysToStart, 10) === 0 && (
                <Grid item>
                  <Typography color="textSecondary">
                    Без ограничения
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {fields.isShowingAtSite && (
            <Grid container spacing={0} item xs={12}>
              <Grid item xs={12}>
                <Typography>
                  Максимальное кол-во продлений при покупке
                </Typography>
              </Grid>
              <Grid container spacing={2} item xs={12} alignItems="center">
                <Grid item xs={8} md={3}>
                  <CustomTextInput
                    className={styles.inputItem}
                    error={!!errors.maxStartRenewals}
                    name="maxStartRenewals"
                    label="шт"
                    value={fields.maxStartRenewals}
                    autoComplete="off"
                    disabled={!hasPassesSettingsUpdateRight}
                    inputProps={{
                      min: 0,
                      type: 'number',
                    }}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    {parseInt(fields.maxStartRenewals, 10) === 0
                      ? 'Купить на мини-сайте можно только абонемент, без продлений'
                      : `Можно купить абонемент и ${fields.maxStartRenewals} ${plural(fields.maxStartRenewals, ['продление', 'продления', 'продлений'])}`}

                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              Запрашивать для оформления
            </Typography>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight || fields.isShowingAtSite}
                    checked={fields.needPhone || fields.isShowingAtSite}
                    name="needPhone"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Номер телефона"
              />
              {(fields.needPhone || fields.isShowingAtSite) && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight || fields.isShowingAtSite}
                      checked={fields.optionalPhone && !fields.isShowingAtSite}
                      name="optionalPhone"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needFio}
                    name="needFio"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="ФИО"
              />
              {fields.needFio && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.optionalFio}
                      name="optionalFio"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needPlateNumber}
                    name="needPlateNumber"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Госномер авто"
              />
              {fields.needPlateNumber && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.optionalPlateNumber}
                      name="optionalPlateNumber"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needCarModel}
                    name="needCarModel"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Марка и модель авто"
              />
              {fields.needCarModel && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.optionalCarModel}
                      name="optionalCarModel"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needStsNumber}
                    name="needStsNumber"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Номер СТС"
              />
              {fields.needStsNumber && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.optionalStsNumber}
                      name="optionalStsNumber"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needRFID}
                    name="needRFID"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="ID (Карта)"
              />
              {fields.needRFID && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight || fields.isShowingAtSite}
                      checked={fields.optionalRFID || fields.isShowingAtSite}
                      name="optionalRFID"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needPinCode}
                    name="needPinCode"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Пин-код"
              />
              {fields.needPinCode && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight || fields.isShowingAtSite}
                      checked={fields.optionalPinCode || fields.isShowingAtSite}
                      name="optionalPinCode"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needBarcode}
                    name="needBarcode"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Штрихкод"
              />
              {fields.needBarcode && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight || fields.isShowingAtSite}
                      checked={fields.optionalBarcode || fields.isShowingAtSite}
                      name="optionalBarcode"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needIdNumber}
                    name="needIdNumber"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Номер паспорта"
              />
              {fields.needIdNumber && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasPassesSettingsUpdateRight}
                      checked={fields.optionalIdNumber}
                      name="optionalIdNumber"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Опционально"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needAdditionalPhone}
                    name="needAdditionalPhone"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Дополнительный номер телефона"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={!hasPassesSettingsUpdateRight}
                    checked={fields.needAdditionalPlateNumber}
                    name="needAdditionalPlateNumber"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Дополнительный авто (до 4 шт.)"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={availableStatuses}
                name="status"
                disabled={!hasPassesSettingsUpdateRight}
                onChange={onChange}
                currentValue={fields.status}
              />
            </Grid>
            {hasPassesSettingsUpdateRight && (
              <Grid item xs={3}>
                <CustomBtn
                  className={styles.saveBtn}
                  btnType="primaryBlue"
                  type="submit"
                  disabled={isSaveLoading}
                >
                  {t('tariffs.save')}
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

function mapStateToProps(state) {
  const {
    facilities,
    passes,
    tariffs,
  } = state || {};
  const { oneFacilityPassPlaces } = facilities || {};
  const { activeTariffPlans: activeTariffPlansState } = tariffs || {};
  const {
    onePassSettings: onePassSettingsState,
    setPassSettings: setPassSettingsState,
  } = passes || {};

  return {
    oneFacilityPlacesState: oneFacilityPassPlaces,
    onePassSettingsState,
    activeTariffPlansState,
    setPassSettingsState,
  };
}

const ConnectedOneTariffPage = connect(
  mapStateToProps,
  {
    getOnePassSettingsReq: getOnePassSettingsAction,
    getActiveTariffPlansActionReq: getActiveTariffPlansAction,
    setPassesSettingsReq: setPassesSettingsAction,
    getFacilityPlacesReq: getFacilityPassPlacesAction,
  },
)(OnePassSettingsPage);

export default ConnectedOneTariffPage;
