import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import axios from 'axios';
import BrowserDetector from 'browser-dtector';

import { getAuthToken } from '@parkly/shared/api';
import { useDidUpdateEffect } from '@parkly/shared/helpers';

import { useIsIdle } from 'helpers/hooks';

import { useStyles } from './styles';

const HOST = 'https://stream.p360.ru';

function CameraStream({
  uuid,
  channel = 0,
  autoStopTimeout = 0,
}) {
  const token = getAuthToken();
  const [active, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isIdlePause, setIsIdlePause] = useState(false);
  const styles = useStyles({ active });
  const videoRef = useRef();
  const mediaStreamRef = useRef(null);
  const webrtcRef = useRef(null);

  const isIdle = useIsIdle({
    enabled: autoStopTimeout > 0,
    timeout: 1000 * autoStopTimeout,
  });

  useDidUpdateEffect(() => {
    if (!autoStopTimeout || autoStopTimeout < 0) {
      return;
    }

    if (isIdle && active) {
      setIsIdlePause(true);
      stop();
    } else if (isIdlePause) {
      play();
      setIsIdlePause(false);
    }
  }, [isIdle]);

  const browser = new BrowserDetector(window.navigator.userAgent);
  const { isSafari } = browser.parseUserAgent();

  useEffect(() => () => {
    if (webrtcRef.current) {
      webrtcRef.current.close();
    }
    setIsActive(false);
  }, [webrtcRef]);

  useEffect(() => {
    if (isSafari) {
      return;
    }
    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafari]);

  const requestFullScreen = useCallback(() => {
    try {
      videoRef.current.requestFullscreen();
    } catch (e) {
      //
    }
  }, []);

  const stop = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    mediaStreamRef.current = null;

    if (webrtcRef.current) {
      webrtcRef.current.close();
    }
    webrtcRef.current = null;
    setIsActive(false);
    setIsLoading(false);
  }, []);

  function play() {
    async function startPlay() {
      mediaStreamRef.current = new MediaStream();
      webrtcRef.current = new RTCPeerConnection({
        iceServers: [
          {
            username: 'free',
            credential: 'free',
            urls: [
              'turn:freestun.net:3479',
            ],
          },
          {
            urls: [
              'stun:stun.l.google.com:19302',
              'stun:stun1.l.google.com:19302',
              'stun:stun2.l.google.com:19302',
              'stun:stun3.l.google.com:19302',
              'stun:stun4.l.google.com:19302',
              'stun:freestun.net:3479',
              'stun:freestun.net:5350',
            ],
          },
        ],
      });
      if (isSafari) {
        webrtcRef.current.addTransceiver('video', { direction: 'recvonly' });
      }
      webrtcRef.current.onnegotiationneeded = handleNegotiationNeeded;
      webrtcRef.current.onsignalingstatechange = signalingstatechange;

      webrtcRef.current.onconnectionstatechange = () => {
        switch (webrtcRef.current.connectionState) {
          case 'new':
          case 'connecting':
            break;
          case 'connected':
            setIsLoading(false);
            break;
          case 'disconnected':
          case 'failed':
            stop();
            break;
          case 'closed':
            stop();
            break;
          default:
            break;
        }
      };

      webrtcRef.current.ontrack = ontrack;
      const offer = await webrtcRef.current.createOffer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: false,
      });
      try {
        await webrtcRef.current.setLocalDescription(offer);
      } catch (e) {}

      videoRef.current.srcObject = mediaStreamRef.current;
    }

    function ontrack(event) {
      event.track.addEventListener('ended', () => {
        if (active) {
          setIsActive(false);
        }
      });

      mediaStreamRef.current.addTrack(event.track);
      videoRef.current.srcObject = mediaStreamRef.current;
    }

    async function signalingstatechange() {
      switch (webrtcRef.current.signalingState) {
        case 'have-local-offer':
          const url = `${HOST}/stream/${uuid}/channel/${channel}/webrtc?uuid=${uuid}&channel=${channel}&token=${token}`;
          const bodyFormData = new FormData();
          bodyFormData.append('data', btoa(webrtcRef.current.localDescription.sdp));

          axios({
            method: 'post',
            url,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }).then((response) => {
            try {
              if (!response.data) {
                stop();
                return;
              }

              if (webrtcRef.current && webrtcRef.current.signalingState === 'have-local-offer') {
                webrtcRef.current.setRemoteDescription(new RTCSessionDescription({
                  type: 'answer',
                  sdp: atob(response.data),
                }));
              }
            } catch (e) {
              console.warn(e);
            }
          })
            .catch(() => {
              stop();
            });
          break;
        case 'stable':
          break;
        case 'closed':
          setIsLoading(false);
          break;
        default:
          break;
      }
    }

    async function handleNegotiationNeeded() {
      const url = `${HOST}/stream/${uuid}/channel/${channel}/webrtc?uuid=${uuid}&channel=${channel}&token=${token}`;
      const offer = await webrtcRef.current.createOffer();
      await webrtcRef.current.setLocalDescription(offer);

      const bodyFormData = new FormData();
      bodyFormData.append('data', btoa(webrtcRef.current.localDescription.sdp));

      axios({
        method: 'post',
        url,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        try {
          if (response.data) {
            stop();
            return;
          }

          webrtcRef.current.setRemoteDescription(new RTCSessionDescription({
            type: 'answer',
            sdp: atob(response.data),
          }));
        } catch (e) {
          console.warn(e);
        }
      })
        .catch(() => {
          stop();
        });
    }

    videoRef.current.addEventListener('loadeddata', () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    });

    startPlay();
    setIsActive(true);
    setIsLoading(true);
  }

  return (
    <div className={styles.videoContainer}>
      <video
        ref={videoRef}
        className={styles.video}
        controlsList="nodownload noremoteplayback"
        playsInline="1"
        muted
      />
      {!active && (
        <IconButton className={styles.playBtn} onClick={play}>
          <PlayArrowIcon fontSize="large" />
        </IconButton>
      )}
      {isLoading && active && (
        <div className={styles.playBtn}>
          <CircularProgress size="1.5rem" color="secondary" />
        </div>
      )}
      {active && (
        <>
          <IconButton className={styles.stopBtn} onClick={stop}>
            <StopIcon htmlColor="white" fontSize="small" />
          </IconButton>
          <IconButton className={styles.fullscreenBtn} onClick={requestFullScreen}>
            <FullscreenIcon htmlColor="white" fontSize="small" />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default CameraStream;
