import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { CircularProgress, Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Copyright from '@parkly/shared/components/atoms/Copyright';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import { formatPhoneNumber, useDidUpdateEffect } from '@parkly/shared/helpers';

import { login } from 'actions/auth';
import { confirmPhone, validatePhone } from 'api/auth';
import SelectLoginCards from 'components/molecules/SelectLoginCards';
import { PATH_PAGES } from 'config/constants';
import { useLoginState } from 'helpers/hooks';

import { useStyles } from './styles';

function PhoneSignInPage({
                           isAuth,
                           login,

                           validatePhoneApi = validatePhone,
                           confirmPhoneApi = confirmPhone,
                         }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [partnersData, setPartnersData] = useState([]);

  const {
    fields: fieldsPhoneCode,
    errors: errorsPhoneCode,
    timerValue,
    isCodeCheckLoading,
    isSendCodeLoading,
    validatedPhone,
    onChange: onChangePhoneCode,
    onSendCode,
    onSendAgain,
  } = useLoginState({
    validatePhoneApi,
    confirmPhoneApi,
    codeLength: 4,
    onSuccessSendCode: () => {
      setStep(1);
    },
    onSuccess: async ({
                        success,
                        partners,
                        tel,
                      }) => {
      if (success) {
        if ((partners || []).length === 1) {
          const {
            token, userId, name,
          } = partners[0];

          login({
            tel,
            token,
            userId,
            partnerName: name,
          }, history);
        } else {
          setPartnersData(partners.map((item) => ({
            ...item,
            tel,
          })));
          setStep(2);
        }
      }
    },
  });

  const codeInputRef = useRef(null);

  useDidUpdateEffect(
    () => {
      if (step === 1 && (codeInputRef || {}).current) {
        codeInputRef.current.focus();
      }
    },
    [step],
  );

  const handleLoginClick = useCallback((partner) => {
    const {
      tel,
      token,
      userId,
      name,
    } = partner;

    login({
      tel,
      token,
      userId,
      partnerName: name,
    }, history);
  }, [history, login]);

  const classes = useStyles();

  if (isAuth) {
    return (
      <Redirect to={{
        pathname: '/',
      }}
      />
    );
  }

  return (
    <Container
      component="main"
    >
      <div className={classes.paper}>
        <P360LogoIcon className={classes.logo} colorP={theme.palette.primary.main} />

        {(step === 0 || step === 1) && (
          <Container maxWidth={"xs"} style={{
            maxWidth: 318
          }}>
          <Box
            style={{
              width: '100%',
              margin: '0 auto',
            }}
          >
            {step === 0 && (
              <form className={classes.form} onSubmit={onSendCode} noValidate>
                <CustomTextInput
                  onChange={onChangePhoneCode}
                  required
                  label={t('signIn.phone')}
                  name="phone"
                  inputProps={{
                    maxLength: MAX_PHONE_NUMBER_LENGTH,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  error={errorsPhoneCode.phone}
                  value={fieldsPhoneCode.phone}
                  autoComplete="phone"
                  autoFocus
                />
                <CustomBtn
                  type="submit"
                  fullWidth
                  disabled={isSendCodeLoading}
                  color="primary"
                  className={classes.submit}
                >
                  {t('signIn.next')}
                  {isSendCodeLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </form>
            )}

            {step === 1 && (
              <form className={classes.form} onSubmit={onSendAgain} noValidate>
                <Typography className={classes.codeSendTo} color="textSecondary">
                  {t('signIn.codeSendTo')}
                  <br />
                  {formatPhoneNumber(validatedPhone)}
                </Typography>
                <CustomTextInput
                  inputRef={codeInputRef}
                  onChange={onChangePhoneCode}
                  required
                  maxLength={4}
                  label={t('signIn.code')}
                  name="code"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  error={errorsPhoneCode.code}
                  value={fieldsPhoneCode.code}
                  autoFocus
                />
                <CustomBtn
                  type="submit"
                  fullWidth
                  disabled={isSendCodeLoading || isCodeCheckLoading || timerValue > 0}
                  color="primary"
                  className={classes.submit}
                >
                  { !(isSendCodeLoading || isCodeCheckLoading) && timerValue > 0
                    ? `Запросить через 00:${timerValue < 10 ? `0${timerValue}` : timerValue}`
                    : t('signIn.again')}
                  {(isSendCodeLoading || isCodeCheckLoading) && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </form>
            )}
          </Box>
          </Container>
        )}

        {step === 2 && (
          <SelectLoginCards
            handleLoginClick={handleLoginClick}
            partnersData={partnersData}
          />
        )}
      </div>

      <Box mt={1} style={{
        width: 318,
        margin: '0 auto',
        paddingTop: 20,
      }}>
        <Typography color="textSecondary" className={classes.userAgreement}>
          Авторизуясь в личном кабинете я принимаю
          {' '}
          <Link
            href={PATH_PAGES.userAgreement}
            target="_blank"
            className={classes.userAgreementLink}
          >
            Пользовательское соглашение
          </Link>
        </Typography>
      </Box>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

function mapStateToProps(state) {
  const { loggedIn, loading } = state.auth || {};
  return {
    isAuth: loggedIn,
    loading,
  };
}

const connectedPhoneSignInPage = connect(
  mapStateToProps,
  {
    login,
  },
)(PhoneSignInPage);

export default connectedPhoneSignInPage;
