import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

import { callAPI } from '@parkly/shared/api';

async function validGetPersonalAccounts({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const meta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const formatedData = curData.map((item) => {
    const {
      id,
      number,
      name,
      status,
      credit_limit: creditLimit,
      balance,
      last_activity_at: lastActivityAt,
    } = item;

    return {
      id,
      number,
      name,
      status,
      creditLimit,
      balance,
      lastActivityAt,
    };
  });

  return {
    data: formatedData,
    meta,
  };
}

export async function getPersonalAccounts({
  status,
  search,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    statuses: (status || []).join(','),
    search,
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `personal-accounts?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPersonalAccounts({
      res,
    }));
}

async function validGetAllPersonalAccounts({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const formatedData = curData.map((item) => {
    const {
      id,
      number,
      name,
      status,
    } = item;

    return {
      id,
      number,
      name,
      status,
    };
  });

  return formatedData;
}

export async function getAllPersonalAccounts() {
  return callAPI('personal-accounts/all')
    .then((res) => validGetAllPersonalAccounts({
      res,
    }));
}

export async function getPersonalAccountsForCharge() {
  return callAPI('personal-accounts/for-charge')
    .then((res) => validGetAllPersonalAccounts({
      res,
    }));
}

async function validGetOnePersonalAccount({
  res,
}) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    name,
    status,
    credit_limit: creditLimit,
    balance,
    last_activity_at: lastActivityAt,
  } = resultData || {};

  return {
    id,
    number,
    name,
    status,
    creditLimit,
    balance,
    lastActivityAt,
  };
}

export async function getOnePersonalAccount({
  id,
}) {
  return callAPI(`personal-accounts/${id}`)
    .then((res) => validGetOnePersonalAccount({
      res,
    }));
}

function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function createOnePersonalAccount({
  number,
  name,
  creditLimit,
}) {
  return callAPI('personal-accounts', 'POST', {
    number,
    name,
    credit_limit: creditLimit === '' ? null : creditLimit,
  }).then((res) => validCreate({
    res,
  }));
}

export async function updateOnePersonalAccount({
  id,
  number,
  name,
  creditLimit,
  status,
}) {
  return callAPI(`personal-accounts/${id}`, 'POST', {
    number,
    name,
    credit_limit: creditLimit === '' ? null : creditLimit,
    status,
  }).then((res) => validCreate({
    res,
  }));
}

async function validGetPersonalAccountTransactions({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const meta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const formatedData = curData.map((item) => {
    const {
      id,
      type,
      status,
      amount,
      description,
      performed_at: performedAt,
      operator_id: operatorId,
      operator_name: operatorName,
    } = item;

    return {
      id,
      type,
      status,
      amount,
      description,
      performedAt,
      operatorId,
      operatorName,
    };
  });

  return {
    data: formatedData,
    meta,
  };
}

export async function getPersonalAccountTransactions({
  id,
  type,
  status,
  dateFrom,
  dateTo,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    types: (type || []).join(','),
    statuses: (status || []).join(','),
    date_from: dateFrom && moment(dateFrom).utc().toISOString(),
    date_to: dateTo && moment(dateTo).utc().toISOString(),
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `personal-accounts/${id}/transactions?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPersonalAccountTransactions({
      res,
    }));
}

export async function createPersonalAccountTransaction({
  id,
  type,
  amount,
  description,
  date,
}) {
  return callAPI(
    `personal-accounts/${id}/transactions`,
    'POST',
    {
      type,
      amount,
      description,
      date,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}
