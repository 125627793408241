import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllPersonalAccountsPage from 'components/pages/personalAccounts/AllPersonalAccountsPage';
import OnePersonalAccountPage from 'components/pages/personalAccounts/OnePersonalAccountPage';
import OnePersonalAccountInfoPage from 'components/pages/personalAccounts/OnePersonalAccountsInfoPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES } from 'config/constants';

function PersonalAccountsPage() {
  useEffect(() => {
    moment.tz.setDefault();
  }, []);

  const urlActions = PATH_PAGES.allPersonalAccounts;

  return (
    <ParentPageContainer isLoading={false}>
      <PrivateRoute exact path={PATH_PAGES.allPersonalAccounts} component={AllPersonalAccountsPage} />
      <PrivateRoute exact path={PATH_PAGES.addNewPersonalAccounts} component={OnePersonalAccountPage} />
      <PrivateRoute exact path={PATH_PAGES.onePersonalAccounts} component={OnePersonalAccountPage} />
      <PrivateRoute exact path={PATH_PAGES.oneInfoPersonalAccounts} component={OnePersonalAccountInfoPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedPersonalAccountsPage = connect(
  mapStateToProps,
  {},
)(PersonalAccountsPage);

export default ConnectedPersonalAccountsPage;
