import React from 'react';
import { Redirect } from 'react-router-dom';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllQuotaPage from 'components/pages/settings/quotas/AllQuotasPage';
import OneQuotaPage from 'components/pages/settings/quotas/OneQuotaPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES } from 'config/constants';

function QuotasPage({
  match,
}) {
  const urlActions = PATH_PAGES.allQuotas;

  return (
    <ParentPageContainer isLoading={false}>
      <PrivateRoute exact path={PATH_PAGES.allQuotas} component={AllQuotaPage} />
      <PrivateRoute exact path={PATH_PAGES.newQuota} component={OneQuotaPage} />
      <PrivateRoute exact path={PATH_PAGES.oneQuota} component={OneQuotaPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

export default QuotasPage;
