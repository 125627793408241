import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 30,
  },
  bodyMain: {

  },
  form: {
    marginTop: 24,
  },
  inputItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    },
  },
  inputAutocompleteItem: {
    margin: 0,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  divider: {
    marginTop: 32,
    backgroundColor: theme.palette.greyC.gray,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btn: {
    width: '100%',
  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerDisabled: {
    '& input': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerWithError: {
    '& input': {
      backgroundColor: `${theme.palette.redC.red4} !important`,
    },
  },
}));

export default {
  useStyles,
};
