import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  balanceContainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  pageBody: {

  },
  paginationContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
  title: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 700,
  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerWithError: {
    '& input': {
      backgroundColor: `${theme.palette.redC.red4} !important`,
    },
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
}));

export default {
  useStyles,
};
