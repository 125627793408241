import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { StringParam, withDefault } from 'use-query-params';

import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { usePersonalAccountTransactions } from 'api/query/personalAccounts';
import {
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES,
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP,
  PERSONAL_ACCOUNT_TRANSACTIONS_TYPES,
  PERSONAL_ACCOUNT_TRANSACTIONS_TYPES_MAP,
} from 'config/constants';
import { useDebounce } from 'helpers/hooks';

import {
  createEnumDelimitedNumericArrayParam,
  PageParam,
  withParams,
  withQueryParams,
} from '../../../helpers/queryParams';

import PersonalAccountTransactionContent from './PersonalAccountTransactionsContent/PersonalAccountTransactionContent';
import { useStyles } from './styles';

const TYPE_ITEMS = [
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp, title: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp] },
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.charge, title: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.charge] },
];

const STATUS_ITEMS = [
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.inProgress, title: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.inProgress] },
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.success, title: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.success] },
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.canceled, title: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.canceled] },
  { id: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.failed, title: PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP[PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.failed] },
];

function getInitParams() {
  return {
    type: [],
    status: [],
    dateFrom: '',
    dateTo: '',
    page: 1,
  };
}

function prepareParam({
  params,
}) {
  const {
    type,
    status,
    dateTo,
    dateFrom,
    page,
  } = params || {};

  const reqParam = {
    page,
    type,
    status,
    dateTo,
    dateFrom,
  };

  return reqParam;
}

function PersonalAccountTransactions({
  accountId,
  showControls = false,
  showPagination,

  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const variables = useDebounce(params, 300);

  const { data: personalAccountTransactionsData, isLoading, getKey } = usePersonalAccountTransactions({
    variables: { id: accountId, ...variables },
    refetchInterval: 1000 * 60 * 10, // 10 mins
    refetchIntervalInBackground: true,
  });

  const {
    data,
    meta,
  } = personalAccountTransactionsData || {};

  const {
    type,
    status,
    dateFrom,
    dateTo,
  } = params;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;

    handleParamChange(propName, value);
  }, [handleParamChange]);

  const handleDateChange = ({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  };

  return (
    <Grid container spacing={2}>
      {showControls && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MultiItemSelectors
                classNameForm={styles.statusSelectForm}
                className={styles.statusSelect}
                name="type"
                currentValue={type}
                items={TYPE_ITEMS}
                unselectText="Тип не выбран"
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <DateTimeInput
                className={[
                  styles.datePicker,
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                ].join(' ')}
                name="dateFrom"
                placeholder="Не выбрано"
                label="От"
                initStartDayTime
                rightIcon={(
                  <>
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleParamChange('dateFrom', '');
                      }}
                    >
                      <CloseIcon htmlColor="#7E8AA7" />
                    </IconButton>
                    <CalendarIcon />
                  </>
                )}
                onChange={handleDateChange}
                value={dateFrom || undefined}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <DateTimeInput
                className={[
                  styles.datePicker,
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                ].join(' ')}
                name="dateTo"
                placeholder="Не выбрано"
                label="До"
                initStartDayTime
                rightIcon={(
                  <>
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleParamChange('dateTo', '');
                      }}
                    >
                      <CloseIcon htmlColor="#7E8AA7" />
                    </IconButton>
                    <CalendarIcon />
                  </>
                )}
                onChange={handleDateChange}
                value={dateTo || undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MultiItemSelectors
                classNameForm={styles.statusSelectForm}
                className={styles.statusSelect}
                name="status"
                currentValue={status}
                items={STATUS_ITEMS}
                unselectText="Статус не выбран"
                onChange={handleChanges}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <PersonalAccountTransactionContent
        showPagination={showPagination}
        data={data}
        meta={meta}
        onPageChange={onPageChange}
        isLoading={isLoading}
      />
    </Grid>
  );
}

export const PersonalAccountTransactionsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(PersonalAccountTransactions);

const TypeArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp,
  PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.charge,
]);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.inProgress,
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.success,
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.canceled,
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES.failed,
]);

const PersonalAccountTransactionsWithQueryParams = withQueryParams({
  queryParams: {
    type: withDefault(TypeArrayEnumParam, []),
    status: withDefault(StatusArrayEnumParam, []),
    dateTo: withDefault(StringParam, ''),
    dateFrom: withDefault(StringParam, ''),
    page: PageParam,
  },
  prepareParam,
})(PersonalAccountTransactions);

export default PersonalAccountTransactionsWithQueryParams;
