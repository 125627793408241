import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Grid, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import { useFormFields } from '@parkly/shared/helpers';

import {
  downloadQrImageFacilitySiteAction,
  getFacilitySiteSettingAction,
  setFacilitySiteSettingAction,
} from 'actions/facilities';
import { getActiveTariffPlansAction } from 'actions/tariffs';
import { MODULES_KEYS, RIGHT_KEYS, SITE_THEMES } from 'config/constants';
import { useCheckFacilityRights, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

const THEMES = [
  { id: SITE_THEMES.default, title: 'Стандартная' },
  { id: SITE_THEMES.default_orange, title: 'Стандартная - Оранжевый' },
  { id: SITE_THEMES.light, title: 'Светлая' },
];

function getOneFacilitySiteSettings({ oneFacilitySiteSettingState, match }) {
  const { facilityId } = (match || {}).params || {};

  const { list } = oneFacilitySiteSettingState || {};
  const currentFacilitySiteSetting = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = '' + id === '' + facilityId;

    return isNeeded;
  });

  return {
    facilityId,
    currentFacilitySiteSetting,
  };
}

function getCurrentActiveTariffPlans({ activeTariffPlansState, facilityId }) {
  const activeTariffPlansList = (activeTariffPlansState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const activeTariffPlans = activeTariffPlansList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    activeTariffPlans,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function SiteSettingsPage({
  match,

  getFacilitySiteSettingReg,
  oneFacilitySiteSettingState,

  activeTariffPlansState,
  getActiveTariffPlansReg,

  setFacilitySiteSettingReg,
  setFacilitySiteSettingState,

  downloadQrImageFacilitySiteReg,

  registrationStatusData,
}) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const { facilityId } = (match || {}).params || {};
  const { isRegistrationCompleted } = registrationStatusData || {};

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      themeType: THEMES[0],
      slug: '',
      name: '',
      description: '',
      showPhone: true,
      showRoute: true,
      showFreePlacesCount: true,
      showTariffs: true,
      showParkingRules: true,
      showEnterBtn: true,
      showSearchByPlate: true,
      preferSearchByPlate: false,
      showPartner: true,
      showProfile: false,
      showTariffPlans: [],
    },
  });

  const hasSiteUpdateRight = useCheckFacilityRights(RIGHT_KEYS.siteUpdate, facilityId);

  const hasPaymentModule = useHasFacilityModule(facilityId, MODULES_KEYS.payments);

  useEffect(() => {
    const { loading: isLoading } = oneFacilitySiteSettingState || {};

    if (isLoading) {
      return;
    }

    const { currentFacilitySiteSetting } = getOneFacilitySiteSettings({
      oneFacilitySiteSettingState,
      match,
    });

    if (currentFacilitySiteSetting) {
      updateState({
        ...currentFacilitySiteSetting,
        showTariffPlans: currentFacilitySiteSetting.showTariffIds || [],
        themeType: THEMES.find(({ id }) => id === currentFacilitySiteSetting.themeType),
      });
      return;
    }

    getFacilitySiteSettingReg({ id: facilityId });
  }, [facilityId, getFacilitySiteSettingReg, match, oneFacilitySiteSettingState, updateState]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const { activeTariffPlans } = getCurrentActiveTariffPlans({
      activeTariffPlansState,
      facilityId,
    });

    const { loading, error } = activeTariffPlansState || {};

    if (!activeTariffPlans && !loading && !error) {
      getActiveTariffPlansReg({
        facilityId,
      });
    }
  }, [facilityId, activeTariffPlansState, getActiveTariffPlansReg]);

  const { activeTariffPlans } = getCurrentActiveTariffPlans({
    activeTariffPlansState,
    facilityId,
  });

  const { data: activeTariffPlansData } = activeTariffPlans || {};

  const tariffPlansSelectData = useMemo(() => {
    const data = (activeTariffPlansData || []).map(({ id, name }) => ({
      id,
      title: name,
    }));

    return data;
  }, [activeTariffPlansData]);

  const handleThemeChange = useCallback(
    (_, newValue) => {
      updateState({
        themeType: newValue,
      });
    },
    [updateState],
  );

  const handleTariffPlansChange = useCallback(
    (_, newValue) => {
      const selTariffPlans = (newValue || []).map(({ id }) => id);

      updateState({
        showTariffPlans: selTariffPlans,
      });
    },
    [updateState],
  );

  const selTariffPlansItems = useMemo(() => tariffPlansSelectData.filter(({ id }) => fields.showTariffPlans.includes(id)), [fields.showTariffPlans, tariffPlansSelectData]);

  const handleSaveClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (!hasSiteUpdateRight) {
        return;
      }

      // TODO validation

      setFacilitySiteSettingReg({
        id: facilityId,
        slug: fields.slug,
        name: fields.name,
        description: fields.description,
        themeType: fields.themeType.id || SITE_THEMES.default,
        showPhone: fields.showPhone,
        showRoute: fields.showRoute,
        showFreePlacesCount: fields.showFreePlacesCount,
        showTariffs: hasPaymentModule && fields.showTariffs,
        showParkingRules: fields.showParkingRules,
        showEnterBtn: fields.showEnterBtn,
        showSearchByPlate: fields.showSearchByPlate,
        preferSearchByPlate: fields.showSearchByPlate && fields.preferSearchByPlate,
        showPartner: fields.showPartner,
        showProfile: fields.showProfile,
        showTariffIds: fields.showTariffPlans,
      });
    },
    [facilityId, fields, hasSiteUpdateRight, setFacilitySiteSettingReg, hasPaymentModule],
  );

  const { loading: isSiteSettingLoading } = oneFacilitySiteSettingState || {};

  const { currentFacilitySiteSetting } = getOneFacilitySiteSettings({
    oneFacilitySiteSettingState,
    match,
  });

  const { error: currentFacilitySiteError } = currentFacilitySiteSetting || {};

  const { loading: isSaveLoading } = setFacilitySiteSettingState || {};

  if (isSiteSettingLoading || currentFacilitySiteError) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn title="Настройки мини-сайта" isBackBtn={false} />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn title="Настройки веб-сайта" isBackBtn={false} />
      <form className={styles.form} autoComplete="off" onSubmit={handleSaveClick} noValidate>
        <Grid container item spacing={2} xs={12} sm={12} md={9} lg={9} className={styles.form}>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} md={5}>
              <Tooltip arrow title="Для смены домена обратитесь в поддержку">
                <div>
                  <CustomTextInput
                    className={styles.inputItem}
                    error={errors.slug}
                    name="slug"
                    label="Домен"
                    value={fields.slug}
                    autoComplete="off"
                    required
                    inputProps={{
                      maxLength: 250,
                    }}
                    disabled
                    onChange={onChange}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.name}
                name="name"
                label="Название парковки"
                value={fields.name}
                autoComplete="off"
                required
                inputProps={{
                  maxLength: 250,
                }}
                disabled={!hasSiteUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                multiline
                minRows={8}
                label="Описание парковки"
                className={styles.inputItem}
                error={errors.description}
                name="description"
                value={fields.description}
                autoComplete="off"
                disabled={!hasSiteUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={tariffPlansSelectData}
                value={selTariffPlansItems}
                disableClearable
                disabled={!hasSiteUpdateRight}
                onChange={handleTariffPlansChange}
                renderInput={(params) => (
                  <CustomTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    className={styles.inputItem}
                    error={errors.themeType}
                    disabled={!hasSiteUpdateRight}
                    label="Тарифы на сайте"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={THEMES}
                value={fields.themeType}
                disableClearable
                disabled={!hasSiteUpdateRight}
                onChange={handleThemeChange}
                renderInput={(params) => (
                  <CustomTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    className={styles.inputItem}
                    error={errors.themeType}
                    disabled={!hasSiteUpdateRight}
                    label="Тема оформления сайта"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>Показывать на сайте</Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showPhone}
                      name="showPhone"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label={'Кнопка "Диспетчер"'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showRoute}
                      name="showRoute"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label={'Кнопка "Построить маршрут"'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showFreePlacesCount}
                      name="showFreePlacesCount"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Количество свободных мест"
                />
              </Grid>
              {hasPaymentModule && (
                <Grid item xs={12}>
                  <FormControlLabel
                    className={styles.checkbox}
                    control={(
                      <Checkbox
                        disabled={!hasSiteUpdateRight}
                        checked={fields.showTariffs}
                        name="showTariffs"
                        onChange={onChange}
                        color="primary"
                      />
                    )}
                    label="Ссылка тарифы"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showParkingRules}
                      name="showParkingRules"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Правила парковки"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showSearchByPlate}
                      name="showSearchByPlate"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Поиск по госномеру"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight || !fields.showSearchByPlate}
                      checked={fields.showSearchByPlate && fields.preferSearchByPlate}
                      name="preferSearchByPlate"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Предпочтителен поиск по госномеру"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled
                      checked={fields.showProfile}
                      name="showProfile"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Личный кабинет"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasSiteUpdateRight}
                      checked={fields.showPartner}
                      name="showPartner"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Данные владельца"
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={isSaveLoading || !hasSiteUpdateRight}
              >
                Сохранить
              </CustomBtn>
            </Grid>
            <Grid item xs={4}>
              <CustomBtn className={styles.btnSubmit} onClick={() => downloadQrImageFacilitySiteReg({ facilityId })}>
                Скачать QR
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  const { facilities, companies, tariffs } = state || {};
  const { oneFacilitySiteSetting, setFacilitySiteSetting } = facilities || {};
  const { registrationStatus } = companies || {};
  const { activeTariffPlans } = tariffs || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    oneFacilitySiteSettingState: oneFacilitySiteSetting,
    setFacilitySiteSettingState: setFacilitySiteSetting,
    activeTariffPlansState: activeTariffPlans,
  };
}

const ConnectedSiteSettingsPage = connect(mapStateToProps, {
  getFacilitySiteSettingReg: getFacilitySiteSettingAction,
  setFacilitySiteSettingReg: setFacilitySiteSettingAction,
  getActiveTariffPlansReg: getActiveTariffPlansAction,
  downloadQrImageFacilitySiteReg: downloadQrImageFacilitySiteAction,
})(SiteSettingsPage);

export default ConnectedSiteSettingsPage;
