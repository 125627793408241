import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column',
    },
  },
  accordionSummaryTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  accordionSummaryDescription: {

  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  container: {
    minWidth: 700,
    width: '100%',

  },
  cellHeader: {
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: '4px 4px 0 0',
    padding: '4px 0 4px 0',
  },
  borderRadiusBottom: {
    borderRadius: '0 0 4px 4px',
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  cellBorder: {
    '&:last-child': {
      borderBottom: 0,
    },
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderLeft: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  cellZone: {
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderLeft: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRight: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  addLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));

export default {
  useStyles,
};
