import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { useConditionalsControllers } from 'api/query/controllers';

import { useStyles } from './styles';

function AddConditionalModalContent({
  facilityId,
  onClose,
  onAdd,
  accessItems,
  zoneIndex,
  gateIndex,
  inputIndex,
  actionIndex,
}) {
  const styles = useStyles();
  const [controllerId, setControllerId] = useState('');
  const [portId, setPortId] = useState('');
  const [value, setValue] = useState('');

  const { data: controllersData, isLoading } = useConditionalsControllers({
    variables: { facilityId },
  });

  const controllersItems = useMemo(
    () => (controllersData || []).map(({ id, name }) => ({ id, title: name })),
    [controllersData],
  );

  const portsItemsMap = useMemo(() => {
    const result = {};

    (controllersData || []).forEach(({ id, ports }) => {
      const portsResult = [];
      (ports || []).forEach(({ id: pId, name: portName }) => {
        portsResult.push({ id: pId, title: portName });
      });
      result[id] = portsResult;
    });

    return result;
  }, [controllersData]);

  const handleOnSave = useCallback(() => {
    if (portId === '' || controllerId === '' || value === '') {
      return;
    }

    const port = portsItemsMap[controllerId].find(({ id }) => id === portId);

    onAdd(zoneIndex, gateIndex, inputIndex, actionIndex, { port_id: port.id, value });
  }, [actionIndex, controllerId, gateIndex, inputIndex, onAdd, portId, portsItemsMap, value, zoneIndex]);

  return (
    <CustomModalContentWrapper
      title="Добавить контроллер"
      onClose={onClose}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={controllersItems}
            label="Контроллер"
            name="controllerId"
            onChange={(e) => {
              setPortId('');
              setControllerId(e.target.value);
            }}
            currentValue={controllerId}
          />
        </Grid>
        <Grid item xs={6}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={portsItemsMap[controllerId] || []}
            label="Порт"
            name="portId"
            disabled={controllerId === ''}
            onChange={(e) => setPortId(e.target.value)}
            currentValue={portId}
          />
        </Grid>
        <Grid item xs={6}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={[{ id: '0', title: '0' }, { id: '1', title: '1' }]}
            label="Значение"
            name="value"
            onChange={(e) => setValue(e.target.value)}
            currentValue={value}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomBtn disabled={portId === ''} onClick={handleOnSave} className={styles.saveBtn} btnType="primaryBlue">
            Добавить
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export default AddConditionalModalContent;
