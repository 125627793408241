import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import { DelimitedNumericArrayParam, StringParam, withDefault } from 'use-query-params';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

import { useFacilities } from 'api/query/facilities';
import { useQuotas } from 'api/query/quotas';
import PersonalAccountSelect from 'components/molecules/PersonalAccountSelect';
import QuotasTable from 'components/templates/QuotasTable';
import {
  MODULES_KEYS,
  PATH_PAGES, QUOTAS_TYPES, QUOTAS_TYPES_MAP, RIGHT_KEYS,
} from 'config/constants';
import { useCommonRights, useDebounce, useHasAnyFacilityModule } from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam,
  PageParam,
  withParams,
  withQueryParams,
} from 'helpers/queryParams';

import { useStyles } from './styles';

const TYPES = [
  { id: QUOTAS_TYPES.activeTicket, title: QUOTAS_TYPES_MAP[QUOTAS_TYPES.activeTicket] },
  { id: QUOTAS_TYPES.activeSession, title: QUOTAS_TYPES_MAP[QUOTAS_TYPES.activeSession] },
];

function getInitParams() {
  return {
    page: 1,
    types: [],
    personalAccounts: [],
    facilities: [],
    search: '',
  };
}

function prepareParam({ params }) {
  const {
    page,
    search,
    types,
    personalAccounts,
    facilities,
  } = params || {};

  const searchValue = (search || '').trim();

  const reqParam = {
    page,
    search: searchValue,
    types,
    personalAccounts,
    facilities,
  };

  return reqParam;
}

function AllQuotaPage({
  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const history = useHistory();

  const hasQuotaUpdateRight = useCommonRights(RIGHT_KEYS.quotaModify);

  const variables = useDebounce(params, 300);

  const hasPersonalAccountModule = useHasAnyFacilityModule(MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const { data: quotasData, isLoading } = useQuotas({
    variables,
    refetchInterval: 1000 * 60 * 10, // 10 mins
    refetchIntervalInBackground: true,
  });

  const {
    data,
    meta,
  } = quotasData || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = meta || {};

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params, setParams]);

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;

    handleParamChange(propName, value);
  }, [handleParamChange]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  const { data: facilitiesData } = useFacilities({});

  const facilitiesItems = useMemo(() => (facilitiesData || []).map(({ id, title }) => ({ id, title })), [facilitiesData]);

  const {
    search,
    types,
    facilities,
    personalAccounts,
  } = params || {};

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title="Квоты"
        isBackBtn={false}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CustomTextInput
              style={{ margin: 0 }}
              name="search"
              label="Поиск"
              value={search}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MultiItemSelectors
              classNameForm={styles.statusSelectForm}
              className={styles.statusSelect}
              name="types"
              currentValue={types}
              items={TYPES}
              unselectText="Тип не выбран"
              onChange={handleChanges}
            />
          </Grid>
          {hasPersonalAccountModule && hasPersonalAccountChargeRight && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PersonalAccountSelect
                name="personalAccounts"
                currentValue={personalAccounts}
                onChange={handleChanges}
                unselectText="Лицевой счет не выбран"
              />
            </Grid>
          )}
          {((facilitiesItems || []).length > 1) && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MultiItemSelectors
                classNameForm={styles.statusSelectForm}
                className={styles.statusSelect}
                name="facilities"
                currentValue={facilities}
                items={facilitiesItems}
                unselectText="Все парковки"
                onChange={handleChanges}
              />
            </Grid>
          )}
          {hasQuotaUpdateRight && (
            <Grid container item spacing={2}>
              <Grid item>
                <CustomBtn
                  btnType="primaryGreen"
                  className={styles.addNewBtn}
                  onClick={() => history.push(PATH_PAGES.newQuota)}
                >
                  Добавить квоту
                </CustomBtn>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading && (
              <CircularIndeterminate style={{ minHeight: 600 }} />
            )}
            {!isLoading && (
              <QuotasTable data={data} />
            )}
            {!isLoading && lastPage > 1 && (
              <div className={styles.paginationContainer}>
                <Pagination
                  className={styles.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export const AllQuotaPageWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(AllQuotaPage);

const TypeArrayEnumParam = createEnumDelimitedNumericArrayParam([
  QUOTAS_TYPES.activeTicket,
  QUOTAS_TYPES.activeSession,
]);

const AllQuotaPageWithQueryParams = withQueryParams({
  queryParams: {
    search: withDefault(StringParam, ''),
    types: withDefault(TypeArrayEnumParam, []),
    personalAccounts: withDefault(DelimitedNumericArrayParam, []),
    facilities: withDefault(DelimitedNumericArrayParam, []),
    page: PageParam,
  },
  prepareParam,
})(AllQuotaPage);

export default AllQuotaPageWithQueryParams;
