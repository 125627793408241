import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { useFormFields } from '@parkly/shared/helpers';

import { useStyles } from './styles';

function AddAccessSchemaModalContent({
  facilityId,
  onClose,
  onAdd,
}) {
  const styles = useStyles();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
    },
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const isTitleValid = fields.name.length > 0;

    if (!isTitleValid) {
      setErrors({
        name: !isTitleValid,
      });
      return;
    }

    onAdd(fields.name);
  }, [fields, onAdd, setErrors]);

  return (
    <CustomModalContentWrapper
      title="Добавить схему доступа"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12}>
            <CustomTextInput
              required
              style={{ margin: 0 }}
              name="name"
              label="Название"
              value={fields.name}
              error={errors.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomBtn type="submit" className={styles.saveBtn} btnType="primaryBlue">
              Добавить
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </CustomModalContentWrapper>
  );
}
export default AddAccessSchemaModalContent;
