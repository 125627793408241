import React, { useMemo } from 'react';

import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

import { usePersonalAccountsForCharge } from 'api/query/personalAccounts';

import { useStyles } from './styles';

export default function PersonalAccountSelect({
  name,
  currentValue,
  onChange,
  unselectText,
}) {
  const styles = useStyles();
  const { data: personalAccountsData, isLoading: isPersonalAccountsLoading } = usePersonalAccountsForCharge({
    variables: {},
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name: accName, number }) => ({
    id,
    title: accName ? `${number} - ${accName}` : number,
  })), [personalAccountsData]);

  return (
    <MultiItemSelectors
      classNameForm={styles.selectForm}
      className={styles.select}
      items={personalAccountItems}
      unselectText={unselectText}
      name={name}
      onChange={onChange}
      currentValue={currentValue}
    />
  );
}
