import { callAPI } from '@parkly/shared/api';

export function validGetCompany(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    isCompleted,
    name,
    partnerName,
    shortName,
    postcode,
    region,
    street,
    house,
    postalPostcode,
    postalRegion,
    postalStreet,
    postalHouse,
    inn,
    kpp,
    ogrn,
    accountNumber,
    bankCode,
    corrNumber,
    bankName,
    email,
    receiptPhone,
  } = resultData;

  return {
    id,
    isCompleted,
    partnerName,
    name,
    shortName,
    postcode,
    region,
    street,
    house,
    postalPostcode,
    postalRegion,
    postalStreet,
    postalHouse,
    inn,
    kpp,
    ogrn,
    accountNumber,
    bankCode,
    corrNumber,
    bankName,
    email,
    receiptPhone,
  };
}

export function validGetRegistrationStatus(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    isCompanyCompleted,
    isFacilityCompleted,
    isFacilityScheduleCompleted,
    isFacilityPlacesCompleted,
    isFacilityIntegrationCompleted,
    isRegistrationCompleted,
  } = resultData;

  return {
    isCompanyCompleted,
    isFacilityCompleted,
    isFacilityScheduleCompleted,
    isFacilityPlacesCompleted,
    isFacilityIntegrationCompleted,
    isRegistrationCompleted,
  };
}

export async function getCompany() {
  return callAPI('company')
    .then((res) => validGetCompany({
      res,
    }));
}

export async function setCompanyInfo({
  name,
  inn,
  kpp,
  email,
  postcode,
  region,
  street,
  house,
  postalPostcode,
  postalRegion,
  postalStreet,
  postalHouse,
  accountNumber,
  bankCode,
  corrNumber,
  bankName,
  receiptPhone,
}) {
  return callAPI(
    'company',
    'POST',
    {
      name,
      inn,
      kpp,
      email,
      postcode,
      region,
      street,
      house,
      postalPostcode,
      postalRegion,
      postalStreet,
      postalHouse,
      accountNumber,
      bankCode,
      corrNumber,
      bankName,
      receiptPhone,
    },
  )
    .then((res) => res);
}

export async function getRegistrationStatus() {
  return callAPI(
    'registration-status',
    'GET',
  )
    .then((res) => validGetRegistrationStatus({
      res,
    }));
}

function validGetModules(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    all,
    any,
    facilities,
  } = resultData;

  return {
    all,
    any,
    facilities,
  };
}

export async function getAllModules() {
  return callAPI(
    'modules',
    'GET',
  )
    .then((res) => validGetModules({
      res,
    }));
}

export default {
  getCompany,
  setCompanyInfo,
  getRegistrationStatus,
};
