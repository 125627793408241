import React, { useMemo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { useControllers } from 'api/query/controllers';

import { useFacilityAreas } from '../../../../api/query/gates';

import { useStyles } from './styles';

export default function ChangeZoneAction({
  facilityId,
  params,
  onChange = () => {},
  devShow = false,
}) {
  const styles = useStyles();

  const { data: areaData } = useFacilityAreas({
    variables: { id: facilityId },
  });

  const areaItems = useMemo(() => {
    const result = [];

    result.push({
      id: 0,
      title: 'Вне парковки',
    });

    (areaData || []).forEach(({ id, name }) => {
      result.push({
        id,
        title: name,
      });
    });

    return result;
  }, [areaData]);

  return (
    <>
      <Grid item xs={12}>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={areaItems}
          label="Зона"
          name="zoneId"
          onChange={(e) => { onChange({ ...params, to: e.target.value }); }}
          currentValue={params.to === undefined ? '' : params.to}
        />
      </Grid>
      {devShow && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              required
              style={{ margin: 0 }}
              label="Повтоный интервал (cек)"
              value={params.repeat_interval || 0}
              inputProps={{
                type: 'number',
                min: 0,
              }}
              onChange={(e) => { onChange({ ...params, repeat_interval: e.target.value }); }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={styles.checkbox}
              control={(
                <Checkbox
                  checked={params.free_change_zone || false}
                  onChange={(e) => { onChange({ ...params, free_change_zone: e.target.checked }); }}
                  color="primary"
                />
              )}
              label="Свободная смена зоны"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              className={styles.checkbox}
              control={(
                <Checkbox
                  checked={params.with_two_stage || false}
                  onChange={(e) => { onChange({ ...params, with_two_stage: e.target.checked }); }}
                  color="primary"
                />
              )}
              label="C подтверждением"
            />
          </Grid>
        </>
      )}
    </>
  );
}
