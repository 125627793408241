import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Chip, CircularProgress,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clone from 'lodash/clone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useModal } from '@parkly/shared/helpers';

import {
  useDeleteDefaultAccessSchemaMutation,
  useSetAccessSchemaMutation,
  useSetDefaultAccessSchemaMutation,
} from 'api/query/accessSchemas';
import { ACCESS_ACTION_TYPES } from 'config/constants';

import AddActionModalContent from './Modals/AddActionModalContent';
import AddConditionalModalContent from './Modals/AddConditionalModalContent';
import AddGateModalContent from './Modals/AddGateModalContent';
import AddInputModalContent from './Modals/AddInputModalContent';
import AddZoneModalContent from './Modals/AddZoneModalContent';
import { useStyles } from './styles';

const AddButton = ({
  onClick = () => {},
  label,
}) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item>
        <IconButton
          size="small"
          onClick={onClick}
        >
          <AddIcon fontSize="small" htmlColor="#00C77F" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function AccessSchema({
  facilityId,
  accessSchema,
  zonesData,
  controllersData,
  gatesData,
  onReload = () => {},
}) {
  const styles = useStyles();
  const theme = useTheme();

  const {
    id: accessSchemaId,
    name: initName,
    description: initDescription,
    is_default: isDefault,
    items: initItems,
  } = accessSchema || {};

  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState(initName);
  const [description, setDescription] = useState(initDescription);
  const [items, setItems] = useState(initItems);

  useEffect(() => {
    setItems(initItems || []);
  }, [initItems]);

  const setAccessSchemaMutation = useSetAccessSchemaMutation({
    onSuccess({ success }) {
      if (success) {
        onReload();
      }
    },
  });

  const { isLoading: isSaveLoading } = setAccessSchemaMutation;

  const setDefaultAccessSchemaMutation = useSetDefaultAccessSchemaMutation({
    onSuccess({ success }) {
      if (success) {
        onReload();
      }
    },
  });

  const { isLoading: isSetDefaultLoading } = setDefaultAccessSchemaMutation;

  const deleteAccessSchemaMutation = useDeleteDefaultAccessSchemaMutation({
    onSuccess({ success }) {
      if (success) {
        onReload();
      }
    },
  });

  const { isLoading: isDeleteLoading } = deleteAccessSchemaMutation;

  const zoneNameMap = useMemo(() => {
    const result = {};

    (zonesData || []).forEach(({ id, name: zoneName }) => {
      result[id] = zoneName;
    });

    result[0] = 'Вне парковки';

    return result;
  }, [zonesData]);

  const gateNameMap = useMemo(() => {
    const result = {};

    (gatesData || []).forEach(({ id, name: gateName }) => {
      result[id] = gateName;
    });

    return result;
  }, [gatesData]);

  const portControllerNameMap = useMemo(() => {
    const result = {};

    (controllersData || []).forEach(({ name: ctlName, ports }) => {
      (ports || []).forEach(({ id: portId, name: portName }) => {
        result[portId] = {
          name: portName,
          ctlName,
        };
      });
    });

    return result;
  }, [controllersData]);

  const [addZoneModalNode, openAddZoneModal, hideAddZoneModal] = useModal({
    content: <AddZoneModalContent
      facilityId={facilityId}
      onClose={() => hideAddZoneModal()}
      onAdd={(zone) => {
        items.push({
          gates: [],
          ...zone,
        });

        setItems(clone(items));

        hideAddZoneModal();
      }}
    />,
  });

  const [addGateModalNode, openAddGateModal, hideAddGateModal] = useModal({
    content: <AddGateModalContent
      facilityId={facilityId}
      onClose={() => hideAddGateModal()}
      onAdd={(zoneIndex, gate) => {
        items[zoneIndex].gates.push({
          inputs: [],
          ...gate,
        });

        setItems(clone(items));

        hideAddGateModal();
      }}
    />,
  });

  const [addInputModalNode, openAddInputModal, hideAddInputModal] = useModal({
    content: <AddInputModalContent
      facilityId={facilityId}
      onClose={() => hideAddInputModal()}
      onAdd={(zoneIndex, gateIndex, input) => {
        items[zoneIndex].gates[gateIndex].inputs.push({
          actions: [],
          ...input,
        });

        setItems(clone(items));

        hideAddInputModal();
      }}
    />,
  });

  const [addActionModalNode, openAddActionModal, hideAddActionModal] = useModal({
    content: <AddActionModalContent
      facilityId={facilityId}
      onClose={() => hideAddActionModal()}
      onAdd={(zoneIndex, gateIndex, inputIndex, actionType, action) => {
        items[zoneIndex].gates[gateIndex].inputs[inputIndex].actions.push({
          conditionals: [],
          action: {
            conditionals: [],
            ...action,
          },
          actionType,
        });

        setItems(clone(items));

        hideAddActionModal();
      }}
    />,
  });

  const [addCondModalNode, openAddCondModal, hideAddCondModal] = useModal({
    content: <AddConditionalModalContent
      facilityId={facilityId}
      onClose={() => hideAddCondModal()}
      onAdd={(zoneIndex, gateIndex, inputIndex, actionIndex, cond) => {
        items[zoneIndex].gates[gateIndex].inputs[inputIndex].actions[actionIndex].action.conditionals.push({
          ...cond,
        });

        setItems(clone(items));

        hideAddCondModal();
      }}
    />,
  });

  const createHandlerDeleteZone = (zoneIndex) => () => {
    items.splice(zoneIndex, 1);

    setItems(clone(items));
  };

  const createHandlerDeleteGate = (zoneIndex, gateIndex) => () => {
    items[zoneIndex].gates.splice(gateIndex, 1);

    setItems(clone(items));
  };

  const createHandlerDeleteInput = (zoneIndex, gateIndex, inputIndex) => () => {
    items[zoneIndex].gates[gateIndex].inputs.splice(inputIndex, 1);

    setItems(clone(items));
  };

  const createHandlerDeleteAction = (zoneIndex, gateIndex, inputIndex, actionIndex) => () => {
    items[zoneIndex].gates[gateIndex].inputs[inputIndex].actions.splice(actionIndex, 1);

    setItems(clone(items));
  };

  const createHandlerDeleteConditional = (zoneIndex, gateIndex, inputIndex, actionIndex, condIndex) => () => {
    items[zoneIndex].gates[gateIndex].inputs[inputIndex].actions[actionIndex].action.conditionals.splice(condIndex, 1);

    setItems(clone(items));
  };

  const onDeleteClick = () => {
    deleteAccessSchemaMutation.mutate({ id: accessSchemaId, facilityId });
  };

  const onDefaultClick = () => {
    setDefaultAccessSchemaMutation.mutate({ id: accessSchemaId, facilityId });
  };

  const onSaveClick = () => {
    const resultItems = [];

    (items || []).forEach((zone) => {
      (zone.gates || []).forEach((gate) => {
        (gate.inputs || []).forEach((input) => {
          (input.actions || []).forEach((action) => {
            const conditionals = [];

            (action.action.conditionals || []).forEach((cond) => {
              conditionals.push({
                port_id: cond.port_id,
                value: cond.value,
              });
            });

            let actionData = {};

            switch (action.actionType) {
              case ACCESS_ACTION_TYPES.changeZone:
                actionData = {
                  to: action.action.to,
                  repeat_interval: parseInt(action.action.repeat_interval || 0, 10),
                  with_two_stage: action.action.with_two_stage || false,
                  free_change_zone: action.action.free_change_zone || false,
                  free_enter: false,
                  free_exit: false,
                  anti_flood_interval: 0,
                  conditionals,
                };
                break;
              case ACCESS_ACTION_TYPES.callController:
              case ACCESS_ACTION_TYPES.callPayment:
                actionData = {
                  port_id: action.action.port_id,
                  conditionals,
                };
                break;
              case ACCESS_ACTION_TYPES.confirm:
                break;
            }

            resultItems.push({
              id: action.item_id || undefined,
              from_zone_id: zone.id,
              is_from_draft_zone: zone.isDraft || false,
              gate_id: gate.id,
              input_type: input.type,
              input: input.id,
              action_type: action.actionType,
              action: actionData,
            });
          });
        });
      });
    });

    setAccessSchemaMutation.mutate({
      id: accessSchemaId,
      facilityId,
      name,
      description,
      items: resultItems,
    });
  };

  return (
    <Accordion expanded={expanded} onChange={(_, i) => setExpanded(i)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.accordionSummary}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <Typography className={styles.accordionSummaryTitle}>{name}</Typography>
          {isDefault && (
            <Chip size="small" color="primary" label="По умолчанию" />
          )}
        </div>
        <Typography>{description}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomTextInput
                  required
                  style={{ margin: 0 }}
                  name="name"
                  label="Название"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <CustomTextInput
                  multiline
                  maxRows={4}
                  label="Описание"
                  style={{ margin: 0 }}
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ overflow: 'auto', paddingBottom: 8 }}>
            <Grid container spacing={0} className={styles.container}>
              <Grid item xs={12}>
                <Grid container className={styles.cellHeader}>
                  <Grid item xs={2}>
                    <Typography align="center">
                      Зона
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container className={styles.borderLeft}>
                      <Grid item xs={2}>
                        <Typography align="center">
                          Барьер
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container className={styles.borderLeft}>
                          <Grid item xs={3}>
                            <Typography align="center">
                              Контроллер
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container className={styles.borderLeft}>
                              <Grid item xs={6}>
                                <Typography align="center">
                                  Действие
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container className={styles.borderLeft}>
                                  <Grid item xs={12}>
                                    <Typography align="center">
                                      Условие
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {(items || []).map(({ id: zoneId, is_from_draft_zone: isDraftZone, gates }, zoneIndex) => (
                <Grid key={`zoneIndex-${zoneIndex}`} item xs={12} className={styles.cellZone}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems="stretch"
                        justifyContent="space-between"
                        direction="column"
                        style={{ height: '100%', overflowX: 'hidden' }}
                      >
                        <Grid item xs={12}>
                          <Typography align="center">
                            {isDraftZone ? `${zoneNameMap[zoneId] || '-'} (черновик)` : zoneNameMap[zoneId] || '-'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton size="small" onClick={createHandlerDeleteZone(zoneIndex)}>
                            <DeleteIcon fontSize="small" htmlColor={theme.palette.greyC.gray2} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container direction="row">
                        {(gates || []).map(({ id: gateId, inputs }, gateIndex) => (
                          <Grid key={`${zoneIndex}-${gateIndex}`} item xs={12} className={styles.cellBorder}>
                            <Grid container>
                              <Grid item xs={2}>
                                <Grid
                                  container
                                  wrap="nowrap"
                                  alignItems="stretch"
                                  justifyContent="space-between"
                                  direction="column"
                                  style={{ height: '100%', overflowX: 'hidden' }}
                                >
                                  <Grid item xs={12}>
                                    <Typography align="center">
                                      {gateNameMap[gateId] || '-'}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <IconButton size="small" onClick={createHandlerDeleteGate(zoneIndex, gateIndex)}>
                                      <DeleteIcon
                                        fontSize="small"
                                        htmlColor={theme.palette.greyC.gray2}

                                      />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={10}>
                                <Grid container direction="row">
                                  {(inputs || []).map(({ type: inType, actions, id: inputId }, inputIndex) => (
                                    <Grid
                                      key={`${zoneIndex}-${gateIndex}-${inputIndex}`}
                                      item
                                      xs={12}
                                      className={styles.cellBorder}
                                    >
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            alignItems="stretch"
                                            justifyContent="space-between"
                                            direction="column"
                                            style={{ height: '100%', overflowX: 'hidden' }}
                                          >
                                            {inType === 1 && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography align="center" style={{ color: 'grey' }}>
                                                    {portControllerNameMap[inputId].ctlName || '-'}
                                                  </Typography>
                                                  <Typography align="center">
                                                    {portControllerNameMap[inputId].name || '-'}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            <Grid item>
                                              <IconButton size="small" onClick={createHandlerDeleteInput(zoneIndex, gateIndex, inputIndex)}>
                                                <DeleteIcon
                                                  fontSize="small"
                                                  htmlColor={theme.palette.greyC.gray2}
                                                />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                          <Grid container direction="row">
                                            {(actions || []).map(({
                                              actionType,
                                              action,
                                            }, actionIndex) => (
                                              <Grid
                                                key={`${zoneIndex}-${gateIndex}-${inputIndex}-${actionIndex}`}
                                                item
                                                xs={12}
                                                className={styles.cellBorder}
                                              >
                                                <Grid container>
                                                  <Grid item xs={6}>
                                                    <Grid
                                                      container
                                                      wrap="nowrap"
                                                      alignItems="stretch"
                                                      justifyContent="space-between"
                                                      direction="column"
                                                      style={{ height: '100%', overflowX: 'hidden' }}
                                                    >
                                                      {actionType === ACCESS_ACTION_TYPES.changeZone && (
                                                        <>
                                                          <Grid item xs={12}>
                                                            <Typography align="center" style={{ color: 'grey' }}>
                                                              Сменить зону на
                                                            </Typography>
                                                            <Typography align="center">
                                                              {zoneNameMap[action.to] || ''}
                                                            </Typography>
                                                          </Grid>
                                                        </>
                                                      )}
                                                      {[ACCESS_ACTION_TYPES.callController, ACCESS_ACTION_TYPES.callPayment].includes(actionType) && (
                                                        <>
                                                          <Grid item xs={12}>
                                                            <Typography align="center" style={{ color: 'grey' }}>
                                                              {portControllerNameMap[action.port_id].ctlName || '-'}
                                                            </Typography>
                                                            <Typography align="center">
                                                              {portControllerNameMap[action.port_id].name || '-'}
                                                            </Typography>
                                                          </Grid>
                                                        </>
                                                      )}
                                                      {actionType === ACCESS_ACTION_TYPES.confirm && (
                                                        <>
                                                          TODO
                                                        </>
                                                      )}
                                                      <Grid item>
                                                        <IconButton size="small" onClick={createHandlerDeleteAction(zoneIndex, gateIndex, inputIndex, actionIndex)}>
                                                          <DeleteIcon
                                                            fontSize="small"
                                                            htmlColor={theme.palette.greyC.gray2}

                                                          />
                                                        </IconButton>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                    <Grid container direction="row">
                                                      {(action.conditionals || []).map(({ port_id: condPortId, value: condValue }, conditionalsIndex) => (
                                                        <Grid
                                                          key={`${zoneIndex}-${gateIndex}-${inputIndex}-${actionIndex}-${conditionalsIndex}`}
                                                          item
                                                          xs={12}
                                                          className={styles.cellBorder}
                                                        >
                                                          <Grid
                                                            container
                                                            wrap="nowrap"
                                                            alignItems="stretch"
                                                            justifyContent="space-between"
                                                            direction="column"
                                                            style={{ height: '100%' }}
                                                          >
                                                            <Grid item xs={12}>
                                                              <Typography align="center" style={{ color: 'grey' }}>
                                                                {portControllerNameMap[condPortId].ctlName || '-'}
                                                              </Typography>
                                                              <Typography align="center">
                                                                {`${portControllerNameMap[condPortId].name || '-'} = ${condValue}`}
                                                              </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                              <IconButton size="small" onClick={createHandlerDeleteConditional(zoneIndex, gateIndex, inputIndex, actionIndex, conditionalsIndex)}>
                                                                <DeleteIcon
                                                                  fontSize="small"
                                                                  htmlColor={theme.palette.greyC.gray2}
                                                                />
                                                              </IconButton>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      ))}
                                                      {[ACCESS_ACTION_TYPES.callController, ACCESS_ACTION_TYPES.callPayment, ACCESS_ACTION_TYPES.changeZone].includes(actionType) && (
                                                        <Grid item xs={12} className={styles.cellBorder}>
                                                          <AddButton
                                                            onClick={() => openAddCondModal({
                                                              zoneIndex, gateIndex, inputIndex, actionIndex,
                                                            })}
                                                            label="Добавить условие"
                                                          />
                                                        </Grid>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            ))}
                                            <Grid item xs={12} className={styles.cellBorder}>
                                              <AddButton
                                                onClick={() => openAddActionModal({ zoneIndex, gateIndex, inputIndex })}
                                                label="Добавить действие"
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                                  <Grid item xs={12} className={styles.cellBorder}>
                                    <AddButton
                                      onClick={() => openAddInputModal({ zoneIndex, gateIndex })}
                                      label="Добавить контроллер"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={12} className={styles.cellBorder} style={{ height: '100%' }}>
                          <AddButton
                            onClick={() => openAddGateModal({ zoneIndex })}
                            label="Добавить барьер"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} className={[styles.cellZone, styles.borderRadiusBottom].join(' ')}>
                <AddButton
                  onClick={() => openAddZoneModal()}
                  label="Добавить зону"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        {accessSchemaId && !isDefault && (
          <CustomBtn
            disabled={isSaveLoading || isSetDefaultLoading || isDeleteLoading}
            btnType="secondary"
            onClick={onDeleteClick}
          >
            Удалить
            {isDeleteLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={16}
                color="inherit"
              />
            )}
          </CustomBtn>
        )}
        {accessSchemaId && !isDefault && (
          <CustomBtn
            disabled={isSaveLoading || isSetDefaultLoading || isDeleteLoading}
            btnType="default"
            onClick={onDefaultClick}
          >
            Сделать по умолчанию
            {isSetDefaultLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={16}
                color="inherit"
              />
            )}
          </CustomBtn>
        )}
        <CustomBtn
          disabled={isSaveLoading || isSetDefaultLoading || isDeleteLoading}
          onClick={onSaveClick}
          btnType="primaryBlue"
        >
          Сохранить
          {isSaveLoading && (
            <CircularProgress
              style={{ marginLeft: 5 }}
              size={16}
              color="inherit"
            />
          )}
        </CustomBtn>
      </AccordionActions>
      {addZoneModalNode}
      {addGateModalNode}
      {addInputModalNode}
      {addActionModalNode}
      {addCondModalNode}
    </Accordion>
  );
}

export default AccessSchema;
