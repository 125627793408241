import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { useFormFields } from '@parkly/shared/helpers';

import { useOnePersonalAccount, useSetOnePersonalAccount } from 'api/query/personalAccounts';
import HeaderWithBackBtn from 'components/molecules/HeaderWithBackBtn';
import {
  PATH_PAGES,
  PERSONAL_ACCOUNTS_STATUSES, PERSONAL_ACCOUNTS_STATUSES_MAP, RIGHT_KEYS,
} from 'config/constants';
import { useCommonRights } from 'helpers/hooks';

import { useStyles } from './styles';

const STATUS_ITEMS = [
  { id: PERSONAL_ACCOUNTS_STATUSES.active, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.active] },
  { id: PERSONAL_ACCOUNTS_STATUSES.blocked, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.blocked] },
  { id: PERSONAL_ACCOUNTS_STATUSES.closed, title: PERSONAL_ACCOUNTS_STATUSES_MAP[PERSONAL_ACCOUNTS_STATUSES.closed] },
];

export default function OnePersonalAccountPage({ match }) {
  const { params, path } = match || {};
  const {
    id,
  } = params || {};
  const styles = useStyles();
  const history = useHistory();

  const isNewMode = !id && path === PATH_PAGES.addNewPersonalAccounts;

  const isAdmin = useCommonRights(RIGHT_KEYS.admin);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      number: '',
      name: '',
      creditLimit: '',
      status: PERSONAL_ACCOUNTS_STATUSES.active,
    },
  });

  const { data: currentAccount, isLoading } = useOnePersonalAccount({
    variables: { id },
    enabled: !isNewMode,
  });

  useEffect(() => {
    if (currentAccount) {
      updateState({
        ...currentAccount,
        creditLimit: currentAccount.creditLimit === null ? '' : currentAccount.creditLimit,
      });
    }
  }, [currentAccount, updateState]);

  const setMutation = useSetOnePersonalAccount({
    onSuccess: ({ success, id: newId }, { id: setId }) => {
      if (success) {
        toast.success('Выполнено!');

        if (newId || setId) {
          history.replace(PATH_PAGES.oneInfoPersonalAccounts.replace(':id', newId || setId));
        }
      }
    },
  });

  const { isLoading: isSetLoading } = setMutation;

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!isAdmin && isNewMode) {
      return;
    }

    const newErrors = {};

    const numberRegex = /^[A-zА-я0-9\-/]+$/;
    if (!fields.number || !numberRegex.test(fields.number)) {
      newErrors.number = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (isNewMode) {
      setMutation.mutate({
        number: fields.number,
        name: fields.name,
        creditLimit: fields.creditLimit,
      });
    } else {
      setMutation.mutate({
        id,
        number: fields.number,
        name: fields.name,
        creditLimit: fields.creditLimit,
        status: fields.status,
      });
    }
  };

  if (
    !isNewMode && !currentAccount
    && isLoading
  ) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn title={isNewMode ? 'Добавление лицевого счета' : 'Лицевой счет'} />
      <form className={styles.form} noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <CustomTextInput
              className={styles.inputItem}
              required
              style={{ margin: 0 }}
              name="number"
              label="Номер лицевого счета"
              value={fields.number}
              error={errors.number}
              inputProps={{
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <CustomTextInput
              className={styles.inputItem}
              style={{ margin: 0 }}
              name="name"
              label="Название"
              value={fields.name}
              error={errors.name}
              inputProps={{
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <CustomTextInput
              className={styles.inputItem}
              style={{ margin: 0 }}
              name="creditLimit"
              label="Кредитный лимит"
              value={fields.creditLimit}
              error={errors.creditLimit}
              inputProps={{
                type: 'number',
                min: 0,
                maxLength: 250,
              }}
              onChange={onChange}
            />
            {fields.creditLimit === '' && (
              <Typography style={{ color: 'grey' }}>Без ограничения</Typography>
            )}
          </Grid>
          {!isNewMode && (
            <Grid item xs={12} md={7}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                name="status"
                label="Статус"
                error={errors.status}
                currentValue={fields.status}
                items={STATUS_ITEMS}
                onChange={onChange}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <CustomBtn
                  type="submit"
                  className={styles.btn}
                  btnType="primaryBlue"
                  disabled={isSetLoading}
                >
                  {isNewMode ? 'Добавить' : 'Сохранить'}
                  {isSetLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
