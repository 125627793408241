import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import { useGetRoles } from 'api/query/operators';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCommonRights } from 'helpers/hooks';

import { useStyles } from './styles';

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'name',
      label: 'Название',
    },
    {
      id: 'lastUpdatedAt',
      label: 'Обновлено',
    },
  ];

  return headers.filter((item) => !!item);
}

export default function AllOperatorRoles() {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const isOperatorUpdateRight = useCommonRights(RIGHT_KEYS.admin);

  const { data: roles, isLoading } = useGetRoles({
    variables: {},
  });

  const headers = getHeaders({ t });

  return (
    <Container
      className={styles.container}
      maxWidth="md"
    >
      <HeaderWithNavigationLinks
        links={[
          { to: PATH_PAGES.operatorsAll, label: 'Операторы' },
          { to: PATH_PAGES.operatorRolesAll, label: 'Роли' },
          { to: PATH_PAGES.operatorsActions, label: 'Журнал действий' },
        ]}
      />
      <div className={styles.pageBody}>
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {!isLoading && (
          <>
            {isOperatorUpdateRight && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <CustomBtn
                    btnType="primaryGreen"
                    className={styles.addNewBtn}
                    onClick={() => { history.push(PATH_PAGES.addNewOperatorRole); }}
                  >
                    Добавить роль
                  </CustomBtn>
                </Grid>
              </Grid>
            )}
            {(roles || []).length === 0 && (
              <Paper className={styles.noDataContainer}>
                <Typography className={styles.noDataStr}>
                  Пока нет ролей
                </Typography>
              </Paper>
            )}
            {(roles || []).length > 0 && (
              <TableContainer className={styles.tableContainer} component={Paper}>
                <Table className={styles.table} size="small">
                  <TableHead>
                    <TableRow>
                      {headers.map(({ label, id }) => (
                        <TableCell
                          key={id}
                          align="left"
                        >
                          <div className={styles.headerContainer}>
                            <Typography className={styles.headerStr}>
                              {label}
                            </Typography>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={styles.tableBody}>
                    {(roles || []).map((item, index, array) => {
                      const { id, name, updatedAt } = item;

                      const key = id;

                      const updatedAtTimeStr = updatedAt ? moment(updatedAt).format('HH:mm:ss') : '-';
                      const updatedAtDateStr = updatedAt ? moment(updatedAt).format('DD.MM.yyyy') : '-';

                      const isLast = (array.length - 1) === index;

                      const tableRowClass = [
                        styles.tableRow,
                        isLast ? styles.tableRowLast : '',
                      ];

                      return (
                        <TableRow
                          className={tableRowClass.join(' ')}
                          key={key}
                          name={id}
                          hover
                          tabIndex={-1}
                          onClick={() => { history.push(PATH_PAGES.oneOperatorRole.replace(':id', id)); }}
                        >
                          <TableCell align="left">
                            <Typography className={styles.nameStr}>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography className={styles.timeStr}>
                              {updatedAtTimeStr}
                            </Typography>
                            <Typography className={styles.dateStr}>
                              {updatedAtDateStr}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
