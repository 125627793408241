import { createQuery } from 'react-query-kit';

import { getAllModules } from 'api/company';
import { getModules } from 'api/licenses';

export const useFacilityLicenceModules = createQuery({
  primaryKey: 'facility-license-modules',
  queryFn: ({ queryKey: [_, { facilityId }] }) => {
    if (!parseInt(facilityId, 10)) {
      return {};
    }

    return getModules({ facilityId });
  },
  retry: false,
});

export const useModules = createQuery({
  primaryKey: 'modules',
  queryFn: () => getAllModules(),
  retry: false,
});
