import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { setControllerAction } from 'actions/controllers';
import { setController } from 'api/controllers';
import { CONTROLLER_TYPES } from 'config/constants';

import { useStyles } from './styles';

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeManualControllerModalContent({
  facilityId,
  id,
  onClose = () => {},
  setControllerApi = setController,

  allControllersState,
}) {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleSetController = useCallback(() => {
    setIsLoading(true);

    setControllerApi({
      facilityId,
      id,
      type: CONTROLLER_TYPES.manual,
      settings: {},
    }).then(() => {
      setIsLoading(false);
      onClose();
    }).catch(() => {
      setIsLoading(false);
    });
  }, [facilityId, id, onClose, setControllerApi]);

  return (
    <CustomModalContentWrapper
      title="Подключение Пульта охраны"
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={6}>
              <CustomBtn
                btnType="primaryBlue"
                className={styles.btn}
                disabled={isLoading}
                onClick={handleSetController}
              >
                {id ? 'Сохранить' : 'Добавить'}
                {(isLoading) && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController,
    allControllers,
  } = controllers || {};

  return {
    setControllerState: setController,
    allControllersState: allControllers,
  };
}

const ConnectedMakeManualControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeManualControllerModalContent);

export default ConnectedMakeManualControllerModalContent;
