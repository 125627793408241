import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';

import Sessions from 'components/organisms/Sessions';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function AllSessionsPage({ match }) {
  const styles = useStyles();
  const { t } = useTranslation();

  useCurrentFacilityMatch();
  const { params: matchParams } = match || {};
  const { facilityId: currentFacilityId } = matchParams || {};

  const hasPaymentModule = useHasFacilityModule(currentFacilityId, MODULES_KEYS.payments);

  const hasPaymentsShowRight = useCheckFacilityRights(RIGHT_KEYS.paymentsShow, currentFacilityId);
  const hasGatewayLogShowRight = useCheckFacilityRights(RIGHT_KEYS.gatewayLogShow, currentFacilityId);

  return (
    <Container maxWidth="xl" className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          { to: PATH_PAGES.sessions.replace(':facilityId', currentFacilityId), label: 'Сессии' },
          hasPaymentsShowRight && hasPaymentModule
            ? { to: PATH_PAGES.payments.replace(':facilityId', currentFacilityId), label: 'Оплаты' }
            : null,
          hasGatewayLogShowRight
            ? { to: PATH_PAGES.gatewayEvents.replace(':facilityId', currentFacilityId), label: 'Проезды' }
            : null,
        ]}
      />
      <div className={styles.pageBody}>
        <Sessions facilityId={currentFacilityId} showControls showPagination />
      </div>
    </Container>
  );
}

export default AllSessionsPage;
