import {
  useEffect, useMemo, useReducer, useState,
} from 'react';
import { useSelector } from 'react-redux';
import newActivityDetector from 'activity-detector';
import isEmpty from 'lodash/isEmpty';

import {
  checkIsPhoneValid,
  cleanPhone,
  formatPhoneNumberInput,
  useDidUpdateEffect,
  useFormFields,
  useTimer,
} from '@parkly/shared/helpers';

import { useFacilityLicenceModules, useModules } from 'api/query/licenses';
import { RIGHT_KEYS, ROLES } from 'config/constants';

import { useFacilityCustomSettings } from '../api/query/settings';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  if (name === 'code') {
    return value.slice(0, 4);
  }

  return value;
}

export function useLoginState({
  codeLength = 4,
  validatePhoneApi = () => {},
  confirmPhoneApi = () => {},
  onSuccessSendCode = () => {},
  onSuccess = () => {},
  formatValueFunc = formatValue,
}) {
  const [validatedPhone, setValidatedPhone] = useState('');
  const [fields, errors, onChange, setErrors] = useFormFields({
    initValues: { phone: '+7', code: '' },
    formatValueFunc,
  });
  const [timer, setTimer] = useState(0);
  const timerValue = useTimer(timer);
  const [isCodeCheckLoading, setIsCodeCheckLoading] = useState(false);
  const [isSendCodeLoading, setIsSendCodeLoading] = useState(false);

  useDidUpdateEffect(() => {
    async function confirm() {
      const confirmRes = await confirmPhoneApi(validatedPhone, fields.code, true);

      if (!confirmRes?.success) {
        const { error } = confirmRes?.error || {};

        setErrors({
          code: error?.message || 'Не верный пин',
        });

        return {
          success: false,
        };
      }

      return {
        success: confirmRes?.success,
        tel: validatedPhone,
        partners: confirmRes?.partners,
      };
    }

    const code = fields.code || '';
    if (code.length === codeLength) {
      setIsCodeCheckLoading(true);
      confirm()
        .then((result) => {
          setIsCodeCheckLoading(false);
          if (result.success) {
            onSuccess(result);
          }
        })
        .catch(() => setIsCodeCheckLoading(false));
    }
  }, [fields.code]);

  async function sendCode(phone) {
    try {
      setIsSendCodeLoading(true);
      const validateResult = await validatePhoneApi(phone);
      setIsSendCodeLoading(false);

      const { success } = validateResult || {};

      if (success) {
        setTimer((prevTimer) => prevTimer + 1);

        if (validateResult?.pin && process.env.REACT_APP_AUTO_LOGIN === 'true') {
          setTimeout(() => {
            onChange({
              target: {
                name: 'code',
                value: validateResult?.pin,
              },
            });
          }, 500);
        }
      }

      return success;
    } catch (error) {
      setIsSendCodeLoading(false);
      const { errors: validErrors } = error || {};

      setErrors({
        phone: 'Введите корректный номер телефона',
        ...validErrors,
      });
      return false;
    }
  }
  async function onSendCode(event) {
    event.preventDefault();

    const trimmedPhone = cleanPhone(fields.phone);
    const isPhoneValid = checkIsPhoneValid(trimmedPhone);

    if (!isPhoneValid) {
      setErrors({
        phone: 'Введите корректный номер телефона',
      });
      return;
    }

    const success = await sendCode(trimmedPhone);
    if (success) {
      onSuccessSendCode({
        validatedPhone: trimmedPhone,
      });
      setValidatedPhone(trimmedPhone);
    }
  }
  async function onSendAgain(event) {
    event.preventDefault();

    if (!validatedPhone) {
      return false;
    }

    return sendCode(validatedPhone);
  }

  return {
    fields,
    errors,
    timerValue,
    validatedPhone,
    isCodeCheckLoading,
    isSendCodeLoading,
    onChange,
    onSendCode,
    onSendAgain,
  };
}

export function useCheckFacilityRights(right, facilityId) {
  const currentOperatorData = useSelector((state) => state.operators.currentOperator.data);

  return useMemo(() => {
    if (!currentOperatorData || !facilityId) {
      return false;
    }

    const { rights, role } = currentOperatorData;

    if ([ROLES.admin, ROLES.dealer].includes(role)) {
      return true;
    }

    const facilityIdInt = parseInt(facilityId, 10);
    const facilityRights = rights[facilityIdInt];

    return !!((facilityRights || [])[right] || false) || !!((facilityRights || [])[`${right}_all`] || false);
  }, [currentOperatorData, right, facilityId]);
}

export function useCommonRights(right) {
  const currentOperatorData = useSelector((state) => state.operators.currentOperator.data);

  return useMemo(() => {
    if (!currentOperatorData) {
      return false;
    }

    const { rights, role } = currentOperatorData;

    if (right === RIGHT_KEYS.admin) {
      return [ROLES.admin, ROLES.dealer].includes(role);
    }

    if ([ROLES.admin, ROLES.dealer].includes(role)) {
      return true;
    }

    const commonRights = rights.common;

    return !!((commonRights || {})[right] || false);
  }, [currentOperatorData, right]);
}

export function useIsOperatorRole(checkRole) {
  const currentOperatorData = useSelector((state) => state.operators.currentOperator.data);

  return useMemo(() => {
    if (!currentOperatorData) {
      return false;
    }

    const { role } = currentOperatorData;

    return role === checkRole;
  }, [currentOperatorData, checkRole]);
}

export function useIsIdle({ enabled = false, timeout = 1000 * 60 * 5 } = {}) {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    // noinspection JSValidateTypes
    const activityDetector = newActivityDetector({
      timeToIdle: timeout,
      autoInit: enabled,
      inactivityEvents: [],
    });
    activityDetector.on('idle', () => setIsIdle(true));
    activityDetector.on('active', () => setIsIdle(false));

    return () => activityDetector.stop();
  }, []);

  return enabled && isIdle;
}

export function useCurrentOperator() {
  return useSelector((state) => state.operators.currentOperator.data || {});
}

export function useLocalStorageState(key, initValue) {
  const [v, dispatch] = useReducer(
    (prev, cur) => {
      localStorage.setItem(key, JSON.stringify({ value: cur }));
      return { value: cur };
    },
    JSON.parse(localStorage.getItem(key)) || { value: initValue },
  );

  return [v.value, dispatch];
}

export function useHasAnyFacilityModule(key) {
  const currentOperator = useCurrentOperator();

  const { data, isLoading } = useModules({
    variables: { operatorId: currentOperator.id || null },
    staleTime: 1000 * 60 * 60, // 1 hour,
    enabled: !!(currentOperator.id || false),
  });

  return !!(data && !isLoading && data.any[key]);
}

export function useHasAllFacilityModule(key) {
  const currentOperator = useCurrentOperator();
  const { data, isLoading } = useModules({
    variables: { operatorId: currentOperator.id || null },
    staleTime: 1000 * 60 * 60, // 1 hour,
    enabled: !!(currentOperator.id || false),
  });

  return !!(data && !isLoading && data.all[key]);
}

export function useHasFacilityModule(facilityId, key) {
  const currentOperator = useCurrentOperator();
  const { data, isLoading } = useModules({
    variables: { operatorId: currentOperator.id || null },
    staleTime: 1000 * 60 * 60, // 1 hour,
    enabled: !!(currentOperator.id || false),
  });

  return !!(data && !isLoading && data.facilities[facilityId] && data.facilities[facilityId][key]);
}

export function useWarnMapModules() {
  const currentOperator = useCurrentOperator();

  const { data, isLoading } = useModules({
    variables: { operatorId: currentOperator.id || null },
    staleTime: 1000 * 60 * 60, // 1 hour,
    enabled: !!(currentOperator.id || false),
  });

  if (!data) {
    return true;
  }

  return isLoading;
}

export function useGetFacilitySetting(facilityId, key, defaultValue) {
  const { data, isLoading } = useFacilityCustomSettings({
    variables: { facilityId: parseInt(facilityId, 10) },
    staleTime: 1000 * 60 * 60, // 1 hour,
  });

  if (isLoading) {
    return defaultValue;
  }

  return (data || {})[key] ?? defaultValue;
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
