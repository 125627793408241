import { createMutation, createQuery } from 'react-query-kit';

import {
  getBulkGates, getFacilityAreas, getFacilityGates, getFacilityPanelGates, openFacilityGate,
} from 'api/facilities';

export const useFacilityAreas = createQuery({
  primaryKey: 'facilities-areas',
  queryFn: ({ queryKey: [_, params] }) => getFacilityAreas(params),
  retry: false,
});

export const useFacilityGates = createQuery({
  primaryKey: 'facility-gates',
  queryFn: ({ queryKey: [_, params] }) => getFacilityGates(params),
  retry: false,
});

export const useFacilityPanelGates = createQuery({
  primaryKey: 'facility-gates-home',
  queryFn: ({ queryKey: [_, params] }) => getFacilityPanelGates(params),
  retry: false,
});

export const useGatesBulk = createQuery({
  primaryKey: 'gates-bulk',
  queryFn: ({ queryKey: [_, params] }) => getBulkGates(params),
  retry: false,
});

export const useOpenGateMutation = createMutation({
  mutationFn: async ({ id, facilityId }) => openFacilityGate({ id, facilityId }),
});
