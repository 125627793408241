import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%', // Исправление проблемы с IE 11.
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%', // Исправление проблемы с IE 11.
    // Убраны marginTop и marginBottom, управление отступами через Grid spacing
    // Добавляем дополнительное оформление по необходимости
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
  cardStr: {
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardSubStr: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

export default {
  useStyles,
};
