import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import NoRightsPage from '@parkly/shared/components/pages/NoRightsPage';

import { getRegistrationStatusAction } from 'actions/company';
import { getFacilitiesAction } from 'actions/facilities';
import { getCurrentOperatorAction } from 'actions/operators';
import AccountPage from 'components/pages/AccountPage';
import AnalyticsPage from 'components/pages/AnalyticsPage';
import AnalyticsPageV2 from 'components/pages/AnalyticsPageV2';
import BlackListPage from 'components/pages/blackList/BlackListPage';
import CustomersPage from 'components/pages/customers/CustomersPage';
import FastLoginPage from 'components/pages/FastLoginPage';
import GatewayEventsPage from 'components/pages/gatewayEvents/GatewayEventsPage';
import HelpPage from 'components/pages/HelpPage';
import HomePage from 'components/pages/HomePage';
import OperatorsPage from 'components/pages/operators/OperatorsPage';
import PassesPage from 'components/pages/passes/PassesPage';
import PaymentsPage from 'components/pages/payments/PaymentsPage';
import PersonalAccountsPage from 'components/pages/personalAccounts/PersonalAccountsPage';
import SessionsPage from 'components/pages/sessions/SessionsPage';
import SettingsPage from 'components/pages/settings/SettingsPage';
import TicketsPage from 'components/pages/tickets/TicketsPage';
import PageContainer from 'components/templates/containers/PageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import MainNavigation from 'components/templates/MainNavigation';
import { PATH_PAGES } from 'config/constants';
import { useWarnMapModules } from 'helpers/hooks';

function getRedirectPath({
  currentOperator,
  currentFacilityId,
}) {
  return PATH_PAGES.home;
}

function AppContent({
  currentOperator,
  registrationStatusState,
  getCurrentOperatorReq = () => {},
  getRegistrationStatusReq = () => {},

  getFacilitiesReq = () => {},
  allFacilitiesState,
  currentFacilityState,
}) {
  useEffect(() => {
    const {
      loading,
      data: currentOperatorData,
      error: currentOperatorError,
    } = currentOperator || {};

    const isCurrentOperatorLoaded = !!currentOperatorData;

    if (currentOperatorError) {
      throw currentOperatorError;
    }

    if (isCurrentOperatorLoaded || loading) {
      return;
    }

    getCurrentOperatorReq();
  }, [currentOperator, getCurrentOperatorReq]);

  useEffect(
    () => {
      const {
        loading,
        data: registrationStatusData,
      } = registrationStatusState || {};

      const isLoaded = !!registrationStatusData;

      if (isLoaded || loading) {
        return;
      }

      getRegistrationStatusReq();
    },
    [registrationStatusState, getRegistrationStatusReq],
  );

  useEffect(() => {
    const {
      loading,
      data: facilitiesData,
      error: facilitiesError,
    } = allFacilitiesState || {};

    const isFacilitiesDataLoaded = !!facilitiesData;

    if (facilitiesError) {
      throw facilitiesError;
    }

    if (isFacilitiesDataLoaded || loading) {
      return;
    }

    getFacilitiesReq();
  }, [allFacilitiesState, getFacilitiesReq]);

  const currentOperatorData = (currentOperator || {}).data;
  const isCurrentOperatorLoaded = !!currentOperatorData;

  const {
    loading: isRegistrationStatusLoading,
    data: registrationStatusData,
  } = registrationStatusState || {};

  const {
    isRegistrationCompleted,
  } = registrationStatusData || {};

  const {
    loading: isFacilitiesLoading,
  } = allFacilitiesState || {};

  const {
    data: currentFacilityData,
    loading: isCurrentFacilityLoading,
  } = currentFacilityState || {};

  const {
    id: currentFacilityId,
  } = currentFacilityData || {};

  const isModulesLoading = useWarnMapModules();

  if (!isRegistrationCompleted) { // TODO isRegistrationCompleted
    return (
      <PageContainer>
        {(!isCurrentOperatorLoaded || isRegistrationStatusLoading || isFacilitiesLoading || isModulesLoading) && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {isCurrentOperatorLoaded && !isRegistrationStatusLoading && !isFacilitiesLoading && !isModulesLoading && (
          <Switch>
            <PrivateRoute path={PATH_PAGES.settings} component={SettingsPage} />
            <Redirect to={PATH_PAGES.settings} />
          </Switch>
        )}
      </PageContainer>
    );
  }

  if ((!isCurrentOperatorLoaded || isRegistrationStatusLoading || isFacilitiesLoading || isCurrentFacilityLoading || isModulesLoading)) {
    return (
      <PageContainer>
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      navigation={<MainNavigation />}
    >
      {(!isCurrentOperatorLoaded || isRegistrationStatusLoading || isFacilitiesLoading || isCurrentFacilityLoading || isModulesLoading) && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {isCurrentOperatorLoaded && !isRegistrationStatusLoading && !isFacilitiesLoading && !isCurrentFacilityLoading && !isModulesLoading && (
        <Switch>
          <PrivateRoute path={PATH_PAGES.notEnoughRights} component={NoRightsPage} />
          <PrivateRoute path={PATH_PAGES.home} component={HomePage} />
          <PrivateRoute path={PATH_PAGES.settings} component={SettingsPage} />
          <PrivateRoute path={PATH_PAGES.customers} component={CustomersPage} />
          <PrivateRoute path={PATH_PAGES.passes} component={PassesPage} />
          <PrivateRoute path={PATH_PAGES.tickets} component={TicketsPage} />
          <PrivateRoute path={PATH_PAGES.sessions} component={SessionsPage} />
          <PrivateRoute path={PATH_PAGES.payments} component={PaymentsPage} />
          <PrivateRoute path={PATH_PAGES.gatewayEvents} component={GatewayEventsPage} />
          <PrivateRoute path={PATH_PAGES.operators} component={OperatorsPage} />
          <PrivateRoute path={PATH_PAGES.blackList} component={BlackListPage} />
          <PrivateRoute path={PATH_PAGES.analyticsv2} component={AnalyticsPageV2} />
          <PrivateRoute path={PATH_PAGES.analytics} component={AnalyticsPage} />
          <PrivateRoute path={PATH_PAGES.account} component={AccountPage} />
          <PrivateRoute path={PATH_PAGES.personalAccounts} component={PersonalAccountsPage} />
          <PrivateRoute path={PATH_PAGES.help} component={HelpPage} />
          <PrivateRoute path={PATH_PAGES.fastLogin} component={FastLoginPage} />
          <Redirect to={getRedirectPath({ currentOperator, currentFacilityId })} />
        </Switch>
      )}
    </PageContainer>
  );
}

function mapStateToProps(state) {
  const { operators } = state || {};
  const { companies } = state || {};
  const { facilities } = state || {};
  const { currentFacility } = state || {};
  const { currentOperator } = operators || {};
  const { registrationStatus } = companies || {};
  const {
    allFacilities: allFacilitiesState,
  } = facilities || {};

  const {
    currentFacility: currentFacilityState,
  } = currentFacility || {};

  return {
    currentOperator,
    registrationStatusState: registrationStatus,
    allFacilitiesState,
    currentFacilityState,
  };
}

const ConnectedAppContent = connect(
  mapStateToProps,
  {
    getCurrentOperatorReq: getCurrentOperatorAction,
    getRegistrationStatusReq: getRegistrationStatusAction,
    getFacilitiesReq: getFacilitiesAction,
  },
)(AppContent);

export default ConnectedAppContent;
