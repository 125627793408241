import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllPassesPage from 'components/pages/passes/AllPassesPage';
import NewOnePassPage from 'components/pages/passes/NewOnePassPage';
import OnePassInfoPage from 'components/pages/passes/OnePassInfoPage';
import OnePassPage from 'components/pages/passes/OnePassPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES } from 'config/constants';

function PassesPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    timezone: tz,
  } = currentFacilityData || {};

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);

  const urlActions = PATH_PAGES.allPasses.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute
        path={PATH_PAGES.allPasses}
        component={AllPassesPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.addPass}
        component={NewOnePassPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.onePass}
        component={OnePassPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.onePassInfo}
        component={OnePassInfoPage}
      />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedCustomersPage = connect(
  mapStateToProps,
  {
  },
)(PassesPage);

export default ConnectedCustomersPage;
