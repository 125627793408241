import { createMutation, createQuery } from 'react-query-kit';

import {
  createRole,
  getOperatorRightList, getRoles, newApiOperatorToken, oneRole, updateRole,
} from 'api/operators';

export const useGetRightList = createQuery({
  primaryKey: 'rights',
  queryFn: () => getOperatorRightList(),
  retry: false,
});

export const useGetRoles = createQuery({
  primaryKey: 'roles',
  queryFn: () => getRoles(),
  retry: false,
});

export const useOneRole = createQuery({
  primaryKey: 'role',
  queryFn: ({ queryKey: [_, params] }) => oneRole(params),
  retry: false,
});

export const useSetRoleMutation = createMutation({
  mutationFn: async ({ id, ...params }) => {
    if (id) {
      return updateRole({ id, ...params });
    }

    return createRole({ ...params });
  },
});

export const useNewApiTokenMutation = createMutation({
  mutationFn: async ({ operatorId }) => newApiOperatorToken({ operatorId }),
});
