import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { useFormFields } from '@parkly/shared/helpers';

import {
  useAddPassPersonalAccountPaymentMutation,
  usePersonalAccountsForCharge,
} from 'api/query/personalAccounts';

import { useStyles } from './styles';

export default function PassAddPersonalAccountPaymentModalContent({
  onClose = () => {},
  onComplete = () => {},
  passId,
  facilityId,
}) {
  const styles = useStyles();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      personalAccountId: '',
    },
  });

  const { data: personalAccountsData } = usePersonalAccountsForCharge({
    variables: {},
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name, number }) => ({
    id,
    title: name || number,
  })), [personalAccountsData]);

  const mutation = useAddPassPersonalAccountPaymentMutation({
    onSuccess({ success }) {
      if (success) {
        onComplete();
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!fields.personalAccountId) {
      setErrors({
        personalAccountId: true,
      });
      return;
    }

    mutation.mutate({
      id: passId,
      facilityId,
      personalAccountId: fields.personalAccountId,
    });
  };

  return (
    <CustomModalContentWrapper
      title="Добавить оплату с ЛС"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <form noValidate onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              className={styles.selector}
              items={personalAccountItems}
              label="Лицевой счет"
              name="personalAccountId"
              onChange={onChange}
              currentValue={fields.personalAccountId}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomBtn className={styles.saveBtn} onClick={onClose}>Отменить</CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn type="submit" className={styles.saveBtn} btnType="primaryGreen">Добавить</CustomBtn>
          </Grid>
        </Grid>
      </form>
    </CustomModalContentWrapper>
  );
}
