import React from 'react';
import Grid from '@material-ui/core/Grid';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useModal } from '@parkly/shared/helpers';

import { useAccessSchemas, useSetAccessSchemaMutation } from 'api/query/accessSchemas';
import { useControllers } from 'api/query/controllers';
import { useFacilityAreas, useFacilityGates } from 'api/query/gates';
import AccessSchema from 'components/organisms/AccessSchemas';

import AddAccessSchemaModalContent from './AddAccessSchemaModalContent';

function AccessSchemasSetting({ match }) {
  const { facilityId } = (match || {}).params || {};

  const { data: accessSchemasData, isLoading, refetch } = useAccessSchemas({
    variables: { facilityId },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const setAccessSchemaMutation = useSetAccessSchemaMutation({
    onSuccess({ success }) {
      if (success) {
        refetch();
      }
    },
  });

  const { data: zonesData, isLoading: isZonesLoading } = useFacilityAreas({
    variables: { id: facilityId },
  });

  const { data: controllersData, isLoading: isControllersLoading } = useControllers({
    variables: { facilityId },
  });

  const { data: gatesData, isLoading: isGatesLoading } = useFacilityGates({
    variables: { id: facilityId },
  });

  const [addAccessSchemaModalNode, openAddAccessSchemaModal, hideAddAccessSchemaModal] = useModal({
    content: <AddAccessSchemaModalContent
      onClose={() => hideAddAccessSchemaModal()}
      onAdd={(name) => {
        setAccessSchemaMutation.mutate({ facilityId, name, items: [] });

        hideAddAccessSchemaModal();
      }}
    />,
  });

  if (isLoading || isZonesLoading || isControllersLoading || isGatesLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <Grid container spacing={2}>
      {(accessSchemasData || []).map(({ id, ...accessSchema }, i) => (
        <Grid key={id || `${i}-i`} item xs={12}>
          <AccessSchema onReload={refetch} accessSchema={{ id, ...accessSchema }} facilityId={facilityId} zonesData={zonesData} controllersData={controllersData} gatesData={gatesData} />
        </Grid>
      ))}
      <Grid item xs={12} md={6}>
        <CustomBtn btnType="primaryBlue" onClick={() => openAddAccessSchemaModal()}>
          Добавить
        </CustomBtn>
      </Grid>
      {addAccessSchemaModalNode}
    </Grid>
  );
}

export default AccessSchemasSetting;
