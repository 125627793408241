import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

import { useFacilityGates } from 'api/query/gates';
import GatewayEvents from 'components/organisms/GatewayEvents';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function AllGatewayEventsPage({ match }) {
  const styles = useStyles();
  const { t } = useTranslation();

  useCurrentFacilityMatch();

  const { params: matchParams } = match || {};
  const { facilityId: currentFacilityId } = matchParams || {};

  const { data: gatesData } = useFacilityGates({
    variables: { id: currentFacilityId },
    staleTime: 5 * 60 * 1000,
  });

  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, currentFacilityId);
  const hasPaymentsShowRight = useCheckFacilityRights(RIGHT_KEYS.paymentsShow, currentFacilityId);

  const hasPaymentModule = useHasFacilityModule(currentFacilityId, MODULES_KEYS.payments);

  return (
    <Container maxWidth="xl" className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          hasSessionShowRight
            ? { to: PATH_PAGES.sessions.replace(':facilityId', currentFacilityId), label: 'Сессии' }
            : null,
          hasPaymentsShowRight && hasPaymentModule
            ? { to: PATH_PAGES.payments.replace(':facilityId', currentFacilityId), label: 'Оплаты' }
            : null,
          { to: PATH_PAGES.gatewayEvents.replace(':facilityId', currentFacilityId), label: 'Проезды' },
        ]}
      />
      <div className={styles.pageBody}>
        <GatewayEvents facilityId={currentFacilityId} gatesData={gatesData} showControls showPagination />
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedAllGatewayEventsPage = connect(mapStateToProps, {})(AllGatewayEventsPage);

export default ConnectedAllGatewayEventsPage;
