import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllTicketsPage from 'components/pages/tickets/AllTicketsPage';
import NewOneTicketPage from 'components/pages/tickets/NewOneTicketPage';
import OneTicketInfoPage from 'components/pages/tickets/OneTicketInfoPage';
import OneTicketPage from 'components/pages/tickets/OneTicketPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

function getRedirect({
  currentFacilityId,
  hasCustomerShowRight,
  hasPassShowRight,
  hasBackListShowRight,
  hasTicketShowRight,
}) {
  if (hasTicketShowRight) {
    return PATH_PAGES.allTickets.replace(':facilityId', currentFacilityId);
  }

  if (hasPassShowRight) {
    return PATH_PAGES.allPasses.replace(':facilityId', currentFacilityId);
  }

  if (hasCustomerShowRight) {
    return PATH_PAGES.allCustomers.replace(':facilityId', currentFacilityId);
  }

  if (hasBackListShowRight) {
    return PATH_PAGES.allBlackList;
  }

  return PATH_PAGES.notEnoughRights;
}

function TicketsPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    timezone: tz,
  } = currentFacilityData || {};

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);
  const hasBackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute path={PATH_PAGES.allTickets} component={AllTicketsPage} />
      <PrivateRoute exact path={PATH_PAGES.addTicket} component={NewOneTicketPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTicket} component={OneTicketPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTicketInfo} component={OneTicketInfoPage} />
      <Redirect
        to={getRedirect({
          currentFacilityId,
          hasCustomerShowRight,
          hasPassShowRight,
          hasBackListShowRight,
          hasTicketShowRight,
        })}
      />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTicketsPage = connect(
  mapStateToProps,
  {},
)(TicketsPage);

export default ConnectedTicketsPage;
