import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {

  },
  modalBody: {

  },
  container: {

  },
  saveBtn: {
    width: '100%',
    height: 48,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: '#F6F7FB',
    },

    '& input:focus': {

    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerWithError: {
    '& input': {
      backgroundColor: `${theme.palette.redC.red4} !important`,
    },
  },
}));

export default {
  useStyles,
};
