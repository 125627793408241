import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 48,
  },
  paginationContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  sortIcon: {
    marginLeft: 7,
  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 330,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '10px 0',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  statusStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  numberStr: {
    fontSize: 16,

  },
  phoneStr: {
    fontSize: 14,
    fontWeight: 700,
  },
  timeStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  dateStr: {

  },
}));

export default {
  useStyles,
};
