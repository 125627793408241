import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import clone from 'lodash/clone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  checkIsPhoneValid,
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  useFormFields,
} from '@parkly/shared/helpers';

import { setControllerAction } from 'actions/controllers';
import { setController, testNewController } from 'api/controllers';
import { CONTROLLER_TYPES } from 'config/constants';

import { useStyles } from './styles';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeMangoATCControllerModalContent({
  facilityId,
  id,
  onClose = () => {},

  testNewControllerApi = testNewController,
  setControllerApi = setController,

  allControllersState,
}) {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isSetLoading, setIsSetLoading] = useState(false);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      phone: '+7',
      extensions: [],
    },
    formatValueFunc: formatValue,
  });

  const handleSetController = useCallback(() => {
    const trimmedPhone = cleanPhone(fields.phone);
    const isPhoneValid = checkIsPhoneValid(trimmedPhone);

    if (!isPhoneValid) {
      setErrors({
        phone: !isPhoneValid,
      });
      return;
    }

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.mangoATC,
      settings: {
        number: cleanPhone(fields.phone),
        ports: fields.extensions,
      },
    }).then(({ data, success }) => {
      if (!success) {
        setIsLoading(false);
        return;
      }

      setIsSetLoading(true);
      setIsLoading(false);

      setControllerApi({
        facilityId,
        id,
        type: CONTROLLER_TYPES.mangoATC,
        settings: {
          number: cleanPhone(fields.phone),
          ports: fields.extensions,
        },
      }).then(() => {
        setIsSetLoading(false);
        onClose();
      }).catch(() => {
        setIsSetLoading(false);
      });
    }).catch((error) => {
      setIsLoading(false);
    });
  }, [facilityId, fields, id, onClose, setControllerApi, setErrors, testNewControllerApi]);

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        phone: formatPhoneNumber(controller.settings.number || '+7'),
        extensions: controller.settings.ports || [],
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  const createDeleteExtHandler = (i) => () => {
    fields.extensions.splice(i, 1);

    updateState({
      extensions: clone(fields.extensions),
    });
  };

  const onChangeExt = (e) => {
    const { target } = e;

    const { name } = target;
    const { value } = target;

    const [i] = name.split('.');

    fields.extensions[i].ext = parseInt(value, 10) || 0;

    updateState({
      extensions: clone(fields.extensions),
    });
  };

  return (
    <CustomModalContentWrapper
      title="Подключение АТС"
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Укажите номер телефона
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextInput
            className={styles.inputItem}
            error={errors.phone}
            name="phone"
            inputProps={{
              maxLength: MAX_PHONE_NUMBER_LENGTH,
            }}
            label="Номер телефона"
            value={fields.phone}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                Добавочные:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {(fields.extensions || []).map(({ ext }, index) => (
                  <Grid key={index} item xs={4}>
                    <Typography />
                    <CustomTextInput
                      className={styles.inputItem}
                      error={errors.phone}
                      name={`${index}.ext`}
                      label="Доб."
                      inputProps={{
                        maxLength: 3,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={createDeleteExtHandler(index)}
                            >
                              <CloseIcon htmlColor="#7E8AA7" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={ext}
                      onChange={onChangeExt}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomLink
            onClick={(e) => {
              e.preventDefault();

              fields.extensions.push({
                ext: '',
              });

              updateState({
                extensions: clone(fields.extensions),
              });
            }}
          >
            Добавить добавочный
          </CustomLink>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={6}>
              <CustomBtn
                btnType="primaryBlue"
                className={styles.btn}
                disabled={isLoading || isSetLoading}
                onClick={handleSetController}
              >
                {id ? 'Сохранить' : 'Добавить'}
                {(isLoading || isSetLoading) && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController,
    allControllers,
  } = controllers || {};

  return {
    setControllerState: setController,
    allControllersState: allControllers,
  };
}

const ConnectedMakeMangoATCControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeMangoATCControllerModalContent);

export default ConnectedMakeMangoATCControllerModalContent;
