import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, Popover } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import SaveIcon from '@material-ui/icons/Save';
import { useQueryClient } from '@tanstack/react-query';
import { bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  checkIsCarNumberValid,
  checkIsPhoneValid,
  cleanPhone,
  formatPhoneNumber,
  getApiErrorMsg,
  useModal,
} from '@parkly/shared/helpers';

import {
  useAddSessionAccessTokenMutation,
  useSessionAccessTokens,
  useSetSessionAccessTokenMutation,
} from 'api/query/sessions';
import HighlightedTypography from 'components/atoms/HighlightedTypography';
import { useImageViewer } from 'components/atoms/ImageViewer';
import SessionAccessChangeTokenModalContent from 'components/molecules/SessionAccessChangeTokenModalContent';

import { ACCESS_TOKEN_TYPES } from '../../../config/constants';

export default function SessionAccessTokensInaccurate({
  sessionId,
  facilityId,
  hasAddPhone = false,
}) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `inaccuratePopover-${sessionId}`,
  });
  const { isOpen } = popupState || {};

  const [inputToken, setInputToken] = useState('');

  const [phoneInputValue, setPhoneInputValue] = useState('');
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [plateInputValue, setPlateInputValue] = useState('');
  const [plateInputError, setPlateInputError] = useState(false);

  const [imageViewerNode, openImageViewer] = useImageViewer();

  const {
    data, isLoading, isRefetching, error, dataUpdatedAt,
  } = useSessionAccessTokens({
    variables: { id: sessionId, facilityId },
    enabled: popupState.isOpen,
  });

  useEffect(() => {
    setInputToken('');
    setPhoneInputValue('');
    setPlateInputValue('');
    setPhoneInputError(false);
    setPlateInputError(false);
  }, [dataUpdatedAt]);

  const queryClient = useQueryClient();

  const setTokenMutation = useSetSessionAccessTokenMutation({
    onSuccess({ success, customer, type }, { token, id: accessEventId }) {
      if (success) {
        toast.success('Выполнено');
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        popupState.close();
        return;
      }

      // eslint-disable-next-line no-use-before-define
      openConfirmChangeTokenModal({
        type, customer, token, additionalData: { accessEventId },
      });
    },
  });

  const addTokenMutation = useAddSessionAccessTokenMutation({
    onSuccess({ success, customer, type }, { token, id: sId, type: tokenType }) {
      if (success) {
        toast.success('Выполнено');
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        popupState.close();
        return;
      }

      // eslint-disable-next-line no-use-before-define
      openConfirmAddTokenModal({
        type, customer, token, additionalData: { sessionId: sId, tokenType },
      });
    },
  });

  const { isLoading: isMutLoading } = setTokenMutation || {};

  const isDataLoading = isRefetching || isLoading;

  const {
    cameraTokens,
    phoneTokens,
  } = data || {};

  const errorMsg = error && getApiErrorMsg(error);

  const [confirmChangeTokenModalNode, openConfirmChangeTokenModal, hideConfirmChangeTokenModal] = useModal({
    content: (
      <SessionAccessChangeTokenModalContent
        facilityId={facilityId}
        onConfirm={({ token, accessEventId }) => {
          setTokenMutation.mutate({
            id: accessEventId, facilityId, token, force: true,
          });
          hideConfirmChangeTokenModal();
        }}
        onClose={() => {
          hideConfirmChangeTokenModal();
        }}
      />
    ),
    enablePortal: true,
  });

  const [confirmAddTokenModalNode, openConfirmAddTokenModal, hideConfirmAddTokenModal] = useModal({
    content: (
      <SessionAccessChangeTokenModalContent
        facilityId={facilityId}
        onConfirm={({ token, sessionId: id, tokenType }) => {
          addTokenMutation.mutate({
            id, facilityId, token, type: tokenType, force: true,
          });
          hideConfirmAddTokenModal();
        }}
        onClose={() => {
          hideConfirmAddTokenModal();
        }}
      />
    ),
    enablePortal: true,
  });

  const handleSave = (token, accessEventId) => {
    if (!token) {
      return;
    }

    setTokenMutation.mutate({ id: accessEventId, facilityId, token });
  };

  const handleAddToken = (token, type) => {
    if (!token) {
      return;
    }

    if (type === ACCESS_TOKEN_TYPES.plateNumber && !checkIsCarNumberValid(token)) {
      setPlateInputError(true);
      return;
    }

    if (type === ACCESS_TOKEN_TYPES.phone && !checkIsPhoneValid(cleanPhone(token))) {
      setPhoneInputError(true);
      return;
    }

    addTokenMutation.mutate({
      id: sessionId, facilityId, token, type,
    });
  };

  const hasPhoto = (cameraTokens || []).length > 0 && (cameraTokens || []).every(({ photoUrl }) => !!photoUrl);

  return (
    <>
      <IconButton
        size="small"
        onClick={popupState.open}
      >
        <EditTwoToneIcon />
      </IconButton>
      {isOpen && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...bindPopover(popupState)}
        >
          <div style={{ padding: 16, width: hasPhoto ? 400 : 300, maxWidth: '90vw' }}>
            {isDataLoading && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {!isDataLoading && error && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>
                  {errorMsg}
                </Typography>
              </div>
            )}
            {!isDataLoading && !error && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 700 }}>
                    Госномера:
                  </Typography>
                </Grid>
                {(cameraTokens || []).length === 0 && (
                  <Grid container item xs={12} spacing={1}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <CustomTextInput
                          style={{ margin: 0 }}
                          label="Добавить госномер"
                          type="secondary"
                          error={plateInputError}
                          onChange={(e) => {
                            setPlateInputValue(e.target.value);
                            setPlateInputError(false);
                          }}
                          value={plateInputValue}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton size="small" disabled={isMutLoading} onClick={() => handleAddToken(plateInputValue, ACCESS_TOKEN_TYPES.plateNumber)}>
                                  <SaveIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {(cameraTokens || []).map(({
                  accessEventId, tokens, photoThumbnailUrl, photoUrl, value,
                }, index) => (
                  <Grid item xs={12}>
                    <Grid key={accessEventId} container spacing={2}>
                      <Grid item container spacing={1} xs={hasPhoto ? 7 : 12}>
                        <Grid item xs={12}>
                          {(tokens || []).sort((a, b) => (a.selected && b.selected ? 0 : a.selected ? -1 : 1)).map(({ token, value, selected }, index) => (
                            <div key={`${accessEventId}-${index}`} style={{ display: 'flex', gap: 24 }}>
                              <HighlightedTypography text={token} />
                              {!selected && (
                                <CustomLink
                                  onClick={() => {
                                    if (isMutLoading) {
                                      return;
                                    }

                                    handleSave(value, accessEventId);
                                  }}
                                >
                                  Выбрать
                                </CustomLink>
                              )}
                            </div>
                          ))}
                          {(tokens || []).length === 0 && (
                            <Typography>{value}</Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <CustomTextInput
                            style={{ margin: 0 }}
                            label="Изменить госномер"
                            type="secondary"
                            name="plate"
                            onChange={(e) => setInputToken(e.target.value)}
                            value={inputToken}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton size="small" disabled={isMutLoading} onClick={() => handleSave(inputToken, accessEventId)}>
                                    <SaveIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      {photoUrl && (
                        <Grid item xs={5}>
                          <div style={{ width: '100%', cursor: 'pointer' }} onClick={() => openImageViewer(photoUrl)}>
                            <img style={{ width: '100%' }} src={photoThumbnailUrl} alt="" />
                          </div>
                        </Grid>
                      )}

                      {index !== ((cameraTokens || []).length - 1) && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
                {hasAddPhone && (
                  <>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: 700 }}>
                        Номер телефона:
                      </Typography>
                    </Grid>
                    {(phoneTokens || []).length === 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CustomTextInput
                              style={{ margin: 0 }}
                              label="Добавить новый"
                              type="secondary"
                              name="phone"
                              error={phoneInputError}
                              onChange={(e) => {
                                setPhoneInputValue(formatPhoneNumber(e.target.value));
                                setPhoneInputError(false);
                              }}
                              value={phoneInputValue}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton size="small" disabled={isMutLoading} onClick={() => handleAddToken(phoneInputValue, ACCESS_TOKEN_TYPES.phone)}>
                                      <SaveIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {(phoneTokens || []).length > 0 && (
                      <Grid item xs={12} container spacing={2}>
                        {(phoneTokens || []).length > 0 && (phoneTokens || []).map(({ value, accessEventId }) => (
                          <Grid key={accessEventId} item xs={12}>
                            <Typography>
                              {formatPhoneNumber(value)}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            )}
          </div>
        </Popover>

      )}
      {imageViewerNode}
      {confirmChangeTokenModalNode}
      {confirmAddTokenModalNode}
    </>
  );
}
