import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  createEnumDelimitedArrayParam, DelimitedNumericArrayParam, NumberParam, ObjectParam, StringParam, withDefault,
} from 'use-query-params';

import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { usePayments } from 'api/query/payments';
import PersonalAccountSelect from 'components/molecules/PersonalAccountSelect';
import CustomerAsyncSelect from 'components/organisms/CustomerAsyncSelect';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import {
  MODULES_KEYS, PAYMENT_ORDERS_STATUSES, PAYMENT_ORDERS_STATUSES_MAP, RIGHT_KEYS,
} from 'config/constants';
import { useCommonRights, useDebounce, useHasFacilityModule } from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import PaymentsContent from './PaymentsContent';
import { useStyles } from './styles';

const STATUS_ITEMS = [
  { id: PAYMENT_ORDERS_STATUSES.new, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.new] },
  { id: PAYMENT_ORDERS_STATUSES.invoiced, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.invoiced] },
  { id: PAYMENT_ORDERS_STATUSES.paid, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.paid] },
  { id: PAYMENT_ORDERS_STATUSES.done, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.done] },
  { id: PAYMENT_ORDERS_STATUSES.canceled, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.canceled] },
  { id: PAYMENT_ORDERS_STATUSES.failed, title: PAYMENT_ORDERS_STATUSES_MAP[PAYMENT_ORDERS_STATUSES.failed] },
];

const TYPE_ITEMS = [
  { id: 'manual', title: 'Ручной платеж' },
  { id: 'site', title: 'На сайте' },
  { id: 'vendotek', title: 'Терминал' },
  { id: 'account', title: 'Лицевой счет' },
  // { id: 'app', title: 'В приложении' },
];

function getInitParams() {
  return {
    searchValue: '',
    status: [],
    type: [],
    personalAccount: [],
    paidTo: '',
    paidFrom: '',
    sorting: {},
    customerId: null,
    page: 1,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function prepareParam({
  params,
}) {
  const {
    searchValue,
    sorting = {},
    status,
    type,
    personalAccount,
    paidTo,
    paidFrom,
    customerId,
    page,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const search = (searchValue || '').trim();

  const reqParam = {
    search,
    page,
    paidTo,
    paidFrom,
    customerId,
    status,
    type,
    personalAccount,
    sorting,
    ...sortParam,
  };

  return reqParam;
}

function Payments({
  showControls = false,
  showPagination = false,
  facilityId,
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const variables = useDebounce(params, 300);

  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const { data: payments, isLoading } = usePayments({
    variables: { ...variables, facilityId },
    refetchInterval: 1000 * 60, // 1 mins
    refetchIntervalInBackground: true,
  });

  const {
    paymentsData,
    paymentsMeta,
  } = payments || {};

  const {
    status,
    type,
    paidTo,
    paidFrom,
    sorting,
    personalAccount,
  } = params;

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevQuery) => ({
      ...prevQuery,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function handleChanges(event) {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }
  function sortingSettingsChange({ headerNameId }) {
    const nextSortStatus = getNextSortStatus(sorting[headerNameId]);
    if (nextSortStatus === null) {
      handleParamChange('sorting', {});
      return;
    }

    handleParamChange('sorting', {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    });
  }

  const handleDateChange = useCallback(({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  }, [handleParamChange]);

  return (
    <Grid container spacing={2}>
      {showControls && (
        <FiltersContainer>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                  ].join(' ')}
                  name="paidFrom"
                  placeholder="Не выбрано"
                  label="От"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('paidFrom', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={paidFrom || undefined}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                  ].join(' ')}
                  name="paidTo"
                  placeholder="Не выбрано"
                  label="До"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('paidTo', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={paidTo || undefined}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomerAsyncSelect
                  facilityId={facilityId}
                  withoutNewCustomer
                  placeholder="Для поиска начните вводить"
                  inputClassName={styles.customer}
                  onChange={(e, v) => {
                    handleParamChange('customerId', v ? v.id : null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="type"
                  currentValue={type}
                  items={TYPE_ITEMS}
                  unselectText="Способ не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="status"
                  currentValue={status}
                  items={STATUS_ITEMS}
                  unselectText="Статус не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              {hasPersonalAccountModule && hasPersonalAccountChargeRight && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <PersonalAccountSelect
                    name="personalAccount"
                    currentValue={personalAccount}
                    onChange={handleChanges}
                    unselectText="Лицевой счет не выбран"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FiltersContainer>
      )}
      <Grid item xs={12}>
        <PaymentsContent
          facilityId={facilityId}
          isLoading={isLoading}
          paymentsData={paymentsData}
          paymentsMeta={paymentsMeta}
          showPagination={showPagination}
          onPageChange={onPageChange}
          sorting={sorting}
          sortingSettingsChange={sortingSettingsChange}
        />
      </Grid>
    </Grid>
  );
}

export const PaymentsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(Payments);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PAYMENT_ORDERS_STATUSES.new,
  PAYMENT_ORDERS_STATUSES.invoiced,
  PAYMENT_ORDERS_STATUSES.paid,
  PAYMENT_ORDERS_STATUSES.done,
  PAYMENT_ORDERS_STATUSES.canceled,
  PAYMENT_ORDERS_STATUSES.failed,
]);
const TypeArrayEnumParam = createEnumDelimitedArrayParam(['manual', 'site', 'vendotek', 'account']);

const PaymentsWithQueryParams = withQueryParams({
  queryParams: {
    paidTo: withDefault(StringParam, ''),
    paidFrom: withDefault(StringParam, ''),
    customerId: NumberParam,
    status: withDefault(StatusArrayEnumParam, []),
    type: withDefault(TypeArrayEnumParam, []),
    personalAccount: withDefault(DelimitedNumericArrayParam, []),
    page: PageParam,
    sorting: withDefault(ObjectParam, {}),
  },
  prepareParam,
})(Payments);

export default PaymentsWithQueryParams;
