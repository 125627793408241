import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import { useFacilities } from 'api/query/facilities';
import { useGetRightList, useOneRole, useSetRoleMutation } from 'api/query/operators';
import OperatorSettingsTable from 'components/templates/OperatorSettingsTable';
import { PATH_PAGES, ROLES } from 'config/constants';
import { useCurrentOperator } from 'helpers/hooks';

import { useStyles } from './styles';

function getSettings({
  ruleList,
  role,
  allFacilitiesData,
  isNewMode,
}) {
  if (isNewMode) {
    return {
      name: '',
      comment: '',
      rules: ruleList.map((ruleListObject) => {
        const {
          id: ruleId,
          isCommon: isRuleCommon,
          default: checkedDefault = false,
        } = ruleListObject || {};

        if (isRuleCommon) {
          return {
            isRuleCommon,
            ruleId,
            checked: !!checkedDefault,
            ruleData: [],
          };
        }

        return (allFacilitiesData || []).map(() => ({
          isRuleCommon,
          ruleId,
          checked: !!checkedDefault,
          ruleData: [],
        }));
      }),
    };
  }

  const {
    rights = {},
    // id,
    name = '',
    comment = '',
  } = role || {};

  if (isEmpty(ruleList)) {
    return {
      name,
      comment,
      rules: [],
    };
  }

  const rules = ruleList.map((ruleListObject) => {
    const ruleId = ruleListObject.id;
    const isRuleCommon = ruleListObject.isCommon;

    if (isRuleCommon) {
      const commonRights = rights.common || [];
      const checked = !!(commonRights[ruleId] || false);

      const ruleData = commonRights[ruleId] || [];
      return {
        isRuleCommon,
        ruleId,
        checked,
        ruleData,
      };
    }

    const facilitiesRightsSettings = (allFacilitiesData || []).map((facility) => {
      const platformIdStr = `${(facility || {}).id}`;
      const platformRights = rights[platformIdStr] || [];

      const checked = !!(platformRights[ruleId] || false);
      const ruleData = platformRights[ruleId] || [];

      return {
        ruleId,
        platformId: platformIdStr,
        checked,
        ruleData,
      };
    });

    return facilitiesRightsSettings;
  });

  return {
    name,
    comment,
    rules,
  };
}

export default function OneOperatorRole({
  match,
}) {
  const styles = useStyles();
  const [settings, setSettings] = useState({});
  const history = useHistory();

  const { params, path } = match || {};
  const {
    id,
  } = params || {};

  const isNewOperatorMode = !id && path === PATH_PAGES.addNewOperatorRole;

  const { data: roleData, isLoading: isRoleLoading } = useOneRole({
    variables: { id },
    enabled: !isNewOperatorMode,
  });

  const currentOperator = useCurrentOperator();

  const { data: facilitiesData, isLoading: isFacilitiesLoading } = useFacilities({ variables: { } });

  const { data: rightsData, isLoading: isRightsLoading } = useGetRightList({
    variables: { opId: currentOperator.id || null },
    enabled: !!(currentOperator.id || false),
    cacheTime: 0,
  });

  const { rights: userRights, modules } = rightsData || {};

  const setMutation = useSetRoleMutation({
    onSuccess({ id, success }) {
      if (success) {
        if (id) {
          history.replace(PATH_PAGES.oneOperatorRole.replace(':id', id));
        }

        toast.success('Роль успешно сохранена');
      }
    },
  });

  const isLoading = (isRoleLoading && !isNewOperatorMode) || isFacilitiesLoading || isRightsLoading;

  useEffect(() => {
    if (!userRights || !facilitiesData) {
      return;
    }

    setSettings(getSettings({
      ruleList: userRights,
      role: roleData,
      allFacilitiesData: facilitiesData,
      isNewMode: isNewOperatorMode,
    }));
  }, [facilitiesData, isNewOperatorMode, roleData, userRights]);

  function updateSettings(propName, value) {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
  }

  const handleChanges = (event) => {
    const propName = event.target.name;

    const { value, checked } = event.target;

    updateSettings(propName, value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isNewOperatorMode) {
      setMutation.mutate({
        name: settings.name,
        comment: settings.comment,
        rules: settings.rules,
        allFacilitiesData: facilitiesData,
      });

      return;
    }

    setMutation.mutate({
      id,
      name: settings.name,
      comment: settings.comment,
      rules: settings.rules,
      allFacilitiesData: facilitiesData,
    });
  };

  const {
    name,
    comment,
    rules,
  } = settings || {};

  if (isLoading) {
    return (
      <Container
        className={styles.container}
        maxWidth={false}
      >
        <HeaderWithBackBtn
          title={isNewOperatorMode ? 'Добавить роль' : 'Роль'}
        />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </Container>
    );
  }

  return (
    <Container
      className={styles.container}
      maxWidth={false}
    >
      <HeaderWithBackBtn
        title={isNewOperatorMode ? 'Добавить роль' : 'Роль'}
      />
      <form
        className={styles.form}
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTextInput
              className={styles.inputItem}
              required
              name="name"
              label="Название"
              value={name || ''}
              autoComplete="off"
              inputProps={{
                maxLength: 250,
              }}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomTextInput
              className={styles.inputItem}
              name="comment"
              label="Комментарий"
              value={comment || ''}
              autoComplete="off"
              inputProps={{
                maxLength: 250,
              }}
              onChange={handleChanges}
            />
          </Grid>
          {rules && (
            <Grid item xs={12}>
              <OperatorSettingsTable
                isUpdatable
                allFacilitiesState={{ data: facilitiesData || [] }}
                ruleList={userRights || []}
                rules={rules}
                modules={modules || {}}
                updateSettings={updateSettings}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <CustomBtn
                  btnType="primaryGreen"
                  type="submit"
                >
                  {isNewOperatorMode ? 'Добавить' : 'Сохранить'}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
