import { createMutation, createQuery } from 'react-query-kit';

import {
  createAccessSchema,
  deleteAccessSchema,
  getAccessSchemas,
  getShortAccessSchemas,
  setAccessSchemaDefault,
  updateAccessSchema,
} from 'api/accessSchemas';

export const useAccessSchemas = createQuery({
  primaryKey: 'access-schemas',
  queryFn: ({ queryKey: [_, params] }) => getAccessSchemas(params),
  retry: false,
});

export const useShortAccessSchemas = createQuery({
  primaryKey: 'access-schemas-short',
  queryFn: ({ queryKey: [_, params] }) => getShortAccessSchemas(params),
  retry: false,
});

export const useSetAccessSchemaMutation = createMutation({
  mutationFn: async ({ id, ...params }) => {
    if (id) {
      return updateAccessSchema({ id, ...params });
    }

    return createAccessSchema(params);
  },
});

export const useSetDefaultAccessSchemaMutation = createMutation({
  mutationFn: async (params) => setAccessSchemaDefault(params),
});

export const useDeleteDefaultAccessSchemaMutation = createMutation({
  mutationFn: async (params) => deleteAccessSchema(params),
});
