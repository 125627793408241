import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllCustomersPage from 'components/pages/customers/AllCustomersPage';
import CustomerInfoPage from 'components/pages/customers/CustomerInfoPage';
import OneCustomerPage from 'components/pages/customers/OneCustomerPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES } from 'config/constants';

function CustomersPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    timezone: tz,
  } = currentFacilityData || {};

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);

  const urlActions = PATH_PAGES.allCustomers.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute
        path={PATH_PAGES.allCustomers}
        component={AllCustomersPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.oneCustomerInfo}
        component={CustomerInfoPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.addCustomer}
        component={OneCustomerPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.oneCustomer}
        component={OneCustomerPage}
      />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedCustomersPage = connect(
  mapStateToProps,
  {
  },
)(CustomersPage);

export default ConnectedCustomersPage;
