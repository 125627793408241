import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {

  },
  modalBody: {

  },
  container: {

  },
}));

export default {
  useStyles,
};
