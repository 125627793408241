import React from 'react';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone';
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import { formatPhoneNumberInput } from '@parkly/shared/helpers';

import SessionAccessTokensInaccurate from 'components/molecules/SessionAccessTokensInaccurate';
import { ACCESS_TOKEN_TYPES } from 'config/constants';

function hidePhone(str) {
  return str.replace(/^(.{9}).*(.{3})$/, '$1 *** ** $2');
}

function formatToken({ type, token }, maskPhone) {
  switch (type) {
    case ACCESS_TOKEN_TYPES.phone:
      const phone = formatPhoneNumberInput(token);
      return maskPhone ? hidePhone(phone) : phone;
    case ACCESS_TOKEN_TYPES.rfid:
    case ACCESS_TOKEN_TYPES.barcode:
    case ACCESS_TOKEN_TYPES.pinCode:
    case ACCESS_TOKEN_TYPES.plateNumber:
    default:
      return token.replaceAll(' ', ' ');
  }
}

export default function SessionAccessToken({
  tokens,
  isFinish = false,
  hideEdit = false,
  hidePhotos = false,
  hasAddPhone = false,
  isFreeExit = false,
  onImageClick = () => {},
  sessionId,
  facilityId,
  dateAt,
  maskPhone = false,
}) {
  if (isEmpty(tokens)) {
    return <Typography />;
  }

  const iconComponent = isFinish
    ? <ArrowBackIcon htmlColor="red" />
    : <ArrowForwardIcon htmlColor="#43D37D" />;

  const handleClick = (id) => {
    onImageClick(id);
  };

  const dateAtStr = dateAt ? moment(dateAt).format('DD.MM HH:mm') : '';
  const dateAtStrFull = dateAt ? moment(dateAt).format('DD.MM.YYYY HH:mm:ss') : '';

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {(tokens || []).map((token, index) => (
          <div
            key={`${sessionId}-${token.gatewayEventId}-${index}`}
            style={{
              display: 'flex',
              height: 28,
              alignItems: 'center',
            }}
          >
            {iconComponent}
            {dateAt && (
              <Tooltip title={dateAtStrFull}>
                <Typography style={{ marginRight: 16, color: 'grey' }}>
                  {`${dateAtStr}`}
                </Typography>
              </Tooltip>
            )}
            {isFreeExit ? (
              <Tooltip title="Свободный выезд">
                <Typography>
                  {formatToken(token, maskPhone)}
                </Typography>
              </Tooltip>
            ) : (
              <Typography>
                {formatToken(token, maskPhone)}
              </Typography>
            )}
            {[ACCESS_TOKEN_TYPES.rfid, ACCESS_TOKEN_TYPES.pinCode, ACCESS_TOKEN_TYPES.barcode].includes(token.type) && (
              <ContactlessOutlinedIcon htmlColor="grey" />
            )}
            {token.type === ACCESS_TOKEN_TYPES.plateNumber && token.isInaccurate && (
              <Tooltip title="Возможно госномер разпознался с ошибкой">
                <WarningTwoToneIcon htmlColor="orange" />
              </Tooltip>
            )}
            {!hidePhotos && token.photo && (
              <IconButton size="small" onClick={() => handleClick(token.photo.url)}>
                <CameraAltTwoToneIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      {!isFinish && !hideEdit && (
        <SessionAccessTokensInaccurate
          facilityId={facilityId}
          sessionId={sessionId}
          hasAddPhone={hasAddPhone}
        />
      )}
    </div>
  );
}
