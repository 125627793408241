import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

import { callAPI } from '@parkly/shared/api';

import { PASS_REQ_TYPES } from '../config/constants';
import { parseReqType, toReqType } from '../helpers/reqValues';

async function validGetTicketsSettings({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const ticketTypesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const ticketTypesData = curData.map((item) => {
    const {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      holderType,
      isSingle,
      status,
      paymentMethod,
    } = item;

    return {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      holderType,
      isSingle,
      status,
      paymentMethod,
    };
  });

  const result = {
    ticketTypesData,
    ticketTypesMeta,
  };

  return result;
}

async function validAllTicketSettings({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || {};

  const ticketTypesData = curData.map(({
    id, name, facility_id: facilityId, facility_name: facilityName,
  }) => ({
    id,
    name,
    facilityId,
    facilityName,
  }));

  return ticketTypesData;
}

export async function getAllTicketSettings() {
  return callAPI('ticket-types')
    .then((res) => validAllTicketSettings({ res }));
}

export async function getTicketsSettings({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/ticket-types?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTicketsSettings({
      res,
    }));
}

async function validGetTicketSettings({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    description,
    status,
    tariffPlanId,
    accessSchemaId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isBookPlace,
    isFixedPlace,
    isIgnoreSessions,
    isIgnoreSchedule,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    requiredData,
    places,
    maxDaysToStart,
    holderType,
    paymentMethod,
  } = resultData;

  const { need: needPhone, required: requiredPhone } = parseReqType(requiredData, PASS_REQ_TYPES.phone);
  const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.plateNumber);
  const { need: needFio, required: requiredFio } = parseReqType(requiredData, PASS_REQ_TYPES.fio);
  const { need: needCarModel, required: requiredCarModel } = parseReqType(requiredData, PASS_REQ_TYPES.carModel);
  const { need: needStsNumber, required: requiredStsNumber } = parseReqType(requiredData, PASS_REQ_TYPES.stsNumber);
  const { need: needIdNumber, required: requiredIdNumber } = parseReqType(requiredData, PASS_REQ_TYPES.idNumber);
  const { need: needRFID, required: requiredRFID } = parseReqType(requiredData, PASS_REQ_TYPES.rfid);
  const { need: needBarcode, required: requiredBarcode } = parseReqType(requiredData, PASS_REQ_TYPES.barcode);
  const { need: needPinCode, required: requiredPinCode } = parseReqType(requiredData, PASS_REQ_TYPES.pinCode);
  const { need: needAdditionalPhone } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPhone);
  const { need: needAdditionalPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPlateNumber);

  const isAllPlace = (places || []).length === 0;

  return {
    id,
    name,
    description,
    status,
    tariffPlanId,
    accessSchemaId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isFixedPlace,
    isBookPlace,
    isAllPlace,
    isIgnoreSessions,
    isIgnoreSchedule,
    maxDaysToStart,
    places,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    holderType,
    paymentMethod,
    needPhone,
    requiredPhone,
    optionalPhone: !requiredPhone,
    needFio,
    requiredFio,
    optionalFio: !requiredFio,
    needPlateNumber,
    requiredPlateNumber,
    optionalPlateNumber: !requiredPlateNumber,
    needCarModel,
    requiredCarModel,
    optionalCarModel: !requiredCarModel,
    needStsNumber,
    requiredStsNumber,
    optionalStsNumber: !requiredStsNumber,
    needIdNumber,
    requiredIdNumber,
    optionalIdNumber: !requiredIdNumber,
    needRFID,
    requiredRFID,
    optionalRFID: !requiredRFID,
    needBarcode,
    requiredBarcode,
    optionalBarcode: !requiredBarcode,
    needPinCode,
    requiredPinCode,
    optionalPinCode: !requiredPinCode,
    needAdditionalPhone,
    needAdditionalPlateNumber,
  };
}

export async function getTicketSetting({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket-types/${id}`)
    .then((res) => validGetTicketSettings({
      res,
    }));
}

async function validGetActiveTicketTypes({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const passesData = data.map((item) => {
    const {
      id,
      name,
      places,
      startTime,
      finishTime,
      requiredData,
      duration,
      maxDayToStart,
      isBookPlace,
      price,
      tariffPlanName,
      intervalMode,
      intervalValue,
      paymentMethod,
    } = item;

    const { need: needPhone, required: requiredPhone } = parseReqType(requiredData, PASS_REQ_TYPES.phone);
    const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.plateNumber);
    const { need: needFio, required: requiredFio } = parseReqType(requiredData, PASS_REQ_TYPES.fio);
    const { need: needCarModel, required: requiredCarModel } = parseReqType(requiredData, PASS_REQ_TYPES.carModel);
    const { need: needStsNumber, required: requiredStsNumber } = parseReqType(requiredData, PASS_REQ_TYPES.stsNumber);
    const { need: needIdNumber, required: requiredIdNumber } = parseReqType(requiredData, PASS_REQ_TYPES.idNumber);
    const { need: needRFID, required: requiredRFID } = parseReqType(requiredData, PASS_REQ_TYPES.rfid);
    const { need: needBarcode, required: requiredBarcode } = parseReqType(requiredData, PASS_REQ_TYPES.barcode);
    const { need: needPinCode, required: requiredPinCode } = parseReqType(requiredData, PASS_REQ_TYPES.pinCode);
    const { need: needAdditionalPhone } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPhone);
    const { need: needAdditionalPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPlateNumber);

    const {
      phone,
      fio,
      plateNumber,
      carModel,
      stsNumber,
      idNumber,
      rfid,
      pinCode,
      barcode,
      additionalPhone,
      additionalPlateNumber,
    } = requiredData || {};

    return {
      id,
      name,
      places,
      duration,
      maxDayToStart,
      startTime,
      finishTime,
      isBookPlace,
      paymentMethod,
      price,
      tariffPlanName,
      intervalMode,
      intervalValue,
      reqData: {
        phone,
        fio,
        plateNumber,
        carModel,
        stsNumber,
        idNumber,
        rfid,
        pinCode,
        barcode,
        additionalPhone,
        additionalPlateNumber,
      },
      needPhone,
      requiredPhone,
      optionalPhone: !requiredPhone,
      needFio,
      requiredFio,
      optionalFio: !requiredFio,
      needPlateNumber,
      requiredPlateNumber,
      optionalPlateNumber: !requiredPlateNumber,
      needCarModel,
      requiredCarModel,
      optionalCarModel: !requiredCarModel,
      needStsNumber,
      requiredStsNumber,
      optionalStsNumber: !requiredStsNumber,
      needIdNumber,
      requiredIdNumber,
      optionalIdNumber: !requiredIdNumber,
      needRFID,
      requiredRFID,
      optionalRFID: !requiredRFID,
      needBarcode,
      requiredBarcode,
      optionalBarcode: !requiredBarcode,
      needPinCode,
      requiredPinCode,
      optionalPinCode: !requiredPinCode,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    };
  });

  return passesData;
}

export async function getActiveTicketSettings({
  facilityId,
  simple,
}) {
  const query = new URLSearchParams(pickBy({
    simple: simple ? 1 : undefined,
  }, identity));

  const queryParams = query.toString();

  return callAPI(`facilities/${facilityId}/ticket-types/active?${queryParams}`)
    .then((res) => validGetActiveTicketTypes({
      res,
    }));
}

function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setTicketSettings({
  facilityId,
  id,
  name,
  description,
  tariffPlanId,
  accessSchemaId,
  intervalValue,
  intervalMode,
  isShowingAtSite,
  isSingle,
  isIgnoreSessions,
  isIgnoreSchedule,
  isBookPlace,
  isFixedPlace,
  holdValue,
  holdMode,
  placeHoldValue,
  placeHoldMode,
  notifyValue,
  notifyMode,
  maxDaysToStart,
  holderType,
  paymentMethod,
  needPhone,
  requiredPhone,
  needFio,
  requiredFio,
  needPlateNumber,
  requiredPlateNumber,
  needCarModel,
  requiredCarModel,
  needStsNumber,
  requiredStsNumber,
  needIdNumber,
  requiredIdNumber,
  needRFID,
  requiredRFID,
  needBarcode,
  requiredBarcode,
  needPinCode,
  requiredPinCode,
  needAdditionalPhone,
  needAdditionalPlateNumber,
  status,
  places,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket-types`,
    'POST',
    {
      id: id || null,
      name,
      description,
      status,
      tariffPlanId,
      accessSchemaId,
      intervalValue,
      intervalMode,
      isShowingAtSite,
      isSingle,
      isIgnoreSessions,
      isIgnoreSchedule,
      isBookPlace,
      isFixedPlace,
      holdValue: holdValue || 0,
      holdMode,
      placeHoldValue: placeHoldValue || 0,
      placeHoldMode,
      notifyValue: notifyValue || 0,
      notifyMode,
      maxDaysToStart: maxDaysToStart || 0,
      holderType,
      paymentMethod,
      places,
      requiredData: {
        phone: toReqType(needPhone, requiredPhone),
        fio: toReqType(needFio, requiredFio),
        plateNumber: toReqType(needPlateNumber, requiredPlateNumber),
        carModel: toReqType(needCarModel, requiredCarModel),
        stsNumber: toReqType(needStsNumber, requiredStsNumber),
        idNumber: toReqType(needIdNumber, requiredIdNumber),
        rfid: toReqType(needRFID, requiredRFID),
        barcode: toReqType(needBarcode, requiredBarcode),
        pinCode: toReqType(needPinCode, requiredPinCode),
        additionalPhone: toReqType(needAdditionalPhone, false),
        additionalPlateNumber: toReqType(needAdditionalPlateNumber, false),
      },
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetPassSettingPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getTicketSettingPlaces({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket-types/active/${id}/places`)
    .then((res) => validGetPassSettingPlaces({
      res,
    }));
}

async function validGetTickets({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const ticketsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const ticketsData = curData.map((item) => {
    const {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeCanceled,
      comment,
      createdBy,
      personalAccountId,
      personalAccountName,
    } = item;

    return {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeCanceled,
      comment,
      createdBy,
      personalAccountId,
      personalAccountName,
    };
  });

  const result = {
    ticketsData,
    ticketsMeta,
  };

  return result;
}

export async function getTickets({
  facilityId,
  type,
  status,
  passType,
  personalAccount,
  search,
  dateFrom,
  dateTo,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
    type,
    status: status.join(','),
    personalAccount: personalAccount.join(','),
    dateFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    dateTo: dateTo && moment(dateTo).utc().toISOString(),
    passType: passType === 'unselect' ? undefined : passType,
    search,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/ticket?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTickets({
      res,
    }));
}

async function validGetTicket({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customerName,
    customerId,
    status,
    price,
    passTypeId,
    passType,
    placeId,
    areaId,
    startTime,
    finishTime,
    requiredData,
    url,
    canBeCanceled,
    comment,
    personalAccountId,
    personalAccountName,
  } = resultData;

  const {
    requiredData: passTypeRequiredData,
  } = passType || {};

  const { need: needPhone, required: requiredPhone } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.phone);
  const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.plateNumber);
  const { need: needFio, required: requiredFio } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.fio);
  const { need: needCarModel, required: requiredCarModel } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.carModel);
  const { need: needStsNumber, required: requiredStsNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.stsNumber);
  const { need: needIdNumber, required: requiredIdNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.idNumber);
  const { need: needRFID, required: requiredRFID } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.rfid);
  const { need: needBarcode, required: requiredBarcode } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.barcode);
  const { need: needPinCode, required: requiredPinCode } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.pinCode);
  const { need: needAdditionalPhone } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.additionalPhone);
  const { need: needAdditionalPlateNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.additionalPlateNumber);

  return {
    id,
    number,
    customerName,
    customerId,
    price,
    placeId,
    areaId,
    status,
    passTypeId,
    passType: {
      ...(passType || {}),
      needPhone,
      requiredPhone,
      needFio,
      requiredFio,
      needPlateNumber,
      requiredPlateNumber,
      needCarModel,
      requiredCarModel,
      needStsNumber,
      requiredStsNumber,
      needIdNumber,
      requiredIdNumber,
      needRFID,
      requiredRFID,
      needBarcode,
      requiredBarcode,
      needPinCode,
      requiredPinCode,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    },
    startTime,
    finishTime,
    requiredData,
    url,
    canBeCanceled,
    comment,
    personalAccountId,
    personalAccountName,
  };
}

export async function getTicket({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket/${id}`)
    .then((res) => validGetTicket({
      res,
    }));
}

async function validGetTicketInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customer,
    status,
    price,
    passType,
    place,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeCanceled,
    comment,
    createdAt,
    createdBy,
    personalAccountId,
    personalAccountName,
  } = resultData;

  return {
    id,
    number,
    customer,
    price,
    place,
    status,
    ticketType: passType,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeCanceled,
    comment,
    createdAt,
    createdBy,
    personalAccountId,
    personalAccountName,
  };
}

export async function getTicketInfo({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket/${id}/info`)
    .then((res) => validGetTicketInfo({
      res,
    }));
}

export async function createTicket({
  facilityId,
  customerId,
  status,
  ticketTypeId,
  placeId,
  comment,
  personalAccountId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket`,
    'POST',
    {
      status,
      comment,
      customerId: customerId || null,
      passTypeId: ticketTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
      personalAccountId,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function updateTicket({
  facilityId,
  id,
  customerId,
  status,
  ticketTypeId,
  placeId,
  comment,
  personalAccountId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket`,
    'PUT',
    {
      id,
      status,
      comment,
      customerId,
      passTypeId: ticketTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
      personalAccountId,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function activateTicket({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket/${id}/activate`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function cancelTicket({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket/${id}/cancel`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}
