import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { toNativeTimeFormat, useFormFields } from '@parkly/shared/helpers';

import { useCreatePersonalAccountTransactionMutation } from 'api/query/personalAccounts';
import { PERSONAL_ACCOUNT_TRANSACTIONS_TYPES } from 'config/constants';

import { useStyles } from './styles';

export default function CreatePersonalAccountTransactionModalContent({
  onClose = () => {},
  onSuccess = () => {},
  accountId,
  type,
}) {
  const styles = useStyles();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      amount: 0,
      description: '',
      date: moment(),
    },
  });

  const mutation = useCreatePersonalAccountTransactionMutation({
    onSuccess({ success }) {
      if (success) {
        onSuccess();
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!type) {
      return;
    }

    if (Number(fields.amount) <= 0) {
      setErrors({
        amount: true,
      });
      return;
    }

    mutation.mutate({
      id: accountId,
      type,
      amount: Number(fields.amount),
      description: fields.description || undefined,
      date: fields.date,
    });
  };

  return (
    <CustomModalContentWrapper
      title={type === PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp ? 'Создать начисление' : 'Создать списание'}
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <form noValidate onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextInput
              required
              style={{ margin: 0 }}
              name="amount"
              label="Сумма"
              value={fields.amount}
              error={errors.amount}
              inputProps={{
                type: 'number',
                min: 0,
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimeInput
              className={[
                styles.datePicker,
              // (errors || {}).startDate ? styles.datePickerWithError : '',
              ].join(' ')}
              name="date"
              placeholder="Не выбрано"
              label="Дата"
              initStartDayTime
              rightIcon={(<CalendarIcon />)}
              onChange={({ value }) => updateState({ date: toNativeTimeFormat(value) })}
              value={fields.date || undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              style={{ margin: 0 }}
              name="description"
              label="Комментарий"
              value={fields.description}
              error={errors.description}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomBtn className={styles.saveBtn} onClick={onClose}>Отменить</CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn type="submit" className={styles.saveBtn} btnType="primaryGreen">Создать</CustomBtn>
          </Grid>
        </Grid>
      </form>
    </CustomModalContentWrapper>
  );
}
