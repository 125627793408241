import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardActionArea, CardContent, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useStyles } from './styles';

const THIRTY_DAYS_MS = 30 * 24 * 60 * 60 * 1000; // 30 дней в миллисекундах

export default function SelectLoginCards({ handleLoginClick, partnersData }) {
  const classes = useStyles();
  const [visitLogs, setVisitLogs] = useState({});

  // Загрузка и очистка данных о посещениях из localStorage при монтировании компонента
  useEffect(() => {
    try {
      const storedLogs = localStorage.getItem('visitLogs');
      if (storedLogs) {
        const parsedLogs = JSON.parse(storedLogs);
        const now = Date.now();
        const cleanedLogs = {};

        Object.keys(parsedLogs).forEach((userId) => {
          const lastVisit = parsedLogs[userId];
          if (now - lastVisit <= THIRTY_DAYS_MS) {
            cleanedLogs[userId] = lastVisit;
          }
        });

        setVisitLogs(cleanedLogs);
        localStorage.setItem('visitLogs', JSON.stringify(cleanedLogs));
      }
    } catch (error) {
      console.error('Ошибка при загрузке visitLogs из localStorage:', error);
    }
  }, []);

  // Функция для обработки кликов на карточки
  const handleCardClickInternal = (partner) => {
    handleLoginClick(partner);

    const { userId } = partner;
    const now = Date.now();
    const updatedLogs = { ...visitLogs };

    updatedLogs[userId] = now;

    setVisitLogs(updatedLogs);

    try {
      localStorage.setItem('visitLogs', JSON.stringify(updatedLogs));
    } catch (error) {
      console.error('Ошибка при сохранении visitLogs в localStorage:', error);
    }
  };

  // Разделение партнёров на посещённые и не посещённые
  const { visitedPartners, nonVisitedPartners } = useMemo(() => {
    if (!partnersData) return { visitedPartners: [], nonVisitedPartners: [] };

    const now = Date.now();

    const visited = [];
    const nonVisited = [];

    partnersData.forEach((partner) => {
      const { userId } = partner;
      const lastVisit = visitLogs[userId];
      if (lastVisit && now - lastVisit <= THIRTY_DAYS_MS) {
        visited.push({
          ...partner,
          lastVisit,
        });
      } else {
        nonVisited.push(partner);
      }
    });

    // Сортировка посещённых партнёров по дате последнего посещения (от новых к старым)
    visited.sort((a, b) => b.lastVisit - a.lastVisit);

    return {
      visitedPartners: visited,
      nonVisitedPartners: nonVisited,
    };
  }, [partnersData, visitLogs]);

  return (
    <Grid container className={classes.cardContainer} spacing={3}>
      {/* Секция Посещённых карточек */}
      {visitedPartners.length > 0 && (
        <>
          {visitedPartners.map((partner) => {
            const { name, userId, facilityName } = partner;

            return (
              <Grid key={userId} item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.card}>
                  <CardActionArea onClick={() => handleCardClickInternal(partner)}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography className={classes.cardStr}>
                            {name}
                          </Typography>
                          <Typography className={classes.cardSubStr}>
                            {facilityName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                          <ArrowForwardIcon htmlColor="#004BF6" />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}

          {/* Разделительная линия, показываем только если есть nonVisitedPartners */}
          {nonVisitedPartners.length > 0 && (
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          )}
        </>
      )}

      {/* Секция Не посещённых карточек */}
      {nonVisitedPartners.length > 0 &&
        nonVisitedPartners.map((partner) => {
          const { name, userId, facilityName } = partner;

          return (
            <Grid key={userId} item xs={12} sm={6} md={4} lg={3}>
              <Card className={classes.card}>
                <CardActionArea onClick={() => handleCardClickInternal(partner)}>
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={10}>
                        <Typography className={classes.cardStr}>
                          {name}
                        </Typography>
                        <Typography className={classes.cardSubStr}>
                          {facilityName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent="flex-end">
                        <ArrowForwardIcon htmlColor="#004BF6" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
}
