import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { useFacilityAreas } from 'api/query/gates';

import { useStyles } from './styles';

function AddZoneModalContent({
  facilityId,
  onClose,
  onAdd,
}) {
  const styles = useStyles();
  const [zoneId, setZoneId] = useState('');

  const { data, isLoading } = useFacilityAreas({
    variables: { id: facilityId },
  });

  const items = useMemo(() => {
    const result = [];

    result.push({
      id: '0',
      title: 'Вне парковки',
      isDraft: false,
    });

    result.push({
      id: '0-draft',
      title: 'Вне парковки (черновик)',
      isDraft: true,
    });

    (data || []).forEach(({ id, name }) => {
      result.push({
        id: `${id}`,
        title: name,
        isDraft: false,
      });

      result.push({
        id: `${id}-draft`,
        title: `${name} (черновик)`,
        isDraft: true,
      });
    });

    return result;
  }, [data]);

  const handleOnSave = useCallback(() => {
    if (zoneId === '') {
      return;
    }

    const zone = items.find(({ id }) => id === zoneId);

    onAdd({ id: parseInt(zone.id.replace('-draft', ''), 10), is_from_draft_zone: zone.isDraft });
  }, [items, onAdd, zoneId]);

  return (
    <CustomModalContentWrapper
      title="Добавить зону"
      onClose={onClose}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={items}
            label="Зона"
            name="zoneId"
            onChange={(e) => setZoneId(e.target.value)}
            currentValue={zoneId}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomBtn disabled={zoneId === ''} onClick={handleOnSave} className={styles.saveBtn} btnType="primaryBlue">
            Добавить
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export default AddZoneModalContent;
