import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { BooleanParam, useQueryParam } from 'use-query-params';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  cleanPhone, formatPhoneNumber, formatPhoneNumberInput, useModal,
} from '@parkly/shared/helpers';

import {
  addNewApiOperatorAction,
  addNewOperatorAction,
  getListRightsAction,
  getOneOperatorAction,
  removeOperatorAction,
  updateApiOperatorAction,
  updateOperatorAction,
} from 'actions/operators';
import { useGetRoles } from 'api/query/operators';
import { useAllPersonalAccounts } from 'api/query/personalAccounts';
import OperatorNewApiTokenModalContent from 'components/organisms/OperatorNewApiTokenModalContent';
import OperatorSettingsTable from 'components/templates/OperatorSettingsTable';
import { MODULES_KEYS, PATH_PAGES, ROLES } from 'config/constants';
import { useCurrentOperator, useHasAnyFacilityModule, useIsOperatorRole } from 'helpers/hooks';

import { useStyles } from './styles';

/* help functions */

function getListFromState(l) {
  const { list } = l || {};

  return list || [];
}

function getOneOperator({
  oneOperatorState,
  match,
}) {
  const operatorId = ((match || {}).params || {}).id;

  const { list } = oneOperatorState || {};
  const currentOperator = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + operatorId);

    return isNeeded;
  });

  return {
    operatorId,
    currentOperator,
  };
}

function getSettings({
  rightListState,
  oneOperatorState,
  allFacilitiesState,
  match,
}) {
  const ruleList = getListFromState(rightListState);
  const { data: allFacilitiesData } = allFacilitiesState || {};

  const {
    currentOperator,
  } = getOneOperator({
    oneOperatorState,
    match,
  });

  const { params, path } = match || {};
  const {
    id,
  } = params || {};

  const isNewOperatorMode = !id && path === PATH_PAGES.addNewOperator;

  if (isNewOperatorMode) {
    return {
      name: '',
      jobTitle: '',
      phone: '+7',
      comment: '',
      status: 1,
      role: ROLES.admin,
      adminStatus: true,
      defaultPersonalAccountId: 'unselect',
      roles: {},
      rules: ruleList.map((ruleListObject) => {
        const {
          id: ruleId,
          isCommon: isRuleCommon,
          default: checkedDefault = false,
        } = ruleListObject || {};

        if (isRuleCommon) {
          return {
            isRuleCommon,
            ruleId,
            checked: !!checkedDefault,
            ruleData: [],
          };
        }

        return (allFacilitiesData || []).map(() => ({
          isRuleCommon,
          ruleId,
          checked: !!checkedDefault,
          ruleData: [],
        }));
      }),
    };
  }

  const {
    rights = {},
    // id,
    isAdmin,
    role,
    roles,
    name = '',
    status = 1,
    phone = '+7',
    jobTitle = '',
    comment = '',
    isApiUser,
    defaultPersonalAccountId,
  } = currentOperator || {};

  if (isEmpty(ruleList)) {
    return {
      name,
      jobTitle,
      phone: formatPhoneNumber(phone),
      comment,
      status,
      role,
      roles,
      adminStatus: isAdmin,
      rules: [],
      isApiUser,
      defaultPersonalAccountId: defaultPersonalAccountId || 'unselect',
    };
  }

  const rules = ruleList.map((ruleListObject) => {
    const ruleId = ruleListObject.id;
    const isRuleCommon = ruleListObject.isCommon;

    if (isRuleCommon) {
      const commonRights = rights.common || [];
      const checked = !!(commonRights[ruleId] || false);

      const ruleData = commonRights[ruleId] || [];
      return {
        isRuleCommon,
        ruleId,
        checked,
        ruleData,
      };
    }

    const facilitiesRightsSettings = (allFacilitiesData || []).map((facility) => {
      const platformIdStr = `${(facility || {}).id}`;
      const platformRights = rights[platformIdStr] || [];

      const checked = !!(platformRights[ruleId] || false);
      const ruleData = platformRights[ruleId] || [];

      return {
        ruleId,
        platformId: platformIdStr,
        checked,
        ruleData,
      };
    });

    return facilitiesRightsSettings;
  });

  return {
    name,
    jobTitle,
    phone: formatPhoneNumber(phone),
    comment,
    status,
    role,
    adminStatus: isAdmin,
    rules,
    roles,
    isApiUser,
    defaultPersonalAccountId: defaultPersonalAccountId || 'unselect',
  };
}

function getErrors({
  addNewOperatorState,
  updateOperatorState,
}) {
  return {
    nameAndRole: false,
    login: false,
    password: false,
    comment: false,
    status: false,
    adminStatus: false,
  };
}

const propTypes = {
  addNewOperatorState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  updateOperatorState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  rightListState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  currentOperatorState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  oneOperatorState: PropTypes.shape({
    loading: PropTypes.bool,
    list: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
  getOneOperatorsReq: PropTypes.func,
  addNewOperatorReq: PropTypes.func,
  updOperatorReq: PropTypes.func,
  removeOperatorReq: PropTypes.func,
};

function OneOperator({
  match,
  addNewOperatorState,
  oneOperatorState,
  updateOperatorState,
  rightListState,
  roleListState,
  modulesListState,
  currentOperatorState,
  allFacilitiesState,
  getOneOperatorsReq = () => {},
  addNewOperatorReq = () => {},
  addNewApiOperatorReq = () => {},
  updOperatorReq = () => {},
  updateApiOperatorReq = () => {},
  removeOperatorReq = () => {},
  getListRightsReq = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [settings, setSettings] = useState(getSettings({
    oneOperatorState,
    allFacilitiesState,
    match,
    rightListState,
  }));
  const [errors, setErrors] = useState(getErrors({
    addNewOperatorState,
    updateOperatorState,
  }));
  const [isAdminSettings, setIsAdminSettings] = useState(false);
  const [isRemoveDialog, setIsRemoveDialog] = useState(false);

  const [isCreateApiUserParam] = useQueryParam('api', BooleanParam);

  const currentOperator = useCurrentOperator();
  const {
    partnerId,
  } = currentOperator || {};

  const { params, path } = match || {};
  const {
    id,
  } = params || {};

  const isNewOperatorMode = !id && path === PATH_PAGES.addNewOperator;

  const isNewApiOperatorMode = isNewOperatorMode && isCreateApiUserParam;

  const hasPersonalAccountModule = useHasAnyFacilityModule(MODULES_KEYS.personalAccounts);

  const { data: personalAccountsData, isLoading: isPersonalAccountsLoading } = useAllPersonalAccounts({
    variables: {},
    enabled: hasPersonalAccountModule,
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id: aId, name, number }) => ({
    id: aId,
    title: name || number,
  })), [personalAccountsData]);

  const { data: rolesData, isLoading: isRolesLoading } = useGetRoles({
    variables: {},
  });

  useEffect(
    () => {
      if (isEmpty(getListFromState(rightListState))) {
        return;
      }

      setSettings(getSettings({
        oneOperatorState,
        allFacilitiesState,
        rightListState,
        match,
      }));
    },
    // eslint-disable-next-line
    [rightListState, oneOperatorState, allFacilitiesState, ((match || {}).params || {}).id],
  );
  useEffect(
    () => {
      const {
        operatorId,
        currentOperator: thisOperator,
      } = getOneOperator({
        oneOperatorState,
        match,
      });
      const isNewOperatorMode = operatorId === 'new';

      const { loading: isOneOperatorLoading } = oneOperatorState || {};
      if (!isOneOperatorLoading && !thisOperator && operatorId && !isNewOperatorMode) {
        getOneOperatorsReq({
          id: operatorId,
        });
      }
    },
    // eslint-disable-next-line
    [oneOperatorState, ((match || {}).params || {}).id],
  );
  useEffect(
    () => {
      const addErrors = (addNewOperatorState || {}).error;
      const updateErrors = (updateOperatorState || {}).error;
      const error = updateErrors || addErrors;

      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors((prevErrors) => ({
          ...prevErrors,
          ...backErrors,
        }));
      }
    },
    // eslint-disable-next-line
    [addNewOperatorState, updateOperatorState],
  );
  useEffect(
    () => {
      const {
        loading,
        list = [],
      } = rightListState || {};

      if (loading || list.length > 0) {
        return;
      }

      getListRightsReq();
    },
    // eslint-disable-next-line
    [rightListState],
  );

  const [newTokenModal, openNewTokenModal, hideNewTokenModal] = useModal({
    content: <OperatorNewApiTokenModalContent
      onClose={() => {
        hideNewTokenModal();
      }}
    />,
  });

  const isAdmin = useIsOperatorRole(ROLES.admin);
  const isDealer = useIsOperatorRole(ROLES.dealer);
  const hasOperatorUpdateRight = isAdmin || isDealer;

  const {
    operatorId,
  } = getOneOperator({
    oneOperatorState,
    match,
  });

  const statusList = [
    {
      id: 1,
      title: t('operators.active'),
    },
    {
      id: 2,
      title: t('operators.notActive'),
    },
  ];

  const isAddNewLoading = (addNewOperatorState || {}).loading;

  const isLoading = isAddNewLoading
    || (oneOperatorState || {}).loading
    || (addNewOperatorState || {}).loading
    || (updateOperatorState || {}).loading
    || (rightListState || {}).loading
    || (roleListState || {}).loading
    || (modulesListState || {}).loading
    || isRolesLoading
    || (isPersonalAccountsLoading && hasPersonalAccountModule);

  const {
    name,
    jobTitle,
    phone,
    comment,
    status,
    rules,
    role,
    isApiUser,
    defaultPersonalAccountId,
    roles,
  } = settings;

  const modules = getListFromState(modulesListState);

  const currentOperatorId = ((currentOperatorState || {}).data || {}).id;
  const isRightToDelete = (`${operatorId}`) !== (`${currentOperatorId}`)
    && (currentOperatorId || currentOperatorId === 0);

  const isSaveBtnDisabled = !phone
    || !name
    || isAddNewLoading;

  function updateSettings(propName, value) {
    if (!hasOperatorUpdateRight) {
      return;
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [propName]: false,
    }));
  }
  const handleChanges = (event) => {
    if (!hasOperatorUpdateRight) {
      return;
    }

    const propName = event.target.name;

    const { value, checked } = event.target;
    let newValue = event.target.type !== 'checkbox' ? value : checked;

    if (propName === 'phone') {
      newValue = formatPhoneNumberInput(value);
    }

    updateSettings(propName, newValue);
  };
  const handleRoleChange = (event, newValue) => {
    updateSettings('role', newValue || ROLES.admin);
  };
  const onSettingsBtnClick = () => {
    setIsAdminSettings((prev) => !prev);
  };
  const openRemoveDialog = () => {
    setIsRemoveDialog(true);
  };
  const handleCloseRemoveDialog = () => {
    setIsRemoveDialog(false);
  };
  const handleAgreeRemoveDialog = () => {
    removeOperatorReq({
      operatorId,
      history,
    });
    setIsRemoveDialog(false);
  };

  const onAddOrSaveBtnClick = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const { data: allFacilitiesData } = allFacilitiesState || {};

    if (isNewApiOperatorMode) {
      addNewApiOperatorReq({
        ...settings,
        allFacilitiesData,
        history,
      });

      return;
    }

    if (isApiUser) {
      updateApiOperatorReq({
        ...settings,
        operatorId,
        allFacilitiesData,
      });

      return;
    }

    if (isNewOperatorMode) {
      addNewOperatorReq({
        ...settings,
        phone: cleanPhone(settings.phone),
        allFacilitiesData,
        history,
      });

      return;
    }

    updOperatorReq({
      ...settings,
      phone: cleanPhone(settings.phone),
      operatorId,
      allFacilitiesData,
    });
  };

  return (
    <Container
      className={classes.container}
      maxWidth={false}
    >
      <HeaderWithBackBtn
        title={
          isNewOperatorMode
            ? isNewApiOperatorMode ? 'Добавить API оператора' : t('operators.addOperator')
            : isApiUser ? 'API оператор' : t('operators.operator')
        }
      />

      {!isLoading && (
        <>
          <form
            className={classes.form}
            onSubmit={onAddOrSaveBtnClick}
            autoComplete="off"
          >
            <Grid className={classes.pageBody} container spacing={2}>
              <Grid container spacing={2} item className={classes.bodyMain} xs={12} sm={8}>
                <Grid item xs={6}>
                  <CustomTextInput
                    className={classes.inputItem}
                    required
                    error={errors.name}
                    name="name"
                    label={t('operators.name')}
                    value={name || ''}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 250,
                    }}
                    disabled={!hasOperatorUpdateRight}
                    onChange={handleChanges}
                  />
                </Grid>

                {(!isNewApiOperatorMode && !isApiUser) && (
                  <Grid item xs={6}>
                    <CustomTextInput
                      className={classes.inputItem}
                      error={errors.jobTitle}
                      name="jobTitle"
                      label={t('operators.jobTitle')}
                      value={jobTitle || ''}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 250,
                      }}
                      disabled={!hasOperatorUpdateRight}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}
                {(!isNewApiOperatorMode && !isApiUser) && (
                  <Grid item xs={6}>
                    <CustomTextInput
                      className={classes.inputItem}
                      required
                      error={errors.phone}
                      name="phone"
                      type="text"
                      label={t('operators.phone')}
                      value={phone || ''}
                      inputProps={{
                        maxLength: MAX_PHONE_NUMBER_LENGTH,
                      }}
                      autoComplete="phone"
                      disabled={!hasOperatorUpdateRight}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <ItemSelectors
                    classNameForm={classes.selectorsForm}
                    classNameLabel={classes.selectorsLabel}
                    className={classes.selector}
                    label={t('operators.status')}
                    error={!!errors.status}
                    name="status"
                    currentValue={status}
                    items={statusList}
                    disabled={!hasOperatorUpdateRight}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    className={classes.inputItem}
                    error={errors.comment}
                    name="comment"
                    label={t('operators.comment')}
                    value={comment || ''}
                    autoComplete="off"
                    multiline
                    disabled={!hasOperatorUpdateRight}
                    onChange={handleChanges}
                  />
                </Grid>
                {hasPersonalAccountModule && (
                  <Grid item xs={6}>
                    <ItemSelectors
                      classNameForm={classes.selectorsForm}
                      classNameLabel={classes.selectorsLabel}
                      className={classes.selector}
                      label="Основной лицевой счет"
                      error={!!errors.defaultPersonalAccountId}
                      name="defaultPersonalAccountId"
                      currentValue={defaultPersonalAccountId}
                      items={personalAccountItems}
                      disabled={!hasOperatorUpdateRight}
                      onChange={handleChanges}
                      defaultItem="Не выбрано"
                    />
                  </Grid>
                )}
                <Grid item xs={12} container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography>Роль</Typography>
                  </Grid>
                  <Grid item>
                    <ToggleButtonGroup
                      size="small"
                      className={classes.rolesContainer}
                      value={role}
                      exclusive
                      onChange={handleRoleChange}
                    >
                      <ToggleButton value={ROLES.admin} disabled={role === ROLES.dealer}>
                        <Typography>
                          Администратор
                        </Typography>
                      </ToggleButton>
                      {role === ROLES.dealer && (
                        <ToggleButton value={ROLES.dealer} disabled>
                          <Typography>
                            Дилер
                          </Typography>
                        </ToggleButton>
                      )}
                      <ToggleButton value={ROLES.custom} disabled={role === ROLES.dealer}>
                        <Typography>
                          Настроить
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                {role !== ROLES.admin && (
                  <Divider className={classes.divider} />
                )}
              </Grid>
            </Grid>

            <Collapse
              in={role && ![ROLES.admin, ROLES.dealer].includes(role)}
              timeout="auto"
              unmountOnExit
            >
              <OperatorSettingsTable
                withRoles
                isUpdatable={hasOperatorUpdateRight && ![ROLES.dealer, ROLES.admin].includes(role)}
                allFacilitiesState={allFacilitiesState}
                ruleList={getListFromState(rightListState)}
                rules={rules}
                modules={modules}
                updateSettings={updateSettings}
                roleList={rolesData || []}
                roles={roles || {}}
              />
            </Collapse>

            {hasOperatorUpdateRight && (
              <Grid container className={classes.makeAdminContainer} spacing={2}>
                <Grid item>
                  <CustomBtn
                    type="submit"
                    className={classes.btnAddOrSave}
                    disabled={isSaveBtnDisabled}
                  >
                    {t('operators.save')}
                  </CustomBtn>
                </Grid>
                {isApiUser && (
                  <Grid item>
                    <CustomBtn onClick={() => openNewTokenModal({ operatorId })}>
                      Получить токен
                    </CustomBtn>
                  </Grid>
                )}
                {!isNewOperatorMode && isRightToDelete && (
                  <Grid item>
                    <CustomBtn
                      className={classes.btnRemove}
                      onClick={openRemoveDialog}
                    >
                      {t('operators.removeOperator')}
                    </CustomBtn>
                  </Grid>
                )}
              </Grid>
            )}
          </form>
          <CustomDialog
            open={isRemoveDialog}
            handleAgree={handleAgreeRemoveDialog}
            handleClose={handleCloseRemoveDialog}
            dialogContent={t('operators.operatorRemoveQuestion')}
            agreeTitle={t('operators.remove')}
            disagreeTitle={t('others.cancel')}
          />
        </>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {newTokenModal}
    </Container>
  );
}

OneOperator.propTypes = propTypes;

function mapStateToProps(state) {
  const { operators } = state || {};
  const { facilities } = state || {};
  const {
    addNewOperator: addNewOperatorState,
    oneOperator: oneOperatorState,
    updateOperator: updateOperatorState,
    rightList: rightListState,
    roleList: roleListState,
    modulesList: modulesListState,
    currentOperator: currentOperatorState,
  } = operators || {};

  const {
    allFacilities: allFacilitiesState,
  } = facilities || {};

  return {
    addNewOperatorState,
    oneOperatorState,
    updateOperatorState,
    rightListState,
    roleListState,
    modulesListState,
    currentOperatorState,
    allFacilitiesState,
  };
}

const ConnectedOneOperator = connect(
  mapStateToProps,
  {
    getOneOperatorsReq: getOneOperatorAction,
    addNewOperatorReq: addNewOperatorAction,
    addNewApiOperatorReq: addNewApiOperatorAction,
    updOperatorReq: updateOperatorAction,
    updateApiOperatorReq: updateApiOperatorAction,
    removeOperatorReq: removeOperatorAction,
    getListRightsReq: getListRightsAction,
  },
)(OneOperator);

export default ConnectedOneOperator;
