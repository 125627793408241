import React from 'react';
import Container from '@material-ui/core/Container';

import HeaderWithBackBtn from 'components/molecules/HeaderWithBackBtn';
import PersonalAccounts from 'components/organisms/PersonalAccounts';

import { useStyles } from './styles';

export default function AllPersonalAccountsPage() {
  const styles = useStyles();

  return (
    <Container maxWidth="xl" className={styles.container}>
      <HeaderWithBackBtn
        title="Лицевые счета"
        isBackBtn={false}
      />
      <div className={styles.pageBody}>
        <PersonalAccounts showPagination showControls />
      </div>
    </Container>
  );
}
