// import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import StopIcon from '@material-ui/icons/Stop';
import moment from 'moment-timezone';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import { getHumanizeDurationShort, plural } from '@parkly/shared/helpers';
import useConfirm from '@parkly/shared/helpers/confirm';

import { useImageViewer } from 'components/atoms/ImageViewer';
import SessionAccessToken from 'components/molecules/SessionAccessToken';
import {
  MODULES_KEYS, PATH_PAGES, RIGHT_KEYS, SESSION_STATUSES,
} from 'config/constants';
import { useCheckFacilityRights, useHasFacilityModule } from 'helpers/hooks';

import { useStyles } from './styles';

/* help function */

const getStatusContentByStatus = (status) => {
  switch (status) {
    case SESSION_STATUSES.active:
      return (
        <>
          <CircleIcon color="#43D37D" />
          Активна
        </>
      );
    case SESSION_STATUSES.finished:
      return (
        <>
          <CircleIcon color="grey" />
          Завершена
        </>
      );
    case SESSION_STATUSES.await_finish:
      return (
        <>
          <CircleIcon color="grey" />
          Завершается
        </>
      );
    case SESSION_STATUSES.stopped:
      return (
        <>
          <CircleIcon color="red" />
          Остановлена
        </>
      );
    case SESSION_STATUSES.canceled:
      return (
        <>
          <CircleIcon color="red" />
          Отменена
        </>
      );
    case SESSION_STATUSES.auto_finish:
      return (
        <>
          <CircleIcon color="grey" />
          Автоматически завершена
        </>
      );
    default:
      return <>-</>;
  }
};

function getHeaders({
  t, hideCustomer, hidePhotos, hasPaymentModule,
}) {
  const headers = [
    // {
    //   id: 'number',
    //   label: 'Номер билета',
    // },
    {
      id: 'enterAt',
      label: 'Въезд',
    },
    {
      id: 'exitAt',
      label: 'Выезд',
    },
    {
      id: 'tokens',
      label: 'Доступ',
    },
    !hidePhotos
      ? {
        id: 'photo',
        label: 'Фото',
        colspan: 2,
      } : null,
    hasPaymentModule
      ? {
        id: 'amount',
        label: 'Оплачено/Всего',
      }
      : null,
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Клиент',
      }
      : null,
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'tariffPlan',
      label: 'Тарифный план',
    },
    // {
    //   id: 'actions',
    //   label: '',
    // },
  ];

  return headers.filter((item) => !!item);
}

function getRows(sessionsData = []) {
  const result = sessionsData.map((item) => item);

  return result;
}

const propTypes = {};

function SessionsTable({
  facilityId,
  sessionsData,
  hideCustomer = false,
  hideBtn = false,
  hideCheckbox = false,
  hidePhotos = false,
  onStop = () => {},
  onCancel = () => {},
  onChangeTariffPlan = () => {},
  onBulkStop = () => {},
  onBulkCancel = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const confirm = useConfirm();

  const [selected, setSelected] = useState([]);

  const rows = getRows(sessionsData);
  const isSessionsData = sessionsData && (sessionsData || []).length > 0;

  const countActiveSession = useMemo(
    () => (rows || []).filter(({ status }) => status === SESSION_STATUSES.active).length,
    [rows],
  );

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasSessionChangeTariffRight = useCheckFacilityRights(RIGHT_KEYS.sessionChangeTariff, facilityId);
  const hasSessionChangeStatusRight = useCheckFacilityRights(RIGHT_KEYS.sessionChangeStatus, facilityId);

  const hasPaymentModule = useHasFacilityModule(facilityId, MODULES_KEYS.payments) || false;

  const headers = getHeaders({
    t,
    hideCustomer,
    hidePhotos,
    hasPaymentModule,
  });

  const createOnStopHandler = (params) => () => {
    onStop(params);
  };

  const createOnCancelHandler = (params) => () => {
    onCancel(params);
  };

  const createOnChangeTariffPlanHandler = (params) => () => {
    onChangeTariffPlan(params);
  };

  const handleBulkCancel = () => {
    if (selected.length === 0) {
      return;
    }

    confirm({
      title: `Вы уверены, что хотите отменить ${selected.length} ${plural(selected.length, ['cecсию', 'сессии', 'сессий'])}?`,
      content:
        'Данная сессия будет помечена как отмененная и не будет учитываться в статистике и расчетах. Имеет смысл использовать, если сессия была создана по ошибке.',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    })
      .then(() => {
        onBulkCancel(selected);
        setSelected([]);
      })
      .catch(() => {});
  };

  const handleBulkStop = () => {
    if (selected.length === 0) {
      return;
    }

    confirm({
      title: `Вы уверены, что хотите остановить ${selected.length} ${plural(selected.length, ['cecсию', 'сессии', 'сессий'])}?`,
      content: 'Имеет смысл использовать, если пользователь расплатился альтернативным способом и покинул парковку.',
      agreeTitle: 'Остановить',
      disagreeTitle: 'Отмена',
    })
      .then(() => {
        onBulkStop(selected);
        setSelected([]);
      })
      .catch(() => {});
  };

  const handleSelectAll = (event) => {
    if (!event.target.checked) {
      setSelected([]);
      return;
    }

    const result = [];
    (rows || []).forEach(({ id, status }) => {
      if (status === SESSION_STATUSES.active) {
        result.push(id);
      }
    });
    setSelected(result);
  };

  const handleSelect = (event, { id, status }) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      if (status !== SESSION_STATUSES.active) {
        return;
      }

      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const [imageViewerNode, openImageViewer] = useImageViewer();

  return (
    <Paper className={classes.root}>
      {!isSessionsData && (
        <div className={classes.noDataContainer}>
          <Typography className={classes.noDataStr}>Пока журнал сессий пуст</Typography>
        </div>
      )}
      {isSessionsData && (
        <>
          {selected.length > 0 && (
            <Toolbar variant="dense" className={classes.toolbar}>
              <Tooltip title="Остановить выбранные">
                <Button onClick={handleBulkStop} startIcon={<StopIcon />}>
                  Остановить сессии
                </Button>
              </Tooltip>
              <Tooltip title="Отменить выбранные">
                <Button onClick={handleBulkCancel} startIcon={<BlockIcon />}>
                  Отменить сессии
                </Button>
              </Tooltip>
            </Toolbar>
          )}
          <TableContainer className={classes.container}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map(({ label, id, colspan }) => (
                    <TableCell
                      key={id}
                      align="left"
                      colSpan={colspan || 1}
                    >
                      <div className={classes.headerContainer}>
                        <Typography className={classes.headerStr}>{label}</Typography>
                      </div>
                    </TableCell>
                  ))}
                  {!hideBtn && hasSessionChangeStatusRight && !hideCheckbox && countActiveSession > 0 && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={handleSelectAll}
                        indeterminate={selected.length > 0 && selected.length < countActiveSession}
                        checked={countActiveSession > 0 && selected.length === countActiveSession}
                      />
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index, array) => {
                  const {
                    id,
                    number,
                    facilityId,
                    customerId,
                    customerName,
                    startTime,
                    finishTime,
                    status,
                    tariffPlanId,
                    tariffPlanName,
                    totalAmount,
                    paidAmount,
                    canChangeTariffPlan,
                    startToken,
                    finishToken,
                    isPass,
                    isFreeExit,
                  } = row || {};
                  const key = id;

                  const currency = t('others.currency');

                  const numberStr = number.replace(/(.{5})/g, '$1 ');

                  const startTimeStr = moment(startTime).format('HH:mm:ss');
                  const startDateStr = moment(startTime).format('DD.MM.yyyy');
                  const finishTimeStr = finishTime ? moment(finishTime).format('HH:mm:ss') : '';
                  const finishDateStr = finishTime ? moment(finishTime).format('DD.MM.yyyy') : '';
                  const duration = finishTime
                    ? moment(finishTime).diff(moment(startTime))
                    : undefined;

                  const totalAmountStr = totalAmount ? `${(+totalAmount || 0).toLocaleString()}\xa0${currency}` : '-';
                  const paidAmountStr = paidAmount ? `${(+paidAmount || 0).toLocaleString()}\xa0${currency}` : '-';

                  const startPhoto = (() => {
                    const first = (startToken || []).find((obj) => obj.photo !== null);
                    return first ? first.photo : null;
                  })();

                  const finishPhoto = (() => {
                    const last = (finishToken || []).slice().reverse().find((obj) => obj.photo !== null);
                    return last ? last.photo : null;
                  })();

                  const isLast = index === array.length - 1;

                  const tableRowClass = [classes.tableRow, isLast ? classes.tableRowLast : ''];

                  return (
                    <TableRow key={key} className={tableRowClass.join(' ')}>
                      {/*  <TableCell align="left">
                        <Typography className={classes.invoiceNumberStr}>{numberStr}</Typography>
                      </TableCell> */}
                      <TableCell align="left">
                        {!isFreeExit && (
                          <>
                            <Typography className={classes.timeStr}>{startTimeStr}</Typography>
                            <Typography className={classes.dateStr}>{startDateStr}</Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.timeStr}>{finishTimeStr}</Typography>
                        <Typography className={classes.dateStr}>{finishDateStr}</Typography>
                        {status === SESSION_STATUSES.active && !hideBtn && hasSessionChangeStatusRight && (
                          <Grid container spacing={1}>
                            <Grid item>
                              <Tooltip title="Остановить">
                                <IconButton size="small" onClick={createOnStopHandler({ id, customerId, facilityId })}>
                                  <StopIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Отменить">
                                <IconButton
                                  size="small"
                                  onClick={createOnCancelHandler({ id, customerId, facilityId })}
                                >
                                  <BlockIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <SessionAccessToken hideEdit={!customerId} hasAddPhone={!isPass} sessionId={id} tokens={startToken} onImageClick={openImageViewer} hidePhotos={hidePhotos} facilityId={facilityId} />
                        <SessionAccessToken isFreeExit={isFreeExit} hideEdit={!customerId} hasAddPhone={!isPass} sessionId={id} tokens={finishToken} isFinish onImageClick={openImageViewer} hidePhotos={hidePhotos} facilityId={facilityId} />
                      </TableCell>
                      {!hidePhotos && (
                        <TableCell align="left" size="small" padding="checkbox">
                          {startPhoto ? (
                            <Grid container spacing={1}>
                              <Grid item>
                                <img
                                  className={classes.photo}
                                  src={startPhoto.thumbUrl}
                                  onClick={() => openImageViewer(startPhoto.url)}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography />
                          )}
                        </TableCell>
                      )}
                      {!hidePhotos && (
                        <TableCell align="left" size="small" padding="none">
                          {finishPhoto ? (
                            <Grid container spacing={1}>
                              <Grid item>
                                <img
                                  className={classes.photo}
                                  src={finishPhoto.thumbUrl}
                                  onClick={() => openImageViewer(finishPhoto.url)}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography />
                          )}
                        </TableCell>
                      )}
                      {hasPaymentModule && (
                        <TableCell align="left">
                          <Typography className={classes.timeStr}>{paidAmountStr}</Typography>
                          <Typography className={classes.dateStr}>{totalAmountStr}</Typography>
                        </TableCell>
                      )}
                      {!hideCustomer && (
                        <TableCell align="left">
                          {customerId && hasCustomerShowRight ? (
                            <CustomLink
                              href={PATH_PAGES.oneCustomerInfo
                                .replace(':facilityId', facilityId)
                                .replace(':id', customerId)}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                  PATH_PAGES.oneCustomerInfo
                                    .replace(':facilityId', facilityId)
                                    .replace(':id', customerId),
                                );
                              }}
                            >
                              {customerName}
                            </CustomLink>
                          ) : (
                            <Typography>{customerName || 'Гость'}</Typography>
                          )}
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <Typography className={classes.statusStr}>{getStatusContentByStatus(status)}</Typography>
                        {duration && (
                          <Typography className={classes.dateStr}>{getHumanizeDurationShort(duration, ['d', 'h', 'm'])}</Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {canChangeTariffPlan && hasSessionChangeTariffRight ? (
                          <CustomLink
                            className={classes.statusStr}
                            onClick={createOnChangeTariffPlanHandler({
                              id,
                              customerId,
                              tariffPlanId,
                              facilityId,
                            })}
                          >
                            {tariffPlanName}
                            <EditIcon fontSize="small" />
                          </CustomLink>
                        ) : (
                          <Typography>{tariffPlanName}</Typography>
                        )}
                      </TableCell>
                      {/* <TableCell align="right"> */}
                      {/*  {(status === SESSION_STATUSES.active && !hideBtn && hasSessionChangeStatusRight) && ( */}
                      {/*    <Grid container spacing={1} justifyContent="flex-end"> */}
                      {/*      <Grid item> */}
                      {/*        <Tooltip title="Остановить"> */}
                      {/*          <IconButton */}
                      {/*            size="small" */}
                      {/*            onClick={createOnStopHandler({ id, customerId, facilityId })} */}
                      {/*          > */}
                      {/*            <StopIcon /> */}
                      {/*          </IconButton> */}
                      {/*        </Tooltip> */}
                      {/*      </Grid> */}
                      {/*      <Grid item> */}
                      {/*        <Tooltip title="Отменить"> */}
                      {/*          <IconButton */}
                      {/*            size="small" */}
                      {/*            onClick={createOnCancelHandler({ id, customerId, facilityId })} */}
                      {/*          > */}
                      {/*            <BlockIcon /> */}
                      {/*          </IconButton> */}
                      {/*        </Tooltip> */}
                      {/*      </Grid> */}
                      {/*    </Grid> */}
                      {/*  )} */}
                      {/* </TableCell> */}
                      {countActiveSession > 0 && !hideBtn && hasSessionChangeStatusRight && !hideCheckbox && (
                        <TableCell padding="checkbox">
                          {status === SESSION_STATUSES.active && (
                            <Checkbox
                              onClick={(e) => handleSelect(e, row)}
                              color="primary"
                              checked={selected.includes(id)}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {imageViewerNode}
    </Paper>
  );
}

SessionsTable.propTypes = propTypes;

export default SessionsTable;
