import { createQuery } from 'react-query-kit';

import {
  getConditionalControllers, getControllers, getOutControllers, getPaymentsControllers,
} from '../controllers';

export const useControllers = createQuery({
  primaryKey: 'controllers',
  queryFn: ({ queryKey: [_, params] }) => getControllers(params),
  retry: false,
});

export const useOutControllers = createQuery({
  primaryKey: 'controllers-out',
  queryFn: ({ queryKey: [_, params] }) => getOutControllers(params),
  retry: false,
});

export const useConditionalsControllers = createQuery({
  primaryKey: 'controllers-conditionals',
  queryFn: ({ queryKey: [_, params] }) => getConditionalControllers(params),
  retry: false,
});

export const usePaymentsControllers = createQuery({
  primaryKey: 'controllers-payments',
  queryFn: ({ queryKey: [_, params] }) => getPaymentsControllers(params),
  retry: false,
});
