import { createMutation, createQuery } from 'react-query-kit';

import {
  activateTicket,
  cancelTicket,
  createTicket,
  getActiveTicketSettings, getAllTicketSettings,
  getTicket,
  getTicketInfo,
  getTickets,
  getTicketSetting,
  getTicketSettingPlaces,
  getTicketsSettings, openGateForPass,
  setTicketSettings,
  updateTicket,
} from 'api/tickets';

export const useTicketTypes = createQuery({
  primaryKey: 'ticket-types',
  queryFn: ({ queryKey: [_, params] }) => getTicketsSettings(params),
  retry: false,
});

export const useAllTicketTypes = createQuery({
  primaryKey: 'all-ticket-types',
  queryFn: ({ queryKey: [_, params] }) => getAllTicketSettings(params),
  retry: false,
});

export const useActiveTicketTypes = createQuery({
  primaryKey: 'active-ticket-types',
  queryFn: ({ queryKey: [_, params] }) => getActiveTicketSettings(params),
  retry: false,
});

export const useActiveTicketTypePlaces = createQuery({
  primaryKey: 'active-ticket-type-places',
  queryFn: ({ queryKey: [_, params] }) => getTicketSettingPlaces(params),
  retry: false,
});

export const useTicketType = createQuery({
  primaryKey: 'ticket-type',
  queryFn: ({ queryKey: [_, params] }) => getTicketSetting(params),
  retry: false,
});

export const useSetTicketTypeMutation = createMutation({
  mutationFn: async (params) => setTicketSettings(params),
});

export const useTickets = createQuery({
  primaryKey: 'tickets',
  queryFn: ({ queryKey: [_, params] }) => getTickets(params),
  retry: false,
});

export const useTicket = createQuery({
  primaryKey: 'ticket',
  queryFn: ({ queryKey: [_, params] }) => getTicket(params),
  retry: false,
});

export const useTicketInfo = createQuery({
  primaryKey: 'ticket-info',
  queryFn: ({ queryKey: [_, params] }) => getTicketInfo(params),
  retry: false,
});

export const useCreateTicketMutation = createMutation({
  mutationFn: async (params) => createTicket(params),
});

export const useUpdateTicketMutation = createMutation({
  mutationFn: async (params) => updateTicket(params),
});

export const useActivateTicketMutation = createMutation({
  mutationFn: async (params) => activateTicket(params),
});

export const useCancelTicketMutation = createMutation({
  mutationFn: async (params) => cancelTicket(params),
});

export const useOpenGateTicketMutation = createMutation({
  mutationFn: async (params) => openGateForPass(params),
});
