import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { ACCESS_ACTION_TYPES } from 'config/constants';

import CallControllerAction from '../Actions/CallControllerAction';
import CallPaymentAction from '../Actions/CallPaymentAction';
import ChangeZoneAction from '../Actions/ChangeZoneAction';

import { useStyles } from './styles';

const TYPE_ITEMS = [
  { id: ACCESS_ACTION_TYPES.changeZone, title: 'Сменить зону' },
  { id: ACCESS_ACTION_TYPES.callController, title: 'Вызвать контроллер' },
  // { id: ACCESS_ACTION_TYPES.confirm, title: 'Подтвердить проезд' },
  { id: ACCESS_ACTION_TYPES.callPayment, title: 'Принять оплату' },
];

function AddActionModalContent({
  facilityId,
  onClose,
  onAdd,
  accessItems,
  zoneIndex,
  gateIndex,
  inputIndex,
}) {
  const styles = useStyles();
  const [actionType, setActionType] = useState('');
  const [params, setParams] = useState({});
  const [devShow, setDevShow] = useState(false);

  const handleOnSave = useCallback(() => {
    if (actionType === '') {
      return;
    }

    switch (actionType) {
      case ACCESS_ACTION_TYPES.changeZone:
        onAdd(zoneIndex, gateIndex, inputIndex, actionType, {
          to: params.to,
          repeat_interval: params.repeat_interval,
          free_change_zone: params.free_change_zone,
          with_two_stage: params.with_two_stage,
        });
        return;
      case ACCESS_ACTION_TYPES.callController:
      case ACCESS_ACTION_TYPES.callPayment:
        onAdd(zoneIndex, gateIndex, inputIndex, actionType, { port_id: params.port_id });

      case ACCESS_ACTION_TYPES.confirm:
    }
  }, [actionType, gateIndex, inputIndex, onAdd, params, zoneIndex]);

  const saveEnabled = (actionType === ACCESS_ACTION_TYPES.changeZone && params.to !== '')
    || (actionType === ACCESS_ACTION_TYPES.callController && params.port_id !== '')
    || (actionType === ACCESS_ACTION_TYPES.callPayment && params.port_id !== '');

  return (
    <CustomModalContentWrapper
      title="Добавить действие"
      onClose={onClose}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <ItemSelectors
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={TYPE_ITEMS}
            label="Тип"
            name="actionType"
            onChange={(e) => setActionType(e.target.value)}
            currentValue={actionType}
          />
        </Grid>
        {actionType === ACCESS_ACTION_TYPES.changeZone && (
          <ChangeZoneAction
            facilityId={facilityId}
            params={params}
            onChange={(p) => { setParams(p); }}
            devShow={devShow}
          />
        )}
        {actionType === ACCESS_ACTION_TYPES.callController && (
          <CallControllerAction
            facilityId={facilityId}
            params={params}
            onChange={(p) => { setParams(p); }}
            devShow={devShow}
          />
        )}
        {actionType === ACCESS_ACTION_TYPES.callPayment && (
          <CallPaymentAction
            facilityId={facilityId}
            params={params}
            onChange={(p) => { setParams(p); }}
            devShow={devShow}
          />
        )}
        {actionType === ACCESS_ACTION_TYPES.confirm && (
          <>

          </>
        )}
        {[ACCESS_ACTION_TYPES.changeZone].includes(actionType) && (
          <Grid item xs={12}>
            <CustomLink
              onClick={(e) => {
                e.preventDefault();
                setDevShow(!devShow);
              }}
            >
              {devShow ? 'Скрыть' : 'Показать доп опции'}
            </CustomLink>
          </Grid>
        )}
        <Grid item xs={6}>
          <CustomBtn disabled={!saveEnabled} onClick={handleOnSave} className={styles.saveBtn} btnType="primaryBlue">
            Добавить
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export default AddActionModalContent;
