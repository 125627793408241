import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import { useModal } from '@parkly/shared/helpers';

import { useOnePersonalAccount } from 'api/query/personalAccounts';
import CreatePersonalAccountTransactionModalContent
  from 'components/organisms/CreatePersonalAccountTransactionModalContent/CreatePersonalAccountTransactionModalContent';
import PersonalAccountTransactions from 'components/organisms/PersonalAccountTransactions';
import {
  PATH_PAGES, PERSONAL_ACCOUNT_TRANSACTIONS_TYPES, PERSONAL_ACCOUNTS_STATUSES_MAP,
  PERSONAL_ACCOUNTS_STATUSES_MAP_COLOR, RIGHT_KEYS,
} from 'config/constants';
import { useCommonRights } from 'helpers/hooks';

import { useStyles } from './styles';

const TABS = [
  {
    id: 'info',
    label: 'Информация о счете',
  },
  {
    id: 'transactions',
    label: 'Операции',
  },
];

export default function OnePersonalAccountInfoPage({ match }) {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { params } = match || {};
  const {
    id,
  } = params || {};
  const [tabValue, setTabValue] = useState(0);

  const hasPersonalAccountManageRight = useCommonRights(RIGHT_KEYS.personalAccountsManage);
  const hasPersonalAccountTopUpRight = useCommonRights(RIGHT_KEYS.personalAccountsTopUp);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const { data: currentAccount, isLoading, refetch } = useOnePersonalAccount({ variables: { id } });

  const [createTransactionModalNode, openCreateTransactionModal, hideCreateTransactionModal] = useModal({
    content: <CreatePersonalAccountTransactionModalContent
      accountId={id}
      onSuccess={() => {
        queryClient.invalidateQueries({ queryKey: ['personal-accounts'] });
        queryClient.invalidateQueries({ queryKey: ['personal-account-transactions'] });
        refetch();
        hideCreateTransactionModal();
      }}
      onClose={() => {
        hideCreateTransactionModal();
      }}
    />,
  });

  const {
    number,
    name,
    status,
    balance,
    creditLimit,
    lastActivityAt,
  } = currentAccount || {};

  if (isLoading) {
    return (
      <Container className={styles.container} maxWidth="md">
        <HeaderWithBackBtn
          title="Лицевой счет"
        />
        <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '8px' }}>
          <Grid item>
            <CircularIndeterminate />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title="Лицевой счет"
      />
      <Grid container spacing={2} style={{ marginTop: '8px' }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {(hasPersonalAccountTopUpRight || hasPersonalAccountManageRight) && (
              <Grid item>
                <CustomBtn
                  startIcon={<AddIcon />}
                  btnType="primaryGreen"
                  onClick={() => openCreateTransactionModal({ type: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp })}
                >
                  Начислить
                </CustomBtn>
              </Grid>
            )}
            {(hasPersonalAccountChargeRight || hasPersonalAccountTopUpRight || hasPersonalAccountManageRight) && (
              <Grid item>
                <CustomBtn
                  startIcon={<RemoveIcon />}
                  btnType="primaryRed"
                  onClick={() => openCreateTransactionModal({ type: PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.charge })}
                >
                  Списать
                </CustomBtn>
              </Grid>
            )}
            {(hasPersonalAccountManageRight) && (
              <Grid item>
                <CustomBtn
                  startIcon={<EditIcon />}
                  onClick={() => { history.push(PATH_PAGES.onePersonalAccounts.replace(':id', id)); }}
                >
                  Редактировать
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTabBar
            tabs={TABS}
            tabValue={tabValue}
            handleParamChange={(_, t) => setTabValue(t)}
          />
        </Grid>
        {tabValue === 0 && (
          <Grid item xs={12}>
            <Grid className={styles.gridContainer} container item spacing={3} xs={12} md={7}>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Номер"
                titleSm={5}
              >
                <Typography>
                  {number}
                </Typography>
              </GridTitleBase>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Название"
                titleSm={5}
              >
                <Typography>
                  {name}
                </Typography>
              </GridTitleBase>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Статус"
                titleSm={5}
              >
                <Typography className={styles.statusStr}>
                  <CircleIcon color={PERSONAL_ACCOUNTS_STATUSES_MAP_COLOR[status] || 'grey'} />
                  {PERSONAL_ACCOUNTS_STATUSES_MAP[status] || '-'}
                </Typography>
              </GridTitleBase>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Баланс"
                titleSm={5}
              >
                <Typography>
                  {`${(+balance || 0).toLocaleString()} ₽`}
                </Typography>
              </GridTitleBase>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Кредитный лимит"
                titleSm={5}
              >
                <Typography>
                  {creditLimit === null ? 'Без ограничения' : `${(+creditLimit || 0).toLocaleString()} ₽`}
                </Typography>
              </GridTitleBase>
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Последняя активность"
                titleSm={5}
              >
                <Typography>
                  {lastActivityAt ? moment(lastActivityAt).format('DD.MM.YYYY HH:mm') : '-'}
                </Typography>
              </GridTitleBase>
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && (
          <Grid item xs={12}>
            <PersonalAccountTransactions showPagination showControls accountId={id} />
          </Grid>
        )}
      </Grid>
      {createTransactionModalNode}
    </Container>
  );
}
