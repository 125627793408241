import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';

const REASON_BACKDROP_CLICK = 'backdropClick';

const ModalWrapper = React.forwardRef((props, ref) => (
  <Modal
    {...props}
    ref={ref}
  >
    <div>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </div>
  </Modal>
));

export function useModal({
  content,
  onClose = () => {},
  disableBackdropClick = false,
  enablePortal = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [additionalProps, setAdditionalProps] = useState({});

  const openModal = (props) => {
    setIsOpen(true);
    setAdditionalProps(props || {});
  };
  const hideModal = (_, reason) => {
    if (disableBackdropClick && reason === REASON_BACKDROP_CLICK) {
      return;
    }

    setIsOpen(false);
    onClose();
  };

  const contentWithAdditionalProps = React.isValidElement(content)
    ? React.cloneElement(content, additionalProps)
    : content;

  const node = (
    <ModalWrapper
      open={isOpen}
      onClose={hideModal}
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      disablePortal={!enablePortal}
    >
      {contentWithAdditionalProps}
    </ModalWrapper>
  );

  return [
    node,
    openModal,
    hideModal,
    isOpen,
  ];
}

export default {
  useModal,
};
