import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default {
  useStyles,
};
