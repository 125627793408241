import React, { useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TuneIcon from '@material-ui/icons/Tune';
import isEmpty from 'lodash/isEmpty';

import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { useModal } from '@parkly/shared/helpers';

import OperatorRightPersonalAccountShowLimitModalContent
  from 'components/organisms/OperatorRightPersonalAccountShowLimitModalContent';
import OperatorRightTicketTypeShowLimitModalContent
  from 'components/organisms/OperatorRightTicketTypeShowLimitModalContent';
import { MAP_MODULES_RIGHTS, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getRuleSettingsList(list) {
  return list;
}

function updateRuleList({
  indexRule,
  platformSign,
  rules = [],
  checked,
  isCommon,
  ruleData,
}) {
  if (isCommon) {
    const newRule = {
      ...rules[indexRule],
      checked,
      ruleData: ruleData || [],
    };
    const newRules = [
      ...rules.slice(0, indexRule),
      newRule,
      ...rules.slice(indexRule + 1),
    ];

    return newRules;
  }

  if (platformSign === 'all') {
    const newRules = rules.map((platformRuleList = [], index) => {
      if (index === indexRule) {
        const newPlatformRuleList = platformRuleList.map((rule) => ({
          ...rule,
          checked,
          ruleData: [],
        }));

        return newPlatformRuleList;
      }

      return platformRuleList;
    });

    return newRules;
  }

  // eslint-disable-next-line
  let newRules = [
    ...rules,
  ];

  newRules[indexRule][platformSign] = {
    ...newRules[indexRule][platformSign],
    checked,
    ruleData: ruleData || [],
  };

  return newRules;
}

function checkDisable(ruleId) {
  return false;
}

function checkDisabledByModuleForFacility({ facilityId, modules, ruleId }) {
  if (!MAP_MODULES_RIGHTS[ruleId]) {
    return false;
  }

  let result = true;
  MAP_MODULES_RIGHTS[ruleId].forEach((moduleKey) => {
    if (modules?.[facilityId]?.[moduleKey]) {
      result = false;
    }
  });

  return result;
}

function checkDisabledModuleAllFacility({ modules, ruleId }) {
  if (!MAP_MODULES_RIGHTS[ruleId]) {
    return false;
  }

  let result = true;
  Object.values(modules).forEach((value) => {
    MAP_MODULES_RIGHTS[ruleId].forEach((moduleKey) => {
      if (value[moduleKey]) {
        result = false;
      }
    });
  });

  return result;
}

const propTypes = {
};

function OperatorSettingsTable({
  withRoles = false,
  isUpdatable,
  allFacilitiesState = [],
  rules = [],
  ruleList = [],
  modules = {},
  roleList = [],
  roles = {},
  updateSettings = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: allFacilitiesData } = allFacilitiesState || {};
  const ruleSettingsList = getRuleSettingsList(ruleList);

  const isEverywhere = (allFacilitiesData || []).length > 1;
  const isFullWidth = (allFacilitiesData || []).length > 2;

  const rolesItems = useMemo(() => (roleList || []).map(({ id, name }) => ({
    id,
    title: name,
  })), [roleList]);

  function createOnCheckedChangeHandler({
    indexRule,
    platformSign,
    isCommon,
    ruleData,
  }) {
    return function onCheckedChange(event) {
      const { checked } = event.target;
      const newRuleList = updateRuleList({
        indexRule,
        platformSign,
        rules,
        checked,
        isCommon,
        ruleData,
      });
      updateSettings('rules', newRuleList);
    };
  }

  const createOnChangeRoleHandler = ({ facilityId }) => (e) => {
    const key = facilityId ? `f_${facilityId}` : 'all';
    const newRoles = {
      ...roles,
      [key]: e.target.value,
    };

    updateSettings('roles', newRoles);
  };

  const [ticketUpdateModalNode, openTicketUpdateModal, hideTicketUpdateModal] = useModal({
    content: (
      <OperatorRightTicketTypeShowLimitModalContent
        onClose={() => {
          hideTicketUpdateModal();
        }}
        onSuccess={({ ruleData, forwardData }) => {
          createOnCheckedChangeHandler({ ...forwardData, ruleData })({ target: { checked: true } });
          hideTicketUpdateModal();
        }}
      />
    ),
  });

  const [accountsModalNode, openAccountsModal, hideAccountsModal] = useModal({
    content: (
      <OperatorRightPersonalAccountShowLimitModalContent
        onClose={() => {
          hideAccountsModal();
        }}
        onSuccess={({ ruleData, forwardData }) => {
          createOnCheckedChangeHandler({ ...forwardData, ruleData })({ target: { checked: true } });
          hideAccountsModal();
        }}
      />
    ),
  });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={isFullWidth ? 12 : 8}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cellFirstColumn}
                  align="left"
                >
                  <Typography className={classes.headerStr}>
                    {t('operators.accessRight')}
                  </Typography>
                </TableCell>
                {isEverywhere && (
                  <TableCell
                    align="left"
                  >
                    <Typography className={classes.headerStr}>
                      {t('operators.everywhere')}
                    </Typography>
                  </TableCell>
                )}
                {(allFacilitiesData || []).map(({ id, title }) => (
                  <TableCell
                    key={id}
                    align="left"
                  >
                    <Typography className={classes.headerStr}>
                      {title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {withRoles && (
                <>
                  <TableRow>
                    <TableCell align="left" className={classes.cellFirstColumn}>
                      <Typography className={classes.bold}>Роли доступа</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <MultiItemSelectors
                        classNameForm={classes.selectForm}
                        className={classes.select}
                        items={rolesItems}
                        name="roles"
                        limitSelected={1}
                        unselectText="Роль не выбрана"
                        onChange={createOnChangeRoleHandler({})}
                        currentValue={(roles || {})?.all || []}
                      />
                    </TableCell>
                    {isEverywhere && (allFacilitiesData || []).map(({ id }, platformIndex) => (
                      <TableCell align="left">
                        <MultiItemSelectors
                          classNameForm={classes.selectForm}
                          className={classes.select}
                          items={rolesItems}
                          name="roles"
                          limitSelected={1}
                          unselectText="Роль не выбрана"
                          onChange={createOnChangeRoleHandler({ facilityId: id })}
                          currentValue={(roles || {})?.[`f_${id}`] || []}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellFirstColumn} colSpan={(allFacilitiesData || []).length + 2}>
                      <Typography>
                        Настроить индивидуально:
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}

              {ruleSettingsList.map((row, indexRule) => {
                const {
                  id: currentRuleId,
                  hidden,
                  title,
                } = row || {};
                const key = currentRuleId;

                if (hidden) {
                  return null;
                }

                if (checkDisabledModuleAllFacility({ modules, ruleId: currentRuleId })) {
                  return null;
                }

                const currentRule = rules[indexRule];
                const isCommon = (typeof currentRule === 'object') && currentRule.isRuleCommon;

                const currentRuleList = currentRule || [];
                const allChecked = isCommon
                  ? currentRule.checked
                  : currentRuleList.reduce(
                    (acc, current) => {
                      const ruleChecked = (current || {}).checked;
                      return acc && ruleChecked;
                    },
                    true,
                  );

                return (
                  <TableRow
                    key={key}
                  >
                    <TableCell
                      align="left"
                      className={classes.cellFirstColumn}
                    >
                      <Typography className={classes.ruleNameStr}>
                        {title}
                      </Typography>
                    </TableCell>
                    {isEverywhere && (
                      <TableCell
                        align="left"
                      >
                        <Checkbox
                          color="primary"
                          checked={allChecked}
                          disabled={checkDisable(currentRuleId) || !isUpdatable}
                          onChange={createOnCheckedChangeHandler({
                            indexRule,
                            platformSign: 'all',
                            isCommon,
                          })}
                        />
                      </TableCell>
                    )}
                    {(allFacilitiesData || []).map(({ id }, platformIndex) => {
                      const ruleChecked = (currentRuleList[platformIndex] || {}).checked;
                      const ruleId = !isCommon ? (currentRuleList[platformIndex] || {}).ruleId : currentRule.ruleId;
                      const ruleData = !isCommon ? (currentRuleList[platformIndex] || {}).ruleData : currentRule.ruleData;

                      const isModuleDisabled = checkDisabledByModuleForFacility({ facilityId: id, modules, ruleId });

                      return (
                        <TableCell
                          key={id}
                          align="left"
                        >
                          <Grid container spacing={0} wrap="nowrap" alignItems="center">
                            {(!isCommon || !isEverywhere) && (
                              <Grid item>
                                <Checkbox
                                  color="primary"
                                  disabled={checkDisable(currentRuleId) || !isUpdatable || isModuleDisabled}
                                  checked={isCommon ? currentRule.checked : ruleChecked}
                                  indeterminate={!isEmpty(ruleData) ? true : undefined}
                                  onChange={createOnCheckedChangeHandler({
                                    indexRule,
                                    platformSign: platformIndex,
                                    isCommon,
                                  })}
                                />
                              </Grid>
                            )}
                            {ruleId === RIGHT_KEYS.ticketUpdate && isUpdatable && !isModuleDisabled && (
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => openTicketUpdateModal({
                                    facilityId: id,
                                    initData: ruleData || [],
                                    forwardData: {
                                      indexRule,
                                      platformSign: platformIndex,
                                      isCommon,
                                    },
                                  })}
                                >
                                  <TuneIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            )}
                            {[RIGHT_KEYS.personalAccountsCharge, RIGHT_KEYS.personalAccountsTopUp].includes(ruleId) && isCommon && isUpdatable && !isModuleDisabled && (
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => openAccountsModal({
                                    initData: ruleData || [],
                                    forwardData: {
                                      indexRule,
                                      isCommon,
                                    },
                                  })}
                                >
                                  <TuneIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!isFullWidth && (
        <Grid item xs={12} sm={4} />
      )}
      {ticketUpdateModalNode}
      {accountsModalNode}
    </Grid>
  );
}

OperatorSettingsTable.propTypes = propTypes;

export default OperatorSettingsTable;
