import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { useFacilities } from 'api/query/facilities';
import { useAllPersonalAccounts } from 'api/query/personalAccounts';
import { useAllTicketTypes } from 'api/query/tickets';
import { QUOTA_TARGETS, QUOTA_TARGETS_MAP } from 'config/constants';

import { useStyles } from './styles';

const TYPES = [
  { id: QUOTA_TARGETS.facility, title: QUOTA_TARGETS_MAP[QUOTA_TARGETS.facility] },
  { id: QUOTA_TARGETS.personalAccount, title: QUOTA_TARGETS_MAP[QUOTA_TARGETS.personalAccount] },
  { id: QUOTA_TARGETS.ticketType, title: QUOTA_TARGETS_MAP[QUOTA_TARGETS.ticketType] },
];

export default function QuotaTargetSelectors({
  targetTypeValue,
  targetIdValue,
  onChangeTypeValue,
  onChangeIdValue,
  onlyTypes,
}) {
  const styles = useStyles();

  const { data: facilitiesData, isLoading: isLoadingFacilities } = useFacilities({ variables: {} });

  const facilityItems = useMemo(() => (facilitiesData || []).map(({ id, title }) => ({
    id,
    title,
  })), [facilitiesData]);

  const { data: personalAccountsData, isLoading: isLoadingPersonalAccounts } = useAllPersonalAccounts({ variables: {} });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name, number }) => ({
    id,
    title: name ? `${number} - ${name}` : number,
  })), [personalAccountsData]);

  const { data: ticketTypesData, isLoading: isLoadingTicketTypes } = useAllTicketTypes({ variables: {} });

  const ticketTypeItems = useMemo(() => (ticketTypesData || []).map(({ id, name, facilityName }) => ({
    id,
    title: `${name} (${facilityName})`,
  })), [ticketTypesData]);

  const typeItems = useMemo(
    () => TYPES.filter(({ id }) => (onlyTypes || []).includes(id)),
    [onlyTypes],
  );

  const valueItems = useMemo(() => {
    if (targetTypeValue === QUOTA_TARGETS.facility) {
      return facilityItems;
    }
    if (targetTypeValue === QUOTA_TARGETS.personalAccount) {
      return personalAccountItems;
    }
    if (targetTypeValue === QUOTA_TARGETS.ticketType) {
      return ticketTypeItems;
    }

    return [];
  }, [facilityItems, personalAccountItems, targetTypeValue, ticketTypeItems]);

  const isLoading = isLoadingFacilities || isLoadingPersonalAccounts || isLoadingTicketTypes;

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Skeleton variant="rect" width="100%" height={48} />
        </Grid>
        <Grid item xs={7}>
          <Skeleton variant="rect" width="100%" height={48} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={typeItems}
          label="Тип"
          onChange={(e) => { onChangeTypeValue(e.target.value); }}
          currentValue={targetTypeValue}
        />
      </Grid>
      <Grid item xs={7}>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={valueItems}
          label="Значение"
          onChange={(e) => { onChangeIdValue(e.target.value); }}
          currentValue={targetIdValue}
        />
      </Grid>
    </Grid>
  );
}
