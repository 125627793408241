import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import AccessSchemasSetting from 'components/organisms/integrationSettings/AccessSchemasSetting';
import ControllersIntegrationSetting from 'components/organisms/integrationSettings/ControllersIntegrationSetting';
import FlowIntegrationSetting from 'components/organisms/integrationSettings/FlowIntegrationSetting';
import GatesIntegrationSetting from 'components/organisms/integrationSettings/GatesIntegrationSetting';
import TestIntegrationDevicesSetting from 'components/organisms/integrationSettings/TestIntegrationControllersSetting';
import { FACILITY_SETTINGS_KEYS } from 'config/constants';
import { useGetFacilitySetting } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getTabs(isAccessSchemasEnabled) {
  return [
    { id: 0, label: 'Барьеры' },
    isAccessSchemasEnabled ? null : { id: 1, label: 'Сессия' },
    { id: 2, label: 'Контроллеры' },
    isAccessSchemasEnabled ? null : { id: 3, label: 'Интеграции' },
    isAccessSchemasEnabled ? { id: 4, label: 'Cхемы доступа' } : null,
  ].filter((item) => !!item);
}

function IntegrationSettingsPage({
  match,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  useCurrentFacilityMatch();

  const { facilityId } = (match || {}).params || {};

  const isAccessSchemasEnabled = useGetFacilitySetting(facilityId, FACILITY_SETTINGS_KEYS.isAccessSchemasEnabled, false);

  const [tabValue, setTabValue] = useState(0);

  const tabs = getTabs(isAccessSchemasEnabled);
  const { id: tabId } = tabs[tabValue];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={t('integrations.integrationsSetting')}
        isBackBtn={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabBar
            tabs={tabs}
            tabValue={tabValue}
            handleParamChange={handleTabChange}
          />
        </Grid>
      </Grid>
      {tabId === 0 && (
        <GatesIntegrationSetting
          match={match}
        />
      )}
      {tabId === 1 && (
        <FlowIntegrationSetting
          match={match}
        />
      )}
      {tabId === 2 && (
        <ControllersIntegrationSetting
          match={match}
        />
      )}
      {tabId === 3 && (
        <TestIntegrationDevicesSetting
          match={match}
        />
      )}
      {tabId === 4 && (
        <AccessSchemasSetting
          match={match}
        />
      )}
    </div>
  );
}

export default IntegrationSettingsPage;
