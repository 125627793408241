import { createMutation, createQuery } from 'react-query-kit';

import { addPassPersonalAccountPayment } from 'api/passes';
import {
  createOnePersonalAccount, createPersonalAccountTransaction, getAllPersonalAccounts,
  getOnePersonalAccount,
  getPersonalAccounts, getPersonalAccountsForCharge, getPersonalAccountTransactions,
  updateOnePersonalAccount,
} from 'api/personalAccounts';

export const usePersonalAccounts = createQuery({
  primaryKey: 'personal-accounts',
  queryFn: ({ queryKey: [_, params] }) => getPersonalAccounts(params),
  retry: false,
});

export const useAllPersonalAccounts = createQuery({
  primaryKey: 'all-personal-accounts',
  queryFn: ({ queryKey: [_, params] }) => getAllPersonalAccounts(params),
  retry: false,
});

export const usePersonalAccountsForCharge = createQuery({
  primaryKey: 'personal-accounts-for-charge',
  queryFn: ({ queryKey: [_, params] }) => getPersonalAccountsForCharge(params),
  retry: false,
});

export const useOnePersonalAccount = createQuery({
  primaryKey: 'one-personal-account',
  queryFn: ({ queryKey: [_, params] }) => getOnePersonalAccount(params),
  retry: false,
});

export const usePersonalAccountTransactions = createQuery({
  primaryKey: 'personal-account-transactions',
  queryFn: ({ queryKey: [_, params] }) => getPersonalAccountTransactions(params),
  retry: false,
});

export const useSetOnePersonalAccount = createMutation({
  mutationFn: async ({ id, ...params }) => {
    if (id) {
      return updateOnePersonalAccount({ id, ...params });
    }
    return createOnePersonalAccount(params);
  },
});

export const useCreatePersonalAccountTransactionMutation = createMutation({
  mutationFn: async (params) => createPersonalAccountTransaction(params),
});

export const useAddPassPersonalAccountPaymentMutation = createMutation({
  mutationFn: async (params) => addPassPersonalAccountPayment(params),
});
