import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';

import { PATH_PAGES, QUOTA_TARGETS_MAP, QUOTAS_TYPES_MAP } from 'config/constants';

import { useStyles } from './styles';

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'name',
      label: 'Название',
    },
    {
      id: 'limit',
      label: 'Использовано',
    },
    {
      id: 'type',
      label: 'Тип',
    },
    {
      id: 'target',
      label: 'На что',
    },

  ];

  return headers.filter((item) => !!item);
}

export default function QuotasTable({
  data,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();

  const headers = getHeaders({
    t,
  });

  const createOnClickHandler = useCallback((id) => (e) => {
    e.preventDefault();
    history.push(
      PATH_PAGES.oneQuota
        .replace(':id', id),
    );
  }, [history]);

  const isHasData = data && (data || []).length > 0;

  return (
    <Paper className={styles.root}>
      {!isHasData && (
        <div className={styles.noDataContainer}>
          <Typography
            className={styles.noDataStr}
          >
            Пока нет настроенных квот
          </Typography>
        </div>
      )}
      {isHasData && (
        <TableContainer className={styles.container}>
          <Table
            stickyHeader
            size="small"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell key={id} align="left">
                    <div className={styles.headerContainer}>
                      <Typography className={styles.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row) => {
                const {
                  id,
                  name,
                  type,
                  targetType,
                  targetId,
                  limit,
                  usage,
                  filters,
                } = row || {};

                return (
                  <TableRow
                    key={id}
                    className={styles.tableRow}
                  >
                    <TableCell align="left">
                      <CustomLink
                        className={styles.nameStr}
                        href={PATH_PAGES.oneQuota.replace(':id', id)}
                        onClick={createOnClickHandler(id)}
                      >
                        {name}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {`${usage || 0}`}
                        {limit !== null && (
                          <span style={{ color: 'grey' }}>{`/${limit || 0}`}</span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {QUOTAS_TYPES_MAP[type] || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {(filters || []).map(({ targetType: filterType }, index) => (
                        <Typography key={`${id}-${index}`}>
                          {QUOTA_TARGETS_MAP[filterType] || '-'}
                        </Typography>
                      ))}
                    </TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
