import { callAPI } from '@parkly/shared/api';

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

function formatAccessSchemaItems(items) {
  const result = [];

  (items || []).forEach((item) => {
    let zone = result.find((z) => z.id === item.from_zone_id);

    if (!zone) {
      zone = {
        id: item.from_zone_id,
        gates: [],
      };
      result.push(zone);
    }

    let gate = zone.gates.find((g) => g.id === item.gate_id);

    if (!gate) {
      gate = {
        id: item.gate_id,
        inputs: [],
      };
      zone.gates.push(gate);
    }

    let input = gate.inputs.find((i) => i.id === item.input_id);

    if (!input) {
      input = {
        type: item.input_type,
        id: item.input_id,
        actions: [],
      };
      gate.inputs.push(input);
    }

    input.actions.push({
      actionType: item.action_type,
      action: item.action,
      item_id: item.id,
    });
  });

  return result;
}

export async function validGetAllAccessSchemasRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const accessSchemaData = curData.map((accessSchema) => {
    const {
      id,
      name,
      description,
      is_default: isDefault,
      items,
    } = accessSchema || {};

    return {
      id,
      name,
      description,
      is_default: isDefault,
      items: formatAccessSchemaItems(items),
    };
  });

  return accessSchemaData;
}

export async function getAccessSchemas({ facilityId }) {
  return callAPI(`facilities/${facilityId}/access-schemas`)
    .then((res) => validGetAllAccessSchemasRes({
      res,
    }));
}

export async function validGetShortAllAccessSchemasRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const accessSchemaData = curData.map((accessSchema) => {
    const {
      id,
      name,
      description,
      is_default: isDefault,
    } = accessSchema || {};

    return {
      id,
      name,
      description,
      is_default: isDefault,
    };
  });

  return accessSchemaData;
}

export async function getShortAccessSchemas({ facilityId }) {
  return callAPI(`facilities/${facilityId}/access-schemas/short`)
    .then((res) => validGetShortAllAccessSchemasRes({
      res,
    }));
}

export async function createAccessSchema({
  facilityId,
  name,
  description,
  isDefault,
  items,
}) {
  return callAPI(
    `facilities/${facilityId}/access-schemas`,
    'POST',
    {
      name,
      description,
      isDefault,
      items,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function updateAccessSchema({
  facilityId,
  id,
  name,
  description,
  isDefault,
  items,
}) {
  return callAPI(
    `facilities/${facilityId}/access-schemas/${id}`,
    'POST',
    {
      name,
      description,
      isDefault,
      items,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function setAccessSchemaDefault({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/access-schemas/${id}/default`,
    'POST',
  )
    .then((res) => validCreate({ res }));
}

export async function deleteAccessSchema({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/access-schemas/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({ res }));
}
