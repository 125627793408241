import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

import { callAPI, downloadFileContentDispositionApi } from '@parkly/shared/api';

export function validGetFacility(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    title,
    address,
    latitude: lat,
    longitude: lon,
    phone,
    badges,
    timezone,
    parkingRules,
    dimensions: {
      maxHeight,
      maxWidth,
      maxLength,
      maxWeight,
    },
  } = resultData;

  return {
    id,
    title,
    address,
    lat,
    lon,
    phone,
    badges,
    timezone,
    rulesText: parkingRules,
    maxHeight,
    maxWidth,
    maxLength,
    maxWeight,
  };
}

export async function validGetAllFacilitiesRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const facilitiesData = curData.map((item) => {
    const {
      id,
      title,
      timezone,
      latitude: lat,
      longitude: lon,
    } = item;

    return {
      id,
      title,
      timezone,
      lat,
      lon,
    };
  });

  return facilitiesData;
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function getFacility({ id }) {
  return callAPI(`facilities/${id}`)
    .then((res) => validGetFacility({
      res,
    }));
}

export async function getFacilities() {
  return callAPI('facilities')
    .then((res) => validGetAllFacilitiesRes({
      res,
    }));
}

export async function createFacility({
  title,
  address,
  lat,
  lon,
  phone,
  badges,
  parkingRules,
  maxHeight,
  maxWidth,
  maxLength,
  maxWeight,
  timezone,
  licenseNumber,
}) {
  return callAPI(
    'facilities',
    'POST',
    {
      title,
      address,
      latitude: lat,
      longitude: lon,
      phone,
      badges,
      parkingRules,
      maxHeight,
      maxWidth,
      maxLength,
      maxWeight,
      timezone,
      licenseNumber,
    },
  )
    .then((res) => {
      const { data } = res || {};

      const {
        id,
        title: titleData,
        timezone: timezoneData,
        latitude: lat,
        longitude: lon,
        success,
      } = data;

      return {
        id,
        title: titleData,
        timezone: timezoneData,
        lat,
        lon,
        success,
      };
    });
}

export async function updateFacility({
  id,
  title,
  address,
  lat,
  lon,
  phone,
  badges,
  parkingRules,
  maxHeight,
  maxWidth,
  maxLength,
  maxWeight,
  timezone,
}) {
  return callAPI(
    `facilities/${id}`,
    'POST',
    {
      title,
      address,
      latitude: lat,
      longitude: lon,
      phone,
      badges,
      parkingRules,
      maxHeight,
      maxWidth,
      maxLength,
      maxWeight,
      timezone,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function setFacilitySchedule({
  id,
  matrix,
}) {
  return callAPI(
    `facilities/${id}/schedule`,
    'POST',
    {
      matrix,
    },
  )
    .then((res) => validCreate({ res }));
}

function validGetFacilitySchedule({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    matrix,
  } = resultData || {};

  return {
    matrix,
  };
}

export async function getFacilitySchedule({ id }) {
  return callAPI(
    `facilities/${id}/schedule`,
    'GET',
  )
    .then((res) => validGetFacilitySchedule({ res }));
}

export async function setFacilityPlaces({
  id,
  places,
}) {
  return callAPI(
    `facilities/${id}/placements`,
    'POST',
    {
      placements: places,
    },
  )
    .then((res) => validCreate({ res }));
}

function validGetFacilityPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return {
    places: resultData,
  };
}

export async function getFacilityPlaces({ id, purpose }) {
  const query = new URLSearchParams(pickBy({
    purpose,
  }, identity));

  const queryParams = query.toString();

  return callAPI(
    `facilities/${id}/placements?${queryParams}`,
    'GET',
  )
    .then((res) => validGetFacilityPlaces({ res }));
}

function validGetFacilityAreas({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getFacilityAreas({ id }) {
  return callAPI(
    `facilities/${id}/areas`,
    'GET',
  )
    .then((res) => validGetFacilityAreas({ res }));
}

function validGetFreeFacilityPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getFacilityFreePlaces({ facilityId }) {
  return callAPI(
    `facilities/${facilityId}/free-placements`,
    'GET',
  )
    .then((res) => validGetFreeFacilityPlaces({ res }));
}

export async function setFacilityFreePlaces({ facilityId, zoneId, value }) {
  return callAPI(
    `facilities/${facilityId}/free-placements/${zoneId}`,
    'POST',
    {
      value,
    },
  )
    .then((res) => validCreate({ res }));
}

function validGetFacilityGates({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const gatesData = curData.map((item) => {
    const {
      id,
      name,
      address,
      latitude: lat,
      longitude: lon,
      type,
      direction,
      facility_name: facilityName,
      facility_id: facilityId,
    } = item;

    return {
      id,
      name,
      address,
      lat,
      lon,
      type,
      direction,
      facilityName,
      facilityId,
    };
  });

  return gatesData;
}

export async function getFacilityGates({ id }) {
  return callAPI(
    `facilities/${id}/gates`,
    'GET',
  )
    .then((res) => validGetFacilityGates({ res }));
}

export async function getFacilityPanelGates({ id }) {
  return callAPI(
    `facilities/${id}/panel/gates`,
    'GET',
  )
    .then((res) => validGetFacilityGates({ res }));
}

export async function getBulkGates({
  facilityIds,
}) {
  const query = new URLSearchParams(pickBy({
    facilityIds: isEmpty(facilityIds) ? undefined : facilityIds.join(','),
  }, identity));

  const queryParams = query.toString();

  return callAPI(
    `gates/bulk?${queryParams}`,
    'GET',
  )
    .then((res) => validGetFacilityGates({ res }));
}

function validGetFacilityGatesWithControllers({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const gatesData = curData.map((item) => {
    const {
      id,
      name,
      type,
      direction,
      lines,
      controllers,
      twoStageControllers,
      awaitFinishControllers,
      manualControllers,
    } = item;

    const controllersData = (controllers || []).map(({
      id: controllerId, portId, type: controllerType, conditional,
    }) => ({
      id: controllerId,
      portId,
      type: controllerType,
      conditionals: (conditional || []).map(({ controllerId: condControllerId, portId: condPortId, value }) => ({
        id: condControllerId,
        portId: condPortId,
        value: value ? '1' : '0',
      })),
    }));

    const twoStageControllersData = (twoStageControllers || []).map(({
      id: controllerId,
      portId,
      type: controllerType,
    }) => ({
      id: controllerId,
      portId,
      type: controllerType,
    }));

    const awaitFinishControllersData = (awaitFinishControllers || []).map(({
      id: controllerId,
      portId,
      type: controllerType,
    }) => ({
      id: controllerId,
      portId,
      type: controllerType,
    }));

    const manualControllersData = (manualControllers || []).map(({ id: controllerId, portId, type: controllerType }) => ({
      id: controllerId,
      portId,
      type: controllerType,
    }));

    return {
      id,
      name,
      type,
      direction,
      lines,
      controllers: controllersData,
      twoStageControllers: twoStageControllersData,
      awaitFinishControllers: awaitFinishControllersData,
      manualControllers: manualControllersData,
    };
  });

  return gatesData;
}

export async function getFacilityGatesWithControllers({ id }) {
  return callAPI(
    `facilities/${id}/gates-with-parking-controllers`,
    'GET',
  )
    .then((res) => validGetFacilityGatesWithControllers({ res }));
}

export async function setFacilityGate({
  facilityId,
  id,
  name,
  address,
  lat,
  lon,
  type,
  direction,
}) {
  return callAPI(
    `facilities/${facilityId}/gates`,
    'POST',
    {
      id,
      name,
      address,
      latitude: lat,
      longitude: lon,
      type,
      direction,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function deleteFacilityGate({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/gates/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({ res }));
}

export async function openFacilityGate({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/gates/${id}/open`,
    'POST',
  )
    .then((res) => validCreate({ res }));
}

function validGetFacilityIntegrationFlow({ res }) {
  const { data } = res || {};

  const {
    nodes,
    edges,
  } = data || {};

  return {
    nodes,
    edges,
  };
}

export async function getFacilityIntegrationFlow({ id }) {
  return callAPI(
    `facilities/${id}/integration/flow`,
    'GET',
  )
    .then((res) => validGetFacilityIntegrationFlow({ res }));
}

export async function setFacilityIntegrationFlow({
  facilityId,
  fromType,
  fromValue,
  toType,
  toValue,
  title,
  subtitle,
}) {
  return callAPI(
    `facilities/${facilityId}/integration/flow`,
    'POST',
    {
      fromType,
      fromValue,
      toType,
      toValue,
      title,
      subtitle,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function deleteFacilityIntegrationFlow({
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/integration/flow`,
    'DELETE',
  )
    .then((res) => validCreate({ res }));
}

function validGetFacilityIntegrationInfo({ res }) {
  const { data: dataRes } = res || {};
  const { data } = dataRes || {};

  const {
    lineNumber,
  } = data || {};

  return {
    lineNumber,
  };
}

export async function getFacilityIntegrationInfo({
  id,
}) {
  return callAPI(
    `facilities/${id}/integration`,
    'GET',
  )
    .then((res) => validGetFacilityIntegrationInfo({ res }));
}

export async function testFacilityIntegrationDevice({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/integration/devices/${id}/test`,
    'GET',
  )
    .then((res) => validCreate({ res }));
}

export async function testCallbackFacilityIntegrationDevice({
  facilityId,
  id,
  isSuccess,
}) {
  return callAPI(
    `facilities/${facilityId}/integration/devices/${id}/test-callback`,
    'POST',
    {
      isSuccess,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function downloadQrImageFacilityGate({
  facilityId,
  id,
}) {
  return downloadFileContentDispositionApi(`facilities/${facilityId}/gates/${id}/qr-image`);
}

export async function downloadQrPdfFacilityGate({
  facilityId,
  id,
}) {
  return downloadFileContentDispositionApi(`facilities/${facilityId}/gates/${id}/qr-pdf`);
}

async function validGetFacilitySiteSetting({ res }) {
  const { data: dataRes } = res || {};

  return dataRes;
}

export async function getFacilitySiteSetting({ id }) {
  return callAPI(
    `facilities/${id}/site-settings`,
    'GET',
  )
    .then((res) => validGetFacilitySiteSetting({ res }));
}

export async function setFacilitySiteSetting({
  id,
  slug,
  name,
  description,
  themeType,
  showPhone,
  showRoute,
  showFreePlacesCount,
  showTariffs,
  showParkingRules,
  showEnterBtn,
  showSearchByPlate,
  preferSearchByPlate,
  showPartner,
  showProfile,
  showTariffIds,
}) {
  return callAPI(
    `facilities/${id}/site-settings`,
    'POST',
    {
      slug,
      name,
      description,
      themeType,
      showPhone,
      showRoute,
      showFreePlacesCount,
      showTariffs,
      showParkingRules,
      showEnterBtn,
      showSearchByPlate,
      preferSearchByPlate,
      showPartner,
      showProfile,
      showTariffIds,
    },
  )
    .then((res) => validCreate({ res }));
}

async function validGetFacilityMPPSetting({ res }) {
  const { data: dataRes } = res || {};

  return dataRes;
}

export async function getFacilityMPPSetting({ id }) {
  return callAPI(
    `facilities/${id}/mpp-settings`,
    'GET',
  )
    .then((res) => validGetFacilityMPPSetting({ res }));
}

export async function setFacilityMPPSetting({
  id,
  title,
  purpose,
  schedule,
  address,
  coordinates,
  contractorName,
  prices,
  levels,
  placesCount,
  handicappedPlacesCount,
  phone,
  note,
  showFreePlaces,
  showPrices,
  facilityTypeTickets,
  facilityTypeSubscription,
}) {
  return callAPI(
    `facilities/${id}/mpp-settings`,
    'POST',
    {
      title,
      purpose,
      schedule,
      address,
      coordinates,
      contractorName,
      prices,
      levels,
      placesCount,
      handicappedPlacesCount,
      phone,
      note,
      showFreePlaces,
      showPrices,
      facilityTypeTickets,
      facilityTypeSubscription,
    },
  )
    .then((res) => validCreate({ res }));
}

async function validGetFacilityCustomSettings({ res }) {
  const { data } = res || {};
  const { data: dataRes } = data || {};

  const {
    isAccessSchemasEnabled,
    isFreeExitCarWithoutSessionEnabled,
    isFreeExitPhoneWithoutSessionEnabled,
  } = dataRes || {};

  return {
    isAccessSchemasEnabled,
    isFreeExitCarWithoutSessionEnabled,
    isFreeExitPhoneWithoutSessionEnabled,
  };
}

export async function getFacilityCustomSettings({ facilityId }) {
  return callAPI(
    `facilities/${facilityId}/custom-settings`,
    'GET',
  )
    .then((res) => validGetFacilityCustomSettings({ res }));
}

export async function setFacilityCustomSettings({
  facilityId,
  isFreeExitCarWithoutSessionEnabled,
  isFreeExitPhoneWithoutSessionEnabled,
}) {
  return callAPI(
    `facilities/${facilityId}/custom-settings`,
    'POST',
    {
      isFreeExitCarWithoutSessionEnabled,
      isFreeExitPhoneWithoutSessionEnabled,
    },
  )
    .then((res) => validCreate({ res }));
}

export async function downloadQrImageFacilitySite({
  facilityId,
}) {
  return downloadFileContentDispositionApi(`facilities/${facilityId}/site-settings/qr`);
}

export default {
  createFacility,
  getFacility,
  getFacilities,
};
