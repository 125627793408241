import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import BarrierIcon from '@parkly/shared/components/atoms/icons/BarrierIcon';
import DoorIcon from '@parkly/shared/components/atoms/icons/DoorIcon';
import GateIcon from '@parkly/shared/components/atoms/icons/GateIcon';

import { useFacilityGates, useFacilityPanelGates, useOpenGateMutation } from 'api/query/gates';
import { GATE_TYPES } from 'config/constants';

import { useStyles } from './styles';

function GateControls({
  currentFacilityState,
}) {
  const styles = useStyles();

  const {
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    id: facilityId,
  } = currentFacilityData || {};

  const { data: gatesData, isLoading: isGatesLoading } = useFacilityPanelGates({
    variables: { id: facilityId },
    staleTime: 5 * 60 * 1000,
  });

  const openGateMutation = useOpenGateMutation();

  const { isLoading: isOpenLoading } = openGateMutation || {};

  const [selDeviceId, setSelDeviceId] = useState(null);

  const createOpenGateHandler = (id) => () => {
    setSelDeviceId(id);
    openGateMutation.mutate({ id, facilityId });
  };

  return (
    <Grid container spacing={2}>
      {isGatesLoading && (
        <CircularIndeterminate type="fullWidth" />
      )}
      {(gatesData || []).map(({
        id, name, type, direction,
      }) => (
        <Grid key={id} item xs={12} sm={12} md={6} lg={4}>
          <CustomBtn
            className={styles.btn}
            btnType="primaryBlue"
            onClick={createOpenGateHandler(id)}
            disabled={isOpenLoading}
          >
            {type === GATE_TYPES.barrier && (
              <BarrierIcon stroke="white" />
            )}
            {type === GATE_TYPES.gate && (
              <GateIcon stroke="white" />
            )}
            {type === GATE_TYPES.door && (
              <DoorIcon stroke="white" />
            )}
            {name}
            {isOpenLoading && id === selDeviceId && (
              <CircularProgress style={{ marginLeft: '8px' }} size="2rem" />
            )}
          </CustomBtn>
        </Grid>
      ))}
    </Grid>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};

  const {
    currentFacility: currentFacilityState,
  } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedGateControls = connect(
  mapStateToProps,
  {},
)(GateControls);

export default ConnectedGateControls;
