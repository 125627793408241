import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  gridContainer: {
    margin: 0,
  },
  itemContainer: {
    minHeight: 'unset !important',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
  itemTitleContainer: {
    alignItems: 'center',
  },
  btn: {

  },
  subTitle: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  statusStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  bold: {
    fontWeight: 700,
  },
}));

export default {
  useStyles,
};
