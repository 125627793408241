import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  pagination: {
    margin: 'auto',
  },
  paginationContainer: {
    marginTop: 24,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  btn: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
  statusSelectForm: {
    height: 48,
    border: 0,
  },
  statusSelect: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
}));

export default {
  useStyles,
};
