import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Pagination from '@material-ui/lab/Pagination';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import useConfirm from '@parkly/shared/helpers/confirm';

import { useActivateTicketMutation, useCancelTicketMutation } from 'api/query/tickets';
import ReqValues from 'components/molecules/ReqValues';
import { getCreatedByContent } from 'components/pages/tickets/OneTicketInfoPage/OneTicketInfoPage';
import {
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

import TicketsTable from '../../../templates/TicketsTable';

import { useStyles } from './styles';

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function getHeaders({
  t,
  hideCustomer,
  hideReqValues,
}) {
  const headers = [
    {
      id: 'id',
      label: 'Номер',
    },
    {
      isSorted: true,
      id: 'startTime',
      label: 'Дата начала',
    },
    {
      id: 'createdBy',
      label: 'Заказчик',
    },
    {
      id: 'pass_type',
      label: 'Тип',
    },
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Гость',
      } : null,
    {
      id: 'placeName',
      label: 'Место',
    },
    !hideReqValues
      ? {
        id: 'tokens',
        label: 'Доступы',
      } : null,
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  return headers.filter((item) => !!item);
}

const getStatusNode = ({ status }, styles, key) => {
  const str = PASSES_STATUSES_MAP[status] || '-';

  const color = PASSES_STATUSES_COLOR_MAP[status] || 'grey';

  return (
    <Typography key={`${key}-${status}-${color}`} className={styles.statusStr}>
      <CircleIcon color={color} />
      {str}
    </Typography>
  );
};

function TicketsContent({
  facilityId,
  isLoading = false,
  ticketsData = [],
  tikcetsMeta,

  showPagination = false,
  onPageChange = () => {},
  sortingSettingsChange = () => {},
  sorting = {},
  hideCustomer = false,
  hideReqValues = false,

  onNeedUpdate = () => {},
  stopSessionsPassReg,

  showIfNotEmpty = false,
}) {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasTicketTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);

  const activateTicketMutation = useActivateTicketMutation({
    onSuccess({ success }) {
      if (success) {
        onNeedUpdate();
      }
    },
  });

  const { isLoading: isActivateLoading } = activateTicketMutation || {};

  const cancelTicketMutation = useCancelTicketMutation({
    onSuccess({ success }) {
      if (success) {
        onNeedUpdate();
      }
    },
  });

  const { isLoading: isCancelLoading } = activateTicketMutation || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = tikcetsMeta || {};

  const headers = getHeaders({ t, hideCustomer, hideReqValues });

  if (!showIfNotEmpty && isEmpty(ticketsData)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {!isLoading && !isEmpty(ticketsData) && (
        <>
          <TicketsTable
            facilityId={facilityId}
            data={ticketsData}
            sortingSettingsChange={sortingSettingsChange}
            sorting={sorting}
            onCancel={(id) => cancelTicketMutation.mutateAsync({ facilityId, id })}
            onActivate={(id) => activateTicketMutation.mutateAsync({ facilityId, id })}
            hideCustomer={hideCustomer}
            hideReqValues={hideReqValues}
            onNeedUpdate={onNeedUpdate}
          />
        </>
      )}
      {!isLoading && showPagination && lastPage > 1 && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.pagination}
            name="page"
            page={currentPage}
            onChange={onPageChange}
            count={lastPage}
            color="primary"
          />
        </div>
      )}
    </Grid>
  );
}

export default TicketsContent;
