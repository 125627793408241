import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import useConfirm from '@parkly/shared/helpers/confirm';

import {
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from '../../../config/constants';
import { useCheckFacilityRights, useCommonRights } from '../../../helpers/hooks';
import { useImageViewer } from '../../atoms/ImageViewer';
import ReqValues from '../../molecules/ReqValues';
import SessionAccessToken from '../../molecules/SessionAccessToken';
import PassGatesControl from '../../organisms/PassGatesControl';
import { getCreatedByContent } from '../../pages/tickets/OneTicketInfoPage/OneTicketInfoPage';

import { useStyles } from './styles';

function getHeaders({
  t,
  hideCustomer,
  hideReqValues,
}) {
  const headers = [
    {
      id: 'ticket',
      label: 'Пропуск',
    },
    {
      isSorted: true,
      id: 'finishTime',
      label: 'Срок / Статус',
    },
    {
      id: 'createdBy',
      label: 'Заказчик',
    },
    {
      id: 'customer',
      label: 'Для кого',
    },
    {
      id: 'sessions',
      label: 'Посещение',
    },
  ];

  return headers.filter((item) => !!item);
}

const getStatusNode = ({ status }, styles, key) => {
  const str = PASSES_STATUSES_MAP[status] || '-';

  const color = PASSES_STATUSES_COLOR_MAP[status] || 'grey';

  return (
    <Typography key={`${key}-${status}-${color}`} className={styles.statusStr}>
      <CircleIcon color={color} />
      {str}
    </Typography>
  );
};

export default function TicketsTable({
  facilityId,
  data,
  sorting = {},
  sortingSettingsChange = () => {},

  hideCustomer = false,
  hideReqValues = false,

  onCancel = async () => {},
  onActivate = async () => {},
  onNeedUpdate = () => {},
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const confirm = useConfirm();

  const headers = getHeaders({ t, hideCustomer, hideReqValues });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasTicketTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);
  const hasGateControlRight = useCheckFacilityRights(RIGHT_KEYS.customerSessionGateControl, facilityId);

  const [imageViewerNode, openImageViewer] = useImageViewer();

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  const createActivateHandler = (id) => () => {
    confirm({
      async: true,
      title: 'Вы уверены, что хотите активировать пропуск?',
      agreeTitle: 'Активировать',
      disagreeTitle: 'Отменить',
    }).then((complete) => {
      onActivate(id).finally(() => complete());
    }).catch(() => {});
  };

  const createCancelHandler = (id) => () => {
    confirm({
      async: true,
      title: 'Вы уверены, что хотите отменить пропуск?',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    }).then((complete) => {
      onCancel(id).finally(() => complete());
    }).catch(() => {});
  };

  return (
    <>
      {isEmpty(data) && (
        <Grid item className={styles.noDataContainer} component={Paper}>
          <Typography
            className={styles.noDataStr}
          >
            Пока нет пропусков
          </Typography>
        </Grid>
      )}
      {!isEmpty(data) && (
        <TableContainer className={styles.container} component={Paper}>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? styles.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={styles.headerContainer}>
                        <Typography className={styles.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={styles.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={styles.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row, index, array) => {
                const {
                  id,
                  number,
                  passTypeName,
                  passTypeId,
                  customerName,
                  customerId,
                  finishTime,
                  startTime,
                  placeName,
                  status,
                  requiredData,
                  canBeCanceled,
                  createdBy,
                  personalAccountName,
                  lastSession,
                  gates,
                } = row || {};
                const key = id;

                const {
                  startTime: lastSessionStartTime,
                  finishTime: lastSessionFinishTime,
                  startToken,
                  finishToken,
                  id: lastSessionId,
                } = lastSession || {};

                const isLast = index === (array.length - 1);

                const finishTimeStr = finishTime ? moment(finishTime).format('HH:mm:ss') : '-';
                const finishDateStr = finishTime ? moment(finishTime).format('DD.MM.yyyy HH:mm') : '-';
                const startTimeStr = startTime ? moment(startTime).format('HH:mm:ss') : '-';
                const startDateStr = startTime ? moment(startTime).format('DD.MM.yy') : '-';

                const tableRowClass = [
                  styles.tableRow,
                  isLast ? styles.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <div className={styles.flexColumn}>
                        <CustomLink
                          className={styles.nameStr}
                          href={PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id)}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id));
                          }}
                        >
                          {number.substring(9)}
                        </CustomLink>
                        {hasTicketTypeShowRight ? (
                          <>
                            <CustomLink
                              href={PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId)}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId));
                              }}
                            >
                              {passTypeName}
                            </CustomLink>
                          </>
                        ) : (
                          <>
                            <Typography>{passTypeName}</Typography>
                          </>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.flexColumn}>
                        <Typography className={styles.dateStr}>
                          {finishDateStr}
                        </Typography>
                        {getStatusNode({ status }, styles, key)}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {getCreatedByContent(createdBy, facilityId, history, hasCustomerShowRight, hasOperatorShowRight)}
                      </Typography>
                      {personalAccountName && (
                        <Typography className={styles.ellipsis}>
                          {personalAccountName}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.flexColumn}>
                        <ReqValues requiredData={requiredData} />
                        {(!hideCustomer && hasCustomerShowRight && customerId) ? (
                          <CustomLink
                          // className={styles.nameStr}
                            href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                            }}
                          >
                            {customerName}
                          </CustomLink>
                        ) : (
                          <Typography>{customerName}</Typography>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.flexColumn}>
                        <div>
                          <SessionAccessToken sessionId={lastSessionId} maskPhone dateAt={lastSessionStartTime} hideEdit tokens={startToken} onImageClick={openImageViewer} facilityId={facilityId} />
                          <SessionAccessToken sessionId={lastSessionId} maskPhone dateAt={lastSessionFinishTime} isFinish hideEdit tokens={finishToken} onImageClick={openImageViewer} facilityId={facilityId} />
                        </div>
                        <Grid item container spacing={1}>
                          {status === PASSES_STATUSES.active && hasGateControlRight && (
                            <Grid item>
                              <PassGatesControl facilityId={facilityId} passId={id} gates={gates} onSuccess={onNeedUpdate} />
                            </Grid>
                          )}
                          <Grid item>
                            <Grid container spacing={1} wrap="nowrap">
                              {hasTicketUpdateRight && [PASSES_STATUSES.active, PASSES_STATUSES.draft].includes(status) && (
                                <Grid item>
                                  <CustomBtn
                                    onClick={() => { history.push(PATH_PAGES.oneTicket.replace(':id', id).replace(':facilityId', facilityId)); }}
                                  >
                                    <EditIcon />
                                  </CustomBtn>
                                </Grid>
                              )}
                              {hasTicketUpdateRight && status === PASSES_STATUSES.draft && (
                                <Grid item>
                                  <CustomBtn onClick={createActivateHandler(id)}>
                                    <PlayCircleOutlineIcon />
                                  </CustomBtn>
                                </Grid>
                              )}
                              {hasTicketUpdateRight && canBeCanceled && (
                                <Grid item>
                                  <CustomBtn
                                    onClick={createCancelHandler(id)}
                                  >
                                    <CancelIcon />
                                  </CustomBtn>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {imageViewerNode}
    </>
  );
}
