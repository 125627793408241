import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ActiveCircleIcon from '@parkly/shared/components/atoms/icons/ActiveCircleIcon';
import StarIcon from '@parkly/shared/components/atoms/icons/StarIcon';
import { formatPhoneNumber } from '@parkly/shared/helpers';

import { getOperatorsAction } from 'actions/operators';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { PATH_PAGES } from 'config/constants';

import { useStyles } from './styles';

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'name',
      label: 'Имя',
    },
    {
      id: 'phone',
      label: 'Телефон',
    },
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'lastActivity',
      label: 'Последняя активность',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  return headers.filter((item) => !!item);
}

const propTypes = {};

function AllOperators({
  currentOperatorState,
  operatorsState,
  getOperatorsReq = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => getOperatorsReq(), [getOperatorsReq]);

  const {
    data: currentOperators,
    loading: isLoading,
  } = operatorsState || {};

  const headers = getHeaders({
    t,
  });

  /* HiddenParklyAdmin - feature for debug. So, we can add admin, but other
  users will not see this admin  */
  const operatorsList = ((currentOperators || [])
    .filter(({ name }) => name.indexOf('HiddenParklyAdmin') < 0));

  const { userRights } = currentOperatorState || {};
  const {
    isAdmin,
    isOperatorUpdate: isOperatorUpdateRight,
  } = userRights || {};

  const onAddOperatorBtnClick = () => {
    history.push(PATH_PAGES.addNewOperator);
  };

  const onAddApiOperatorBtnClick = () => {
    history.push(`${PATH_PAGES.addNewOperator}?api=1`);
  };

  function createHandlerTableRowClick(id) {
    const linkStr = PATH_PAGES.oneOperator.replace(':id', id);
    return function handleTableRowClick() {
      history.push(linkStr);
    };
  }

  function createActionBtnClickHandler(id) {
    return function onActionBtnClick(event) {
      event.stopPropagation();
      event.preventDefault();
      history.push(`${PATH_PAGES.operatorsActions}?id=${id}`);
    };
  }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithNavigationLinks
        links={[
          { to: PATH_PAGES.operatorsAll, label: 'Операторы' },
          { to: PATH_PAGES.operatorRolesAll, label: 'Роли' },
          { to: PATH_PAGES.operatorsActions, label: 'Журнал действий' },
        ]}
      />
      <div className={classes.pageBody}>
        {!isLoading && (
          <>
            {isOperatorUpdateRight && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <CustomBtn
                    btnType="primaryGreen"
                    className={classes.addNewBtn}
                    onClick={onAddOperatorBtnClick}
                  >
                    Добавить оператора
                  </CustomBtn>
                </Grid>
                <Grid item>
                  <CustomBtn
                    btnType="secondary"
                    onClick={onAddApiOperatorBtnClick}
                  >
                    Создать API оператора
                  </CustomBtn>
                </Grid>
              </Grid>
            )}
            {!isLoading && operatorsList && (
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      {headers.map(({ label, id }) => (
                        <TableCell
                          key={id}
                          align="left"
                        >
                          <div className={classes.headerContainer}>
                            <Typography className={classes.headerStr}>
                              {label}
                            </Typography>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {operatorsList.map((curOperator = {}, index, array) => {
                      const key = curOperator.id;
                      const {
                        id,
                        name,
                        phone,
                        status,
                        isApiUser,
                        lastActivity,
                      } = curOperator || {};

                      const isCurOperatorAdmin = (curOperator || {}).isAdmin;

                      const isLast = (array.length - 1) === index;

                      const tableRowClass = [
                        classes.tableRow,
                        isLast ? classes.tableRowLast : '',
                      ];

                      return (
                        <TableRow
                          className={tableRowClass.join(' ')}
                          key={key}
                          name={id}
                          hover
                          tabIndex={-1}
                          onClick={createHandlerTableRowClick(id)}
                        >
                          <TableCell
                            align="left"
                            className={`${classes.tableCell} classes.operatorTitle`}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <div className={`${classes.isAdmin}`}>
                                {isCurOperatorAdmin && (
                                  <StarIcon />
                                )}
                              </div>
                              <Typography className={classes.operatorTitleStr}>
                                {name}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                          >
                            <div className={`${classes.containerInCell}`}>
                              {isApiUser ? (
                                <Typography>
                                  API
                                </Typography>
                              ) : (
                                <Typography>
                                  <nobr>{formatPhoneNumber(phone)}</nobr>
                                </Typography>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <ActiveCircleIcon
                                className={classes.statusIcon}
                                /* eslint-disable-next-line eqeqeq */
                                active={status === 1}
                              />
                              <Typography align="center" className={classes.statusName}>
                                {/* eslint-disable-next-line eqeqeq */}
                                {status === 1 ? t('operators.active') : t('operators.notActive')}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <Typography>
                                {lastActivity !== null ? moment(lastActivity).format('DD.MM.YY HH:mm') : '–' }
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <CustomBtn
                              btnType="secondary"
                              onClick={createActionBtnClickHandler(id)}
                            >
                              Действия
                            </CustomBtn>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

AllOperators.propTypes = propTypes;

function mapStateToProps(state) {
  const { operators } = state || {};
  const {
    operators: operatorsState,
    currentOperator: currentOperatorState,
  } = operators || {};

  return {
    operatorsState,
    currentOperatorState,
  };
}

const ConnectedAllOperators = connect(
  mapStateToProps,
  {
    getOperatorsReq: getOperatorsAction,
  },
)(AllOperators);

export default ConnectedAllOperators;
