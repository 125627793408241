import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { callAPI } from '@parkly/shared/api';

async function validGetQuotas({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const meta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const formatedData = curData.map((item) => {
    const {
      id,
      name,
      type,
      limit,
      usage,
      filters,
    } = item;

    const filtersData = (filters || []).map((item) => {
      const {
        target_type: tType,
        operation_type: operationType,
        value,
      } = item;

      return {
        targetType: tType,
        operationType,
        value,
      };
    });

    return {
      id,
      name,
      type,
      limit,
      usage,
      filters: filtersData,
    };
  });

  return {
    data: formatedData,
    meta,
  };
}

export async function getQuotas({
  search,
  types,
  personalAccounts,
  facilities,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    search,
    types: (types || []).join(','),
    personal_accounts: (personalAccounts || []).join(','),
    facilities: (facilities || []).join(','),
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `quotas?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetQuotas({
      res,
    }));
}

async function validGetQuota({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    type,
    limit,
    usage,
    filters,
  } = resultData || {};

  const filtersData = (filters || []).map((item) => {
    const {
      target_type: tType,
      operation_type: operationType,
      value,
    } = item;

    return {
      targetType: tType,
      operationType,
      value,
    };
  });

  return {
    id,
    name,
    type,
    limit,
    usage,
    filters: filtersData,
  };
}

export function getQuota({ id }) {
  return callAPI(`quotas/${id}`)
    .then((res) => validGetQuota({
      res,
    }));
}

async function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export function createQuota({
  name,
  type,
  limit,
  usage,
  filters,
}) {
  return callAPI('quotas', 'POST', {
    name,
    type,
    limit,
    usage,
    filters: (filters || []).map((item) => ({
      target_type: item.targetType,
      operation_type: item.operationType,
      value: item.value,
    })),
  })
    .then((res) => validCreate({
      res,
    }));
}

export function updateQuota({
  id,
  name,
  type,
  limit,
  usage,
  filters,
}) {
  return callAPI(`quotas/${id}`, 'PUT', {
    name,
    type,
    limit,
    usage,
    filters: (filters || []).map((item) => ({
      target_type: item.targetType,
      operation_type: item.operationType,
      value: item.value,
    })),
  })
    .then((res) => validCreate({
      res,
    }));
}

export function deleteQuota({ id }) {
  return callAPI(`quotas/${id}`, 'DELETE')
    .then((res) => validCreate({
      res,
    }));
}
