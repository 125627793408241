import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Pagination from '@material-ui/lab/Pagination';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';

import {
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP,
  PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP_COLOR,
  PERSONAL_ACCOUNT_TRANSACTIONS_TYPES,
} from 'config/constants';

import { useStyles } from './styles';

function getHeaders({ t }) {
  return [
    {
      id: 'type',
      label: 'Тип',
    },
    {
      id: 'amount',
      label: 'Сумма',
    },
    {
      id: 'description',
      label: 'Описание',
    },
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'performedAt',
      label: 'Дата выполнения',
    },
    {
      id: 'operator',
      label: 'Оператор',
    },

  ];
}

const getStatusNode = ({ status }, styles, key) => {
  const str = PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP[status] || '-';

  const color = PERSONAL_ACCOUNT_TRANSACTIONS_STATUSES_MAP_COLOR[status] || 'grey';

  return (
    <Typography key={`${key}-${status}-${color}`} className={styles.statusStr}>
      <CircleIcon color={color} />
      {str}
    </Typography>
  );
};

export default function PersonalAccountTransactionContent({
  isLoading,
  data,
  meta,
  showPagination = false,
  onPageChange = () => {},
  sortingSettingsChange = () => {},
  sorting = {},
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const headers = getHeaders({ t });

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  const {
    currentPage = 1,
    lastPage = 1,
  } = meta || {};

  return (
    <Grid item xs={12}>
      {isEmpty(data) && !isLoading && (
        <Grid item className={styles.noDataContainer} component={Paper}>
          <Typography className={styles.noDataStr}>
            Пока нет операций
          </Typography>
        </Grid>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {!isLoading && !isEmpty(data) && (
        <TableContainer className={styles.container} component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? styles.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={styles.headerContainer}>
                        <Typography className={styles.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={styles.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={styles.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((item, index) => {
                const {
                  id, type, status, amount, description, performedAt, operatorId, operatorName,
                } = item || {};
                const isLast = index === (data.length - 1);
                const key = id;

                const performedTimeStr = performedAt ? moment(performedAt).format('HH:mm:ss') : '-';
                const performedDateStr = performedAt ? moment(performedAt).format('DD.MM.yyyy') : '-';

                const currency = t('others.currency');
                const amountStr = `${(+amount || 0).toLocaleString()} ${currency}`;

                const tableRowClass = [
                  styles.tableRow,
                  isLast ? styles.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      {type === PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.topUp && (
                        <AddIcon htmlColor="#43D37D" />
                      )}
                      {type === PERSONAL_ACCOUNT_TRANSACTIONS_TYPES.charge && (
                        <RemoveIcon htmlColor="red" />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {amountStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {description}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {getStatusNode({ status }, styles, key)}
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={styles.dateStr}>
                        {performedDateStr}
                      </Typography>
                      <Typography className={styles.timeStr}>
                        {performedTimeStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {operatorName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && showPagination && lastPage > 1 && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.pagination}
            name="page"
            page={currentPage}
            onChange={onPageChange}
            count={lastPage}
            color="primary"
          />
        </div>
      )}
    </Grid>
  );
}
